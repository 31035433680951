import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScrollRestoration: React.FunctionComponent<any> = props => {
  const [previousLocation, setPreviousLocation] = useState("");

  useEffect(() => {
    if (previousLocation !== props.location.pathname) {
      window.scrollTo(0, 0);
      setPreviousLocation(props.location.pathname);
    }
  });

  return props.children;
};

export default withRouter(ScrollRestoration);
