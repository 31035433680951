import { ReactNode } from "react";
import reactNodeToString from "react-node-to-string";

// Preserve only select valid characters
const invalidSelectorCharsRegEx = /[^\w-]+/g;
// Capture leading digits or dashes
const invalidStartCharRegEx = /^(\d|-)/;

const stripInvalidSelectorChars = (str: string): string =>
  str.replace(invalidSelectorCharsRegEx, "");

/**
 * Make a valid slug from content by stripping invalid characters and joining with dashes
 * @param content - string
 * @returns a slugified string
 */
export const slugify = (content: string): string =>
  content
    .split(" ")
    .map((part, i) => {
      let strippedPart = stripInvalidSelectorChars(part).toLowerCase();
      if (i === 0) {
        // Prefix invalid initial character that is valid elsewhere
        strippedPart = strippedPart.replace(invalidStartCharRegEx, "_$&");
      }
      return strippedPart;
    })
    .join("-");

/**
 *
 * @param content - Any `ReactNode`
 * @returns a slugified string
 * @see `slugify`
 */
export const slugifyReactNode = (content: ReactNode): string => {
  const nodeAsString = reactNodeToString(content);
  return slugify(nodeAsString);
};
