import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { SearchResultPlan } from "../../@types";
import { EnrollButtonForm } from "../EnrollButtonForm";
import routes from "../../app/routes";
import AddToCompare from "./AddToCompare";
import {
  Ga4Event,
  AnalyticsActionType,
} from "../../app/contexts/Analytics/types";
import { getPlanName } from "../../helpers/planHelpers";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { IntlContext, useTranslate } from "../../helpers/intlHooks";
import { makePlanLongId } from "../../helpers/objectUtilities";

interface PlanCardActionsProps extends RouteComponentProps {
  plan: SearchResultPlan;
  isCurrentPlan?: boolean;
  handleAddToCompare?: (plan: SearchResultPlan) => void;
  handleRemoveFromCompare?: (removeId: number | string) => void;
  hideCompareButton?: boolean;
}

const PlanCardActions: React.FunctionComponent<PlanCardActionsProps> = ({
  plan,
  isCurrentPlan,
  location,
  handleAddToCompare,
  handleRemoveFromCompare,
  hideCompareButton,
}) => {
  const t = useTranslate();
  const {
    state: { language },
  } = useAppContext();
  const fm = useContext(IntlContext).formatMessage;
  const planName = getPlanName(plan, language);
  const aria_string = `${t("plan_card.enroll_in")} ${planName}`;
  const { dispatch } = useAppContext();
  return (
    <div className="PlanCard__actions mct-hide-print">
      {!isCurrentPlan && (
        <EnrollButtonForm
          plan={plan}
          aria_string={aria_string}
          tealiumEventAction="Find Plans - Available Plans"
        />
      )}
      <HashLink
        to={`${routes.planDetails.replace(":id", makePlanLongId(plan))}${
          location.search
        }`}
        className="ds-c-button ds-c-button--solid ds-c-button--alternate e2e-plan-details-btn PlanCard__mobile_order_3"
        onClick={(): void => {
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: {
              event_action: "Find Plans - Available Plans",
              event_label: `Plan Click: ${plan.name}`,
            },
          });
          dispatch({
            type: AnalyticsActionType.SEND_GA4_EVENT,
            settings: {
              event_name: Ga4Event.PLAN_DETAILS,
            },
          });
        }}
        aria-label={fm(
          {
            id: "plan_card.plan_details_for",
          },
          { planName }
        )}
      >
        {t("plan_card.plan_details")}
      </HashLink>
      <AddToCompare
        plan={plan}
        hide={hideCompareButton}
        handleAddToCompare={handleAddToCompare}
        handleRemoveFromCompare={handleRemoveFromCompare}
      />
    </div>
  );
};

export default withRouter(PlanCardActions);
