import { Envs } from "./@types";
import { getEnvironment } from "./helpers/urlHelpers";

/* eslint-disable no-undef */
const DEBUG = process.env.NODE_ENV === "development";
const env = getEnvironment();

function mctapihost(): string {
  if (DEBUG) {
    return "//localhost:8080";
  }
  if (window.location.hostname.includes("s3-website")) {
    return "//test.medicare.gov";
  }
  return "";
}

const FE_VERSION: string = process.env.REACT_APP_RELEASE_VERSION || "Unknown";

const MCTAPI_PATH: string = DEBUG ? "" : "/api/v1/data/plan-compare";
const MCTAPI_URI: string = process.env.REACT_APP_MCTAPI_URI
  ? process.env.REACT_APP_MCTAPI_URI
  : `${mctapihost()}${MCTAPI_PATH}`;
const USE_LOCAL_PROXY = process.env.REACT_APP_USE_LOCAL_PROXY || false;

// Launch Darkly Variables
const LD_BASE_URL = process.env.REACT_APP_LD_BASE_URL || "Not Set";
const LD_CLIENT_ID = process.env.REACT_APP_LD_CLIENT_ID || "Not Set";
const LD_EVENTS_URL = process.env.REACT_APP_LD_EVENTS_URL || "Not Set";
const LD_STREAM_URL = process.env.REACT_APP_LD_STREAM_URL || "Not Set";

// Mapbox Variables
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || false;
const NEW_RELIC_BROWSER_API_KEY =
  process.env.REACT_APP_NEW_RELIC_BROWSER_API_KEY;

const ANON_ENROLL_BASE_URL =
  process.env.REACT_APP_ANON_ENROLL_BASE_URL ||
  "https://test.enrollmentcenter.medicare.gov";

const FORCE_LOGOUT_TIMEOUT_MINUTES = isNaN(
  process.env.REACT_APP_FORCE_LOGOUT_TIMEOUT_MINUTES as unknown as number
)
  ? null
  : Number(process.env.REACT_APP_FORCE_LOGOUT_TIMEOUT_MINUTES);

const CSR_IDLE_TIMEOUT_MINUTES = isNaN(
  process.env.REACT_APP_CSR_IDLE_TIMEOUT_MINUTES as unknown as number
)
  ? null
  : Number(process.env.REACT_APP_CSR_IDLE_TIMEOUT_MINUTES);

const CSR_FORCE_LOGOUT_TIMEOUT_MINUTES = isNaN(
  process.env.REACT_APP_CSR_FORCE_LOGOUT_TIMEOUT_MINUTES as unknown as number
)
  ? null
  : Number(process.env.REACT_APP_CSR_FORCE_LOGOUT_TIMEOUT_MINUTES);

const FORCE_LOGOUT_TIMEOUT_INFO =
  process.env.REACT_APP_FORCE_LOGOUT_TIMEOUT_INFO === "true" &&
  env !== Envs.prod;

export const DISABLE_REACT_QUERY_DEVTOOLS =
  process.env.REACT_APP_DISABLE_REACT_QUERY_DEVTOOLS === "true" &&
  env !== Envs.prod;

export const DISABLE_IDLE_TIMEOUT =
  process.env.REACT_APP_DISABLE_IDLE_TIMEOUT === "true" && env !== Envs.prod;

declare global {
  interface Window {
    config: Record<string, unknown>;
  }
}

export const config = (window.config = {
  ANON_ENROLL_BASE_URL,
  CSR_FORCE_LOGOUT_TIMEOUT_MINUTES,
  CSR_IDLE_TIMEOUT_MINUTES,
  DEBUG,
  DISABLE_IDLE_TIMEOUT,
  DISABLE_REACT_QUERY_DEVTOOLS,
  FE_VERSION,
  FORCE_LOGOUT_TIMEOUT_INFO,
  FORCE_LOGOUT_TIMEOUT_MINUTES,
  MAPBOX_ACCESS_TOKEN,
  MCTAPI_URI,
  LD_BASE_URL,
  LD_CLIENT_ID,
  LD_EVENTS_URL,
  LD_STREAM_URL,
  USE_LOCAL_PROXY,
  NEW_RELIC_BROWSER_API_KEY,
});
