import { Choice, InlineError, TextField } from "@cmsgov/ds-medicare-gov";
import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { CoverageSelectorErrorState } from "../../../@types";
import { useCounties } from "../../../helpers/query-hooks/useCounties";
import { useTranslate } from "../../../helpers/intlHooks";
import {
  useCurrentPlanYear,
  useYearPart,
} from "../../../helpers/yearFlagHelpers";

export type ZipcodeAndCoverageYearStepProps = {
  countiesErrorState?: CoverageSelectorErrorState;
  currentFips: string | undefined;
  initialFips: string | undefined;
  onCountyChange: (county: string) => void;
  onCoverageYearChange: (selectedYear: string) => void;
  onZipcodeChange: (zipcodeInput: string) => void;
  selectedCoverageYear?: number;
  selectedZipcode?: string;
  zipcode?: string;
  zipcodeErrorState?: CoverageSelectorErrorState;
  /** if `true`, will provide more prominent headers. */
  isSinglePage?: boolean;
};

export const ZipcodeAndCoverageYearStep: React.FunctionComponent<
  ZipcodeAndCoverageYearStepProps
> = ({
  countiesErrorState,
  currentFips,
  initialFips,
  isSinglePage,
  onCountyChange,
  onCoverageYearChange,
  onZipcodeChange,
  selectedCoverageYear,
  zipcode,
  zipcodeErrorState,
}) => {
  // * Hooks
  const t = useTranslate();
  const mctCurrentPlanYear = useCurrentPlanYear();
  const { isOpenEnrollmentNextYearOnly, isPublicPreview, isOpenEnrollment } =
    useYearPart();

  // * Queries
  const { counties, isFetching } = useCounties(zipcode);

  // * Constants
  const nextPlanYear = mctCurrentPlanYear + 1;
  const showCoverageYearSelector =
    isPublicPreview || isOpenEnrollment || isOpenEnrollmentNextYearOnly;

  return (
    <div>
      {showCoverageYearSelector && (
        <div
          data-testid="coverage-year-section"
          className="ds-u-margin-bottom--2"
        >
          {isSinglePage ? (
            <h2 className="ds-h3 ds-u-margin-bottom--2">
              {t("coverage_selector.oe.ntm.zipcode.heading")}
            </h2>
          ) : (
            <h2 className="ds-text-heading--md ds-u-measure--narrow ds-u-leading--lead">
              {t("coverage_selector.oe.zipcode.heading")}
            </h2>
          )}
          <fieldset className="ds-c-fieldset ds-u-margin-top--0">
            <legend className="ds-c-label">
              <span className="ds-u-text-transform--uppercase">
                {t("coverage_selector.coverage_for")}
              </span>
            </legend>
            <div className="mct-c-coverage-selector-v2__coverage-years">
              <Choice
                type="radio"
                name="coverage-year"
                className="mct-c-coverage-selector-v2__coverage-year-choice--next-year"
                id={"coverage-year-next-year"}
                value={nextPlanYear}
                data-testid={`coverage-selector-coverage-year-${nextPlanYear}`}
                data-cy={"coverage-year-next-year"}
                checked={selectedCoverageYear === nextPlanYear}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onCoverageYearChange(e.target.value)
                }
                label={nextPlanYear}
              />

              {!isOpenEnrollmentNextYearOnly && (
                <Choice
                  type="radio"
                  className="mct-c-coverage-selector-v2__coverage-year-choice--current-year"
                  name="coverage-year"
                  id={"coverage-year-current-year"}
                  value={mctCurrentPlanYear}
                  data-testid={`coverage-selector-coverage-year-${mctCurrentPlanYear}`}
                  data-cy={"coverage-year-current-year"}
                  checked={selectedCoverageYear === mctCurrentPlanYear}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onCoverageYearChange(e.target.value)
                  }
                  label={mctCurrentPlanYear}
                />
              )}
            </div>
          </fieldset>
        </div>
      )}
      {!showCoverageYearSelector &&
        (isSinglePage ? (
          <h2 className="ds-h3 ds-u-margin-bottom--2">
            {t("coverage_selector.ooe.ntm.zipcode.heading")}
          </h2>
        ) : (
          <h2 className="ds-text-heading--md mct-c-coverage-selector-v2__step-heading">
            {t("coverage_selector.ooe.zipcode.heading")}
          </h2>
        ))}
      <div className="mct-c-coverage-selector-v2__zipcode-container">
        <TextField
          label={t("coverage_selector.zip_code")}
          labelClassName="ds-h6"
          name="zipcode"
          data-testid="coverage-selector-zipcode"
          className={classNames("mct-c-coverage-selector-v2__zipcode", {
            "mct-c-coverage-selector-v2__text-field--error":
              zipcodeErrorState?.error,
          })}
          onChange={e => onZipcodeChange(e.target.value)}
          defaultValue={zipcode}
          aria-required="true"
          aria-invalid={!!zipcodeErrorState?.error}
          {...(zipcodeErrorState?.error
            ? { "aria-describedby": "zipcode-container-error" }
            : {})}
        />
      </div>
      {zipcodeErrorState?.message && (
        <div
          className=" ds-u-padding-top--1"
          data-testid="zipcodePlanTypeError"
        >
          <InlineError id="zipcode-container-error">
            {t(zipcodeErrorState.message || "")}
          </InlineError>
        </div>
      )}
      {isFetching && <p>{t("app.loading")}...</p>}
      <>
        {counties.length > 1 && (
          <fieldset className="ds-c-fieldset ds-u-margin-top--2">
            <legend className="ds-c-label ds-u-margin-bottom--1">
              <span data-testid="select-your-county">
                {t("coverage_selector.select_your_county")}
              </span>
            </legend>
            <div className="mct-c-coverage-selector-v2__zip-counties">
              {counties.map(county => (
                <Choice
                  className="mct-c-coverage-selector-v2__county-choice"
                  data-testid={`coverage-selector-fips-${county.fips}`}
                  defaultChecked={
                    county.fips === initialFips || county.fips === currentFips
                  }
                  id={county.fips}
                  key={county.fips}
                  label={`${county.name}, ${county.state}`}
                  name="county"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onCountyChange(e.target.value);
                  }}
                  type="radio"
                  value={JSON.stringify(county)}
                />
              ))}
            </div>
          </fieldset>
        )}
        {countiesErrorState?.error && (
          <div className="ds-u-margin-y--1">
            <InlineError>{t(countiesErrorState.message || "")}</InlineError>
          </div>
        )}
      </>
    </div>
  );
};
