import { getBeneOecStatus } from "../../api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCurrentPlanYear, useYearPart } from "../yearFlagHelpers";
import { OecStatuses } from "../../@types";
import { useCallback, useMemo } from "react";
import { QueryKey } from "./types";
import { useLoggedIn } from "../loginHelpers";

/**
 * a hook to access a bene's current and future pending enrollments
 */
export const usePendingEnrollments = () => {
  // * Hooks
  const isLoggedIn = useLoggedIn();
  const { isOpenEnrollmentNextYearOnly, isOpenEnrollment } = useYearPart();
  const queryClient = useQueryClient();
  const currentPlanYear = useCurrentPlanYear();
  const nextPlanYear = currentPlanYear + 1;

  // * Queries
  const currentYearQuery = useQuery({
    queryKey: [QueryKey.PendingEnrollments, currentPlanYear],
    queryFn: () => {
      return getBeneOecStatus(String(currentPlanYear));
    },
    enabled: isLoggedIn && !isOpenEnrollmentNextYearOnly,
  });

  const nextYearQuery = useQuery({
    queryKey: [QueryKey.PendingEnrollments, nextPlanYear],
    queryFn: () => {
      return getBeneOecStatus(String(nextPlanYear));
    },
    enabled: isLoggedIn && (isOpenEnrollmentNextYearOnly || isOpenEnrollment),
  });

  // * Memos
  const oecStatuses: OecStatuses = useMemo(() => {
    return {
      currentYear: currentYearQuery.data,
      nextYear: nextYearQuery.data,
    };
  }, [currentYearQuery.data, nextYearQuery.data]);

  // * Callbacks
  const refreshPendingEnrollments = useCallback(
    (year?: number) => {
      if (!year || year === currentPlanYear) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.PendingEnrollments, currentPlanYear],
        });
      }
      if (!year || year === nextPlanYear) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.PendingEnrollments, nextPlanYear],
        });
      }
    },
    [currentPlanYear, nextPlanYear, queryClient]
  );

  return {
    /** an object containing this year's and next year's pending enrollments */
    oecStatuses,
    /** get the latest pending enrollments from endpoint by invalidating the query */
    refreshPendingEnrollments,
  };
};
