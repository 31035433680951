import React from "react";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { CUSTOM_INTERNAL_LINK_CLICKED_EVENT } from "./AnalyticsListeners";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export const ExternalLink = ({
  href,
  text,
}: {
  href: string;
  text: string;
}) => {
  const { dispatch } = useAppContext();
  return (
    <a
      {...{ [CUSTOM_INTERNAL_LINK_CLICKED_EVENT]: "true" }}
      href={href}
      onClick={() => {
        dispatch({
          type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
          settings: {
            linkText: text,
            linkUrl: href,
            text: text,
          },
        });
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};
