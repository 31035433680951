import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  ReactNode,
  AriaAttributes,
  useMemo,
} from "react";
import {
  Button,
  CloseIconThin,
  HelpDrawer,
  HelpDrawerProps,
  HelpDrawerToggle,
  HelpDrawerToggleProps,
} from "@cmsgov/ds-medicare-gov";
import { getReactNodeText } from "../helpers/analyticsHelpers";
import { slugifyReactNode } from "../helpers/slugifyUtilities";
import classNames from "classnames";
import StickyFooter from "./StickyFooter";
import uniqueId from "lodash.uniqueid";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { sendHelpDrawerOpenAnalyticsEvent } from "../app/contexts/Analytics/events";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

interface MctHelpDrawerToggleProps
  extends Omit<
    HelpDrawerToggleProps,
    "helpDrawerOpen" | "showDrawer" | "children"
  > {
  open: boolean;
  anyDrawerOpen: boolean;
  openDrawer: () => void;
  toggleClassName?: string;
  inlineToggle?: boolean;
  toggleText: ReactNode;
}

const internalToggleClassName = "help-drawer-toggle";

export const MctHelpDrawerToggle: FunctionComponent<
  MctHelpDrawerToggleProps
> = ({
  open,
  anyDrawerOpen,
  openDrawer,
  toggleClassName,
  inlineToggle,
  toggleText,
  ...props
}) => {
  return (
    <HelpDrawerToggle
      helpDrawerOpen={anyDrawerOpen || open}
      showDrawer={openDrawer}
      className={`${internalToggleClassName} ${toggleClassName}`}
      inline={inlineToggle}
      aria-expanded={open}
      {...props}
    >
      {toggleText}
    </HelpDrawerToggle>
  );
};

type ModifiedHelpDrawerProps = Omit<
  HelpDrawerProps,
  "onCloseClick" | "heading"
>;

interface ButtonInterface
  extends Omit<
      React.ComponentPropsWithoutRef<typeof Button>,
      "render" | "children"
    >,
    AriaAttributes {
  id?: string;
  shouldCloseDrawer?: boolean;
  text: string;
}

export interface MctHelpDrawerFooterProps {
  className?: string;
  id?: string;
  children?: React.ReactNode;
  buttons: ButtonInterface[];
}

interface MctHelpDrawerProps extends ModifiedHelpDrawerProps {
  large?: boolean;
  drawerTitle: ReactNode;
  closeDrawer: () => void;
  footer?: MctHelpDrawerFooterProps;
  id?: string;
  dialogClassName?: string;
}

export const MctHelpDrawer: FunctionComponent<MctHelpDrawerProps> = ({
  large,
  drawerTitle,
  closeDrawer,
  footer,
  children,
  id,
  className,
  dialogClassName,
  ...props
}) => {
  const { dispatch } = useAppContext();
  const classes = classNames(
    "mct-c-help-drawer ds-u-text-align--left mct-u-position--absolute",
    className,
    large ? "mct-c-help-drawer__large" : ""
  );

  return (
    <div className={classes} id={id} role="complementary">
      <HelpDrawer
        closeButtonText={<CloseIconThin />}
        heading={drawerTitle}
        onCloseClick={closeDrawer}
        className={dialogClassName}
        {...props}
      >
        {children}
        {footer && (
          <StickyFooter className={footer.className} id={footer.id}>
            {footer?.children}
            {footer.buttons.map(button => {
              const { text, onClick, shouldCloseDrawer, ...props } = button;
              return (
                <Button
                  key={text}
                  onClick={() => {
                    onClick && onClick();
                    if (shouldCloseDrawer) {
                      closeDrawer();
                    }
                    dispatch({
                      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                      settings: {
                        button: {
                          buttonStyle: AnalyticsButtonStyle.DEFAULT,
                          buttonType: AnalyticsButtonType.BUTTON,
                          text,
                        },
                      },
                    });
                  }}
                  {...props}
                >
                  {text}
                </Button>
              );
            })}
          </StickyFooter>
        )}
      </HelpDrawer>
    </div>
  );
};

export interface HelpDrawerAnalytics {
  openDrawer: () => void;
  closeDrawer: () => void;
}

export interface HelpDrawerWrapperProps extends ModifiedHelpDrawerProps {
  inlineToggle?: boolean;
  toggleText: ReactNode;
  toggleClassName?: string;
  toggleIcon?: ReactNode;
  drawerTitle: ReactNode;
  large?: boolean;
  footer?: MctHelpDrawerFooterProps;
  /**
   * Specify to use custom analytic event that
   * is different from the default method.
   */
  mctAnalytics?: HelpDrawerAnalytics;
  /**
   * Set `true` to use the default method to dispatch analytic event
   * when help drawer is opened.
   * The default method dispatchs analytic event with the
   * `toggleText` and `drawerTitle`.
   * This is different from the Design System analytics properties.
   */
  enableDefaultAnalytics?: boolean;
  onOpenDrawer?: () => void;
  onCloseDrawer?: () => void;
  dialogClassName?: string;
  disableClickOutsideHandler?: boolean;
}

const HelpDrawerWrapper: FunctionComponent<HelpDrawerWrapperProps> = ({
  inlineToggle = false,
  toggleText,
  toggleClassName = "",
  toggleIcon,
  drawerTitle,
  children,
  large,
  footer,
  mctAnalytics,
  onOpenDrawer = () => {},
  onCloseDrawer = () => {},
  className,
  dialogClassName,
  enableDefaultAnalytics = false,
  disableClickOutsideHandler = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  // Use this state value to prevent focus going to any toggle when a drawer
  // is closed because the user has opened another. If all drawers are closed
  // focus can go to the last used toggle (see https://design.cms.gov/components/help-drawer/)
  // `HelpDrawerToggle` -> `helpDrawerOpen` prop
  const [anyDrawerOpen, setAnyDrawerOpen] = useState(false);
  // We need a reference to the open state variable so that the clickOutsideHandler
  // function can be updated appropriately, otherwise it just uses the initial value
  const openRef = useRef(open);
  const controlName = useMemo(
    () => `control-${slugifyReactNode(drawerTitle)}-${uniqueId()}`,
    [drawerTitle]
  );

  const openDrawer = (): void => {
    if (mctAnalytics) {
      mctAnalytics.openDrawer();
    } else if (enableDefaultAnalytics) {
      sendHelpDrawerOpenAnalyticsEvent({
        heading: getReactNodeText(drawerTitle),
        text: getReactNodeText(toggleText),
      });
    }
    onOpenDrawer();
    setOpen(true);
  };

  const closeDrawer = (): void => {
    if (mctAnalytics) {
      mctAnalytics.closeDrawer();
    }
    onCloseDrawer();
    setOpen(false);
  };

  /**
   * A close handler for drawer click-outside events, only. The `HelpDrawer`
   * component has its own prop for the close button handler (`closeDrawer`).
   * Checks that the target isn't inside the drawer or the toggle
   * that opens the drawer, and that the drawer is currently open
   */
  const clickOutsideHandler = (e: MouseEvent): void => {
    if (!openRef.current) {
      return;
    }
    const target = e.target as HTMLElement;
    const buttonTarget = target.closest("button");
    const isInsideDrawer = target.closest(`#${controlName}`);
    const isThisDrawerToggle =
      target.matches(`[aria-controls="${controlName}"]`) ||
      buttonTarget?.matches(`[aria-controls="${controlName}"]`);
    if (!isInsideDrawer && !isThisDrawerToggle) {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (open) {
      if (!disableClickOutsideHandler) {
        document.addEventListener("click", clickOutsideHandler);
      }

      setAnyDrawerOpen(true);
    } else {
      if (!disableClickOutsideHandler) {
        document.removeEventListener("click", clickOutsideHandler);
      }

      const otherOpenDrawer = Boolean(
        document.querySelector(
          `.${internalToggleClassName}[aria-expanded="true"]`
        )
      );
      setAnyDrawerOpen(otherOpenDrawer);
    }

    // Maintain the state of the boolean ref
    openRef.current = open;
    return () => {
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [open, disableClickOutsideHandler]);

  const optionalDrawerProps: Pick<ModifiedHelpDrawerProps, "headingLevel"> = {};
  if (props.headingLevel) {
    optionalDrawerProps.headingLevel = props.headingLevel;
  }

  return (
    <>
      <MctHelpDrawerToggle
        icon={toggleIcon}
        open={open}
        anyDrawerOpen={anyDrawerOpen}
        openDrawer={openDrawer}
        toggleClassName={toggleClassName}
        inlineToggle={inlineToggle}
        toggleText={toggleText}
        aria-controls={controlName}
      />
      {open && (
        <MctHelpDrawer
          closeDrawer={closeDrawer}
          large={large}
          drawerTitle={drawerTitle}
          footer={footer}
          id={controlName}
          className={className}
          dialogClassName={dialogClassName}
          {...optionalDrawerProps}
        >
          {children}
        </MctHelpDrawer>
      )}
    </>
  );
};

export default HelpDrawerWrapper;
