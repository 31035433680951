import { createContext, useContext } from "react";
import { ReferrerContextValues } from "./types";
import routes from "../../routes";

export const initialReferrerContextState = {
  goBackTextId: "",
  previousRoute: routes.anonLanding,
} as ReferrerContextValues;
export const ReferrerContext = createContext(initialReferrerContextState);
export const useReferrerContext = () => {
  const context = useContext(ReferrerContext);
  if (context === undefined) {
    throw new Error(
      "useReferrerContext must be used within a ReferrerContextProvider"
    );
  }
  return context;
};
