/**
 * @param t - A translation from en-US.json (e.g., t["some_translation_key"])
 * @param replacements - Any number of strings to fill in variables in the translation
 * @returns - Interpolated message
 */
export const interpolate = (
  t: string,
  ...replacements: (string | number)[]
): string => {
  const stringParts: string[] = t.split(/{[^}]+}/);
  return stringParts
    .map((part, i) => `${part}${replacements[i] || ""}`)
    .join("");
};

/**
 * Removes HTML tags from Phrase content, useful for asserting on expected content
 * using `byText` queries, where tags mixed in with text will fail an assertion
 */
export const interpolateStripMarkup = (
  t: string,
  ...replacements: (string | number)[]
): string => {
  const interpolated = interpolate(t, ...replacements);
  return interpolated.replace(/<[^>]+>/g, "");
};
