import { useFlags } from "launchdarkly-react-client-sdk";
import { Dispatch, useCallback } from "react";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import URI from "urijs";
import {
  Action,
  UserLanguage,
  ActionType,
  MBPLandingParams,
  Envs,
  RolloverPlanInfo,
  MBPTargets,
} from "../@types";
import {
  getCounties,
  getCounty,
  getLoggedInBeneInfo,
  getBeneDrugs,
  getBenePharmacies,
} from "../api";
import routes from "../app/routes";
import { updateBeneInfo } from "./beneficiaryInfoHelpers";
import { useAppContext } from "./context-hooks/useAppContext";
import { logError, ApiError } from "./errors";
import { initiateLogin, parseDegradedIntegrations } from "./loginHelpers";
import { setMbpReferrerUri } from "./mbpReferrerHelpers";
import { makePlanLongId } from "./objectUtilities";
import { getPharmacyType } from "./pharmacyHelpers";
import {
  fetchCurrentPlan,
  isCurrentPlan,
  fetchPlanIdsForNextYear,
} from "./planHelpers";
import { getPlanDetailsStarRatingsRoute } from "./routeHelpers";
import { getIsSlsxAuthenticated } from "./sessionHelpers";
import { getEnvironment } from "./urlHelpers";
import { getYearPartInfo } from "./yearFlagHelpers";

/**
 * Handler specific to MBP callback routes, linked from global header
 * @returns {boolean} - Tells calling session handler whether to switch from loading
 * state to rendering children
 */
export const handleMbpLandingRoute = async ({
  dispatch,
  search,
  history,
  language,
  isOutsideOpenEnrollment,
}: {
  dispatch: Dispatch<Action>;
  search: string;
  history: RouteComponentProps["history"];
  language: UserLanguage;
  isOutsideOpenEnrollment: boolean | undefined;
}): Promise<boolean> => {
  const handleMbpFailure = () => {
    dispatch({ type: ActionType.UPDATE_MBP_HANDOFF_FAILED, payload: true });
    history.replace(routes.summary.landingPage);
    return false;
  };

  dispatch({
    type: ActionType.UPDATE_MBP_HANDOFF_FAILED,
    payload: false,
  });

  const tempRoute = new URI("/");

  const searchObj = URI.parseQuery(search) as MBPLandingParams;

  // If we are using SLSx and there isn't a session, we need to
  // send the user to SSO
  // This is an edge case, maybe only valid for when someone pastes a URL they
  // captured when previously logged-in
  if (!(await getIsSlsxAuthenticated())) {
    const isLocal = getEnvironment() === Envs.local;

    initiateLogin(
      language,
      isLocal
        ? "https://localhost/mbp"
        : `${window.location.origin}/plan-compare/mbp`,
      searchObj
    );
    return false;
  }

  // Start with the values from URI search/query params
  let params: MBPLandingParams = searchObj;

  const { relay } = searchObj;

  if (relay) {
    try {
      // if `relay` exists, it should be a base64-encoded string
      const decodedRelay: MBPLandingParams = JSON.parse(atob(relay));

      // Overwrite params using values from decoded `relay`
      params = decodedRelay;
    } catch (e) {
      logError("Failed to parse the relay (MBPLandingPage)", e as ApiError);

      return handleMbpFailure();
    }
  }

  const {
    fips,
    zipCode,
    target,
    planYear: contract_year,
    planId: plan_id,
    contractId: contract_id,
    segmentId: segment_id,
    lang = UserLanguage.ENGLISH,
    mbp_redirect,
  } = params;

  setMbpReferrerUri(dispatch, mbp_redirect);

  if (!target) {
    return handleMbpFailure();
  }

  let finalFips = fips;

  dispatch({ type: ActionType.UPDATE_LANGUAGE, payload: lang });

  if (!fips && zipCode) {
    dispatch({ type: ActionType.UPDATE_ZIPCODE, payload: zipCode });
    // TODO: If possible, we should make use of the `useCounties` hook instead of calling the endpoint directly here.
    // TODO: https://jira.cms.gov/browse/MCT-9683
    const counties = await getCounties(String(zipCode));
    if (counties.length === 1) {
      finalFips = counties[0].fips;
      dispatch({
        type: ActionType.UPDATE_COUNTY,
        payload: counties[0],
      });
    }
  }

  if (finalFips) {
    dispatch({ type: ActionType.UPDATE_FIPS, payload: finalFips });
    await getCounty(finalFips).then(county =>
      dispatch({ type: ActionType.UPDATE_COUNTY, payload: county })
    );
  }

  let nextYearPlanIds: RolloverPlanInfo[] | undefined;

  try {
    const { headers, beneficiary: bene } = await getLoggedInBeneInfo();

    const degradedIntegrations = parseDegradedIntegrations(headers);

    if (degradedIntegrations.length) {
      dispatch({
        type: ActionType.UPDATE_DEGRADED_INTEGRATIONS,
        payload: degradedIntegrations,
      });
    }

    if (bene) {
      updateBeneInfo({
        beneInfo: bene,
        dispatch,
      });

      if (bene.coverage_current.length) {
        await fetchCurrentPlan({
          beneficiary: bene,
          dispatch,
          futureLis: bene.future_lis_level,
          isOutsideOpenEnrollment,
          lis: bene.lis_level,
        });
      }

      try {
        const drugs = await getBeneDrugs();

        dispatch({
          type: ActionType.SET_PRESCRIPTIONS,
          payload: drugs,
        });

        if (target === MBPTargets.PRESCRIPTIONS) {
          tempRoute.href(
            `${routes.prescriptionsList}?fips=${finalFips ? finalFips : ""}`
          );
        }
      } catch (e) {
        logError(
          "Could not get beneficiary's drugs (MBPLandingPage)",
          e as ApiError
        );

        if (target === MBPTargets.PRESCRIPTIONS) {
          return handleMbpFailure();
        }
      }

      try {
        const { pharmacies, mail_order } = await getBenePharmacies();
        dispatch({
          type: ActionType.SET_PHARMACIES,
          payload: pharmacies,
        });

        dispatch({
          type: ActionType.UPDATE_PHARMACY_TYPE,
          payload: getPharmacyType(pharmacies, mail_order),
        });

        if (target === MBPTargets.PHARMACIES) {
          tempRoute.href(routes.managePharmacies);
        }
      } catch (e) {
        logError(
          "Failed to get beneficiary pharmacies (MBPLandingPage)",
          e as ApiError
        );

        if (target === MBPTargets.PHARMACIES) {
          return handleMbpFailure();
        }
      }

      if (target === MBPTargets.PLAN || target === MBPTargets.STAR_RATINGS) {
        if (contract_year && plan_id && segment_id && contract_id) {
          const idParts = {
            contract_year,
            plan_id,
            segment_id,
            contract_id,
          };
          // If we are not outside open enrollment and the id passed in is different from
          // what we have as the bene's current coverage, we defer to the passed
          // in id when determining the id of the next year's plan
          if (
            !isOutsideOpenEnrollment &&
            !isCurrentPlan(bene.coverage_current[0], idParts)
          ) {
            nextYearPlanIds = await fetchPlanIdsForNextYear(idParts);
          }
          const urlPlanId =
            nextYearPlanIds && nextYearPlanIds.length === 1
              ? makePlanLongId(nextYearPlanIds[0])
              : makePlanLongId(idParts);

          if (target === MBPTargets.PLAN) {
            tempRoute.href(routes.planDetails.replace(":id", urlPlanId));
          } else if (target === MBPTargets.STAR_RATINGS) {
            tempRoute.href(
              getPlanDetailsStarRatingsRoute(
                nextYearPlanIds && nextYearPlanIds.length
                  ? nextYearPlanIds[0]
                  : idParts,
                language,
                contract_year
              )
            );
          }
        } else {
          return handleMbpFailure();
        }
      }
    }

    if (target === MBPTargets.GATEWAY) {
      tempRoute.href(routes.summary.landingPage);
    }

    if (target === MBPTargets.ENROLL_STATUS) {
      tempRoute.href(routes.enrollmentStatus);
    }

    history.replace(tempRoute.addQuery({ lang }).toString());
    return true;
  } catch (e) {
    logError("Could not get beneficiary (MBPLandingPage)", e as ApiError);
    return handleMbpFailure();
  }
};

/**
 * Hook provides all needed arguments to `handleMpbLandingRoute` and returns it,
 * memoized for all of its dependencies
 */
export const useHandleMbpLandingRoute = () => {
  const {
    state: { language },
    dispatch,
  } = useAppContext();
  const { search } = useLocation();
  const history = useHistory();
  const flags = useFlags();
  const { isOutsideOpenEnrollment } = getYearPartInfo(flags);
  const handleRoute = useCallback(async () => {
    return handleMbpLandingRoute({
      dispatch,
      search,
      isOutsideOpenEnrollment,
      history,
      language,
    });
  }, [dispatch, history, isOutsideOpenEnrollment, language, search]);
  return handleRoute;
};
