import { AppState } from "../../../@types";
import {
  createGa4Event,
  createTealiumEvent,
  sendPageView,
  sendGa4Event,
  sendTealiumEvent,
  sendTealiumLink,
  sendTealiumPageView,
} from "./helpers";
import {
  AnalyticsActions,
  AnalyticsActionType,
  Ga4Event,
  Ga4EventType,
} from "./types";

export function analyticsReducer(
  state: AppState,
  action: AnalyticsActions
): AppState {
  switch (action.type) {
    case AnalyticsActionType.SEND_GA4_EVENT: {
      const { settings } = action;
      sendGa4Event({ state, settings });
      return state;
    }
    case AnalyticsActionType.SEND_ALERT_IMPRESSION_EVENT: {
      if (!window.utag?.link) {
        return state;
      }
      const { heading, text, type } = action.settings;
      const ga4Dimensions = createGa4Event({
        settings: {
          event_name: Ga4Event.ALERT_IMPRESSION,
          heading: heading,
          text: text,
          alert_type: type,
        },
        state,
      });
      const uaDimensions = createTealiumEvent({
        event_action: `alert impression:${type}`,
        event_label: heading,
        other_props: {
          event_category: Ga4EventType.CONTENT_TOOLS,
        },
      });
      window.utag.link({
        ...ga4Dimensions,
        ...uaDimensions,
      });
      return state;
    }
    case AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT: {
      if (!window.utag?.link) {
        return state;
      }
      const { button, custom = {}, linkUrl = "" } = action.settings;
      const ga4Dimensions = createGa4Event({
        settings: {
          custom,
          event_name: Ga4Event.BUTTON_ENGAGEMENT,
          event_type: Ga4EventType.UI_INTERACTION,
          link_url: linkUrl,
          text: button.text,
          button_style: button.buttonStyle,
          button_type: button.buttonType,
        },
        state,
      });
      const uaDimensions = createTealiumEvent({
        event_action: `engaged ${button.buttonStyle} button`,
        event_label:
          custom?.event_label ||
          `type: ${button.buttonType}: ${button.text}: ${linkUrl}`,
        other_props: {
          event_category: Ga4EventType.UI_INTERACTION,
        },
      });
      window.utag.link({
        ...ga4Dimensions,
        ...uaDimensions,
      });
      return state;
    }
    case AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT: {
      if (!window.utag?.link) {
        return state;
      }
      /**
       * Used primarily for links that go to external sites (???)
       * @param custom {object} - Pass custom dimensions for GA4
       * @param custom.link_text {string} - Override link text for UA
       */
      const {
        custom = {},
        linkText,
        linkUrl = "",
        text = "",
        parent_component_heading,
        parent_component_type,
      } = action.settings;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { link_text, ...rest } = custom;
      const ga4Dimensions = createGa4Event({
        settings: {
          custom: rest,
          event_name: Ga4Event.INTERNAL_LINK_CLICKED,
          event_type: Ga4EventType.UI_INTERACTION,
          link_text: linkText,
          link_url: linkUrl,
          text: text,
          parent_component_heading,
          parent_component_type,
        },
        state,
      });
      const uaLinkText = custom?.link_text || `${linkText}: ${linkUrl}`;
      const uaDimensions = createTealiumEvent({
        event_action: `${
          custom?.event_action || "internal link clicked"
        }: ${linkText}`,
        event_label: uaLinkText,
        other_props: {
          event_category: Ga4EventType.UI_INTERACTION,
        },
      });
      window.utag.link({ ...ga4Dimensions, ...uaDimensions });
      return state;
    }
    case AnalyticsActionType.SEND_TEALIUM_PAGE_VIEW: {
      const props = action.settings;
      sendTealiumPageView({ props, state });
      return state;
    }
    case AnalyticsActionType.SEND_TEALIUM_LINK: {
      const props = action.settings;
      sendTealiumLink(props);
      return state;
    }
    case AnalyticsActionType.SEND_TEALIUM_EVENT: {
      const props = action.settings;
      sendTealiumEvent(props);
      return state;
    }
    case AnalyticsActionType.SEND_PAGE_VIEW: {
      const props = action.settings;
      sendPageView({ props, state });
      return state;
    }
    default: {
      return state;
    }
  }
}
