import React from "react";
import { getLinkToMedicare } from "../../helpers/urlHelpers";
import { AnalyticsActionType } from "../../app/contexts/Analytics/types";
import { FCWithChildren } from "../../@types";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";

export const LinkAvoidPartDPenalty: FCWithChildren = ({ children }) => {
  const {
    state: { language },
    dispatch,
  } = useAppContext();
  const href = getLinkToMedicare({
    language,
    mgovUrlKey: "avoidPartDPenalties",
  });
  const text = String(children);
  return (
    <a
      href={href}
      onClick={() => {
        dispatch({
          type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
          settings: {
            linkText: text,
            linkUrl: href,
            text: text,
          },
        });
      }}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
