import React, { ReactNode } from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";

/** @returns <Alert> component with hideIcon set to false */
const NoIconAlert = (heading: string, message: ReactNode): JSX.Element => {
  return (
    <Alert
      className="ds-u-margin-y--2 ds-u-margin-left--1"
      hideIcon={true}
      heading={heading}
      role="alert"
    >
      {message}
    </Alert>
  );
};

export default NoIconAlert;
