import { useFlags } from "launchdarkly-react-client-sdk";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { MctYearPartFlag } from "../@types";

/**
 * @see {@link https://imp.medicare.gov/plan-compare/guide/external_dependencies/launchdarkly/use.html?highlight=year%20parts#launchdarkly-mct-current-plan-year-and-mct-year-part-flags|LaunchDarkly Year Part flags}
 */
export interface YearPartInfo {
  isOutsideOpenEnrollment: boolean | undefined;
  isPublicPreview: boolean | undefined;
  isOpenEnrollment: boolean | undefined;
  isOpenEnrollmentNextYearOnly: boolean | undefined;
}

const undefinedYearPart = {
  isPublicPreview: undefined,
  isOpenEnrollment: undefined,
  isOpenEnrollmentNextYearOnly: undefined,
  isOutsideOpenEnrollment: undefined,
};

export const getYearPartInfo = (flags: LDFlagSet | undefined): YearPartInfo => {
  return flags && flags.mctYearPart
    ? {
        isPublicPreview: flags.mctYearPart === MctYearPartFlag.PUBLIC_PREVIEW,
        isOpenEnrollment: flags.mctYearPart === MctYearPartFlag.OPEN_ENROLLMENT,
        isOpenEnrollmentNextYearOnly:
          flags.mctYearPart === MctYearPartFlag.OPEN_ENROLLMENT_NEXT_YEAR_ONLY,
        isOutsideOpenEnrollment:
          flags.mctYearPart === MctYearPartFlag.OUTSIDE_OPEN_ENROLLMENT,
      }
    : undefinedYearPart;
};

export const getYearPartInfoFromYearPart = (
  mctYearPart: MctYearPartFlag
): YearPartInfo => getYearPartInfo({ mctYearPart });

export const useYearPart = (): YearPartInfo & { yearPart: MctYearPartFlag } => {
  const flags = useFlags();
  const { mctYearPart } = flags as {
    mctYearPart: MctYearPartFlag;
  };
  return {
    ...getYearPartInfo(flags),
    yearPart: mctYearPart,
  };
};

/** returns the current plan year from LaunchDarkly as a number
 * @example const currentPlanYear = useCurrentPlanYear(); // 2023
 */
export const useCurrentPlanYear = (): number => {
  const { mctCurrentPlanYear } = useFlags();
  return mctCurrentPlanYear as number;
};
