import React, { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ActionType, FCWithChildren } from "../@types";
import {
  getTealiumAsyncScriptUrl,
  getTealiumEnvironment,
  getTealiumSyncScriptUrl,
} from "../helpers/tealiumHandlerHelpers";
import { getEnvironment } from "../helpers/urlHelpers";
import { devDebug } from "../helpers/loggingHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

/**
 * NOTE: `AppState.tealiumLoaded` defaults to `false` on every page load
 */
export const TealiumHandler: FCWithChildren = ({ children }) => {
  const {
    state: { tealiumLoaded },
    dispatch,
  } = useAppContext();
  const { feEnableTealium, tmpFeEnableFirstPartyTealiumSource } =
    useFlags() as {
      feEnableTealium: boolean;
      tmpFeEnableFirstPartyTealiumSource: boolean;
    };
  const [jQueryLoaded, setJQueryLoaded] = useState(false);
  const [script1Inserted, setScript1Inserted] = useState(false);
  const [script2Inserted, setScript2Inserted] = useState(false);
  const [script3Inserted, setScript3Inserted] = useState(false);
  const [script3Loaded, setScript3Loaded] = useState(false);

  const env = getEnvironment();
  const tealiumEnv = getTealiumEnvironment(env);

  /***
   * 1. Load jQuery
   */
  useEffect(() => {
    if (!feEnableTealium || jQueryLoaded || tealiumLoaded) {
      return;
    }
    if (window["jQuery"]) {
      setJQueryLoaded(true);
      return;
    }
    (async () => {
      const $ = await import("jquery/dist/jquery.slim.min");
      window["jQuery"] = $.default;
      window["$"] = $.default;
      setJQueryLoaded(true);
    })();
  }, [feEnableTealium, jQueryLoaded, tealiumLoaded]);

  /***
   * 2. Insert Tealium script tag #1 after jQuery is loaded
   */
  useEffect(() => {
    if (!jQueryLoaded || script1Inserted || tealiumLoaded) {
      return;
    }
    const script1 = document.createElement("script");
    script1.innerHTML =
      "var utag_data; window.utag_cfg_ovrd = { noview: true };";
    script1.type = "text/javascript";
    document.head.appendChild(script1);
    setScript1Inserted(true);
  }, [jQueryLoaded, script1Inserted, tealiumLoaded]);

  /***
   * 3. Insert Tealium script tag #2 after the first is inserted
   */
  useEffect(() => {
    if (!script1Inserted || script2Inserted || tealiumLoaded) {
      return;
    }
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = getTealiumSyncScriptUrl(
      tealiumEnv,
      tmpFeEnableFirstPartyTealiumSource
    );
    script2.defer = true;
    document.head.appendChild(script2);
    setScript2Inserted(true);
  }, [
    script1Inserted,
    script2Inserted,
    tealiumEnv,
    tealiumLoaded,
    tmpFeEnableFirstPartyTealiumSource,
  ]);

  /***
   * 4. Insert Tealium script tag #3 after the second is inserted
   */
  useEffect(() => {
    if (!script2Inserted || script3Inserted || tealiumLoaded) {
      return;
    }
    const script3 = document.createElement("script");
    script3.src = getTealiumAsyncScriptUrl(
      tealiumEnv,
      tmpFeEnableFirstPartyTealiumSource
    );
    script3.type = "text/javascript";
    script3.async = true;
    script3.onload = (): void => {
      setScript3Loaded(true);
    };
    document.body.appendChild(script3);
    setScript3Inserted(true);
  }, [
    dispatch,
    script2Inserted,
    script3Inserted,
    tealiumEnv,
    tealiumLoaded,
    tmpFeEnableFirstPartyTealiumSource,
  ]);

  /***
   * 5. Wait for the load event of Tealium script #3 to dispatch `tealiumLoaded`
   */
  useEffect(() => {
    if (!script3Loaded || tealiumLoaded) {
      return;
    }
    dispatch({
      type: ActionType.SET_TEALIUM_LOADED,
      payload: true,
    });
  }, [dispatch, script3Loaded, tealiumLoaded]);

  /***
   * 6. Log for dev purposes
   */
  useEffect(() => {
    if (tealiumLoaded) {
      devDebug("☕️ Tealium loaded!");
    }
  }, [tealiumLoaded]);

  /***
   * If the flag is enabled and jQuery loaded, Tealium will be enabled
   */
  return (
    <>
      {feEnableTealium && jQueryLoaded && (
        <span
          id="app-utag1"
          data-testid="app-utag1"
          className="ds-u-display--none"
        >
          Tealium Enabled
        </span>
      )}
      {children}
    </>
  );
};
