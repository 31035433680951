import React from "react";
import {
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@cmsgov/ds-medicare-gov";
import { FormattedMessage } from "react-intl";
import { interpolateStripMarkup } from "../../helpers/translationHelpers";
import { usePharmacyIsOon } from "../../helpers/pharmacyHelpers";
import { getLocaleFromUserLang } from "../../helpers/dateHelpers";
import {
  TranslationKey,
  useFormatMessage,
  useTypedTranslate,
} from "../../helpers/intlHooks";
import { $$ } from "../../helpers/currencyUtilities";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import {
  getPlanStackedHeadings,
  getTranslatedMonthNameFromM3pDate,
} from "./m3pModalHelpers";
import { M3PModalPlanHeading } from "./M3PModalPlanHeading";
import { M3pData } from "../../@types";
import { SelectedPharmacy } from "./types";
import { useAnalyticsImpression } from "../../app/contexts/Analytics/eventHooks/useAnalyticsImpression";

export interface M3PTableProps {
  m3pData: M3pData;
  selectedPharmacy: SelectedPharmacy;
}

export const m3pTableHeadingId = "m3p-monthly-cost";
export const m3pTableHeadingTranslationKey: TranslationKey =
  "m3p.modal.table.heading.your_monthly_cost";

export const M3PTable = ({
  m3pData,
  selectedPharmacy: { label: name, value: npi },
}: M3PTableProps) => {
  // * Context
  const {
    state: { language },
  } = useAppContext();
  const fm = useFormatMessage();
  const t = useTypedTranslate();

  // * Analytics
  const alertHeadingText = t("m3p.modal.alert.out_of_network_pharmacy.heading");
  const alertContentText = interpolateStripMarkup(
    t("m3p.modal.alert.out_of_network_pharmacy.content"),
    name
  );

  const alertRef = useAnalyticsImpression({
    event_name: "alert_impression",
    type: "informational",
    heading: alertHeadingText,
    text: alertContentText,
  });

  const stackedHeadings = getPlanStackedHeadings(fm);
  const locale = getLocaleFromUserLang(language);
  const monthHeading = t("m3p.modal.table.heading.month");
  const currentPharmacyIsOon = usePharmacyIsOon(npi);
  const {
    estimated_monthly_costs,
    estimated_yearly_mppp_cost,
    estimated_yearly_part_d_only_cost,
  } = m3pData[npi];

  return currentPharmacyIsOon ? (
    <Alert heading={alertHeadingText}>
      <div ref={alertRef}>
        <FormattedMessage
          id="m3p.modal.alert.out_of_network_pharmacy.content"
          values={{
            pharmacy_name: name,
            p: chunks => <p>{chunks}</p>,
          }}
        />
      </div>
    </Alert>
  ) : (
    <Table
      aria-labelledby={m3pTableHeadingId}
      className="mct-c-modal-m3p__cost-breakdown"
      stackable={true}
      stackableBreakpoint="sm"
    >
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} id="m3p-month">
            {monthHeading}
          </TableCell>
          <TableCell colSpan={2} id={m3pTableHeadingId}>
            {t(m3pTableHeadingTranslationKey)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell id="m3p-cost-without">
            <M3PModalPlanHeading withPlan={false} />
          </TableCell>
          <TableCell id="m3p-cost-with">
            <M3PModalPlanHeading />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estimated_monthly_costs.map(row => {
          const monthName = getTranslatedMonthNameFromM3pDate({
            date: row.date,
            locale,
          });
          return (
            <TableRow key={row.date}>
              <TableCell
                className="mct-c-modal-m3p__cost-breakdown-stacked-header"
                component="th"
                headers="m3p-month"
                stackedTitle={monthHeading}
              >
                {monthName}
              </TableCell>
              <TableCell
                headers="m3p-cost-without"
                stackedTitle={stackedHeadings.withoutPlan}
              >
                {$$(row.part_d_only_total)}
              </TableCell>
              <TableCell
                headers="m3p-cost-with"
                stackedTitle={stackedHeadings.withPlan}
              >
                {$$(row.mppp_total)}
              </TableCell>
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell className="ds-u-font-weight--bold">
            {t("m3p.modal.table.row.heading.total").toUpperCase()}
          </TableCell>
          <TableCell
            className="ds-u-font-weight--bold"
            headers="m3p-cost-without"
            stackedTitle={stackedHeadings.withoutPlan}
          >
            {$$(estimated_yearly_part_d_only_cost)}
          </TableCell>
          <TableCell
            className="ds-u-font-weight--bold"
            headers="m3p-cost-with"
            stackedTitle={stackedHeadings.withPlan}
          >
            {$$(estimated_yearly_mppp_cost)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
