import {
  PackageServiceAvailability as Availability,
  PackageServices,
} from "../@types";

export const isAvailable = (availability: Availability): boolean => {
  const availableOptions = [
    Availability.ANY_DOCTOR,
    Availability.ANY_WILLING_DOCTOR,
    Availability.PLAN_DOCTORS,
    Availability.PLAN_DOCTORS_MOST_SERVICES,
    Availability.PLAN_DOCTORS_SOME_SERVICES,
    Availability.PLAN_DOCTORS_WITH_EXCEPTIONS,
    Availability.PROVIDED,
    Availability.PROVIDED_EXTRA_COST,
  ];

  return availableOptions.includes(availability);
};

export const hasDentalService = (
  contractYear: number,
  services: PackageServices
) => {
  return contractYear > 2024
    ? services.ms_dental_services
    : isAvailable(services.dental_services);
};
