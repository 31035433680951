import { useLoggedIn } from "../loginHelpers";
import { useMemo } from "react";
import { usePendingEnrollments } from "../query-hooks/usePendingEnrollments";
import { useMedigapCoverages } from "../query-hooks/useMedigapCoverages";
import { useAppContext } from "./useAppContext";
import { createNewToMedicareAlertInfo } from "./helpers";

/**
 * a hook to access NTM alert info,
 * from app context (if anon) or from bene info and endpoints (if logged in)
 */
export const useNewToMedicareAlertInfo = () => {
  // * Hooks
  const isLoggedIn = useLoggedIn();

  // * Queries
  const { oecStatuses } = usePendingEnrollments();
  const { medigapCoverages } = useMedigapCoverages();

  // * Context
  const {
    state: { anonNewToMedicareAlertInfo, beneficiary },
  } = useAppContext();

  // * Memos
  const liNewToMedicareAlertInfo = useMemo(() => {
    return beneficiary
      ? createNewToMedicareAlertInfo({
          bene: beneficiary,
          oecStatuses,
          medigapCoverages,
        })
      : undefined;
  }, [beneficiary, medigapCoverages, oecStatuses]);

  return isLoggedIn ? liNewToMedicareAlertInfo : anonNewToMedicareAlertInfo;
};
