import React, { useContext } from "react";
import { SearchResultPlan } from "../../@types";
import CollapsibleBox from "../CollapsibleBox";
import { PlanBenefitCheckmark } from "./PlanBenefitCheckmark";
import {
  hasDentalService,
  isAvailable,
} from "../../helpers/planBenefitHelpers";
import { useTranslate } from "../../helpers/intlHooks";
import { PreviewContext } from "../../helpers/PreviewContext";

interface PlanBenefitsProps {
  plan: SearchResultPlan;
}

const PlanBenefits: React.FunctionComponent<PlanBenefitsProps> = ({ plan }) => {
  const t = useTranslate();
  const previewMode = useContext(PreviewContext);
  const {
    package_services: services,
    transportation,
    silver_sneakers,
    worldwide_emergency,
    telehealth,
    otc_drugs,
    in_home_support,
    home_safety_devices,
    emergency_response_device,
    contract_year,
  } = plan;

  if (!services) {
    return null;
  }

  return (
    <div className="PlanCard__section" data-testid="benefits">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.plan_benefits")}
      </h3>
      <ul className="PlanCard__benefits">
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.vision")}
            checked={isAvailable(services.vision_services)}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.dental")}
            checked={hasDentalService(+contract_year, services)}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.hearing")}
            checked={services.ms_hearing_services}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.transportation")}
            checked={transportation}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.fitness_benefits")}
            checked={silver_sneakers}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.worldwide_emergency")}
            checked={worldwide_emergency}
          />
        </li>
        <li>
          <PlanBenefitCheckmark
            text={t("plan_terms.telehealth")}
            checked={telehealth}
          />
        </li>
      </ul>
      <CollapsibleBox
        labelId="plan_terms.see_more_benefits"
        titleClassname="PlanCard__collapsible"
        open={previewMode}
      >
        <ul className="PlanCard__benefits">
          <li>
            <PlanBenefitCheckmark
              text={t("plan_terms.otc_drugs")}
              checked={otc_drugs}
            />
          </li>
          <li>
            <PlanBenefitCheckmark
              text={t("plan_terms.in_home_support")}
              checked={in_home_support}
            />
          </li>
          <li>
            <PlanBenefitCheckmark
              text={t("plan_terms.home_safety_devices")}
              checked={home_safety_devices}
            />
          </li>
          <li>
            <PlanBenefitCheckmark
              text={t("plan_terms.emergency_response_device")}
              checked={emergency_response_device}
            />
          </li>
        </ul>
      </CollapsibleBox>
    </div>
  );
};

export default PlanBenefits;
