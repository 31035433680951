import React, { useState, ChangeEvent, useEffect } from "react";
import { Dropdown } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import { getDrugsByLetter, getPapDrugsByLetter } from "../api";
import { AutocompleteDrug, AutocompletePapDrug } from "../@types";
import { FormattedMessage } from "react-intl";
import { HelpDrawerWrapper } from ".";
import {
  HelpDrawerWrapperProps,
  MctHelpDrawerFooterProps,
} from "./HelpDrawerWrapper";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export interface AtoZDrugListProps {
  onAddDrug: (drug: AutocompleteDrug | AutocompletePapDrug) => void;
  onOpen?: () => void;
  PAP?: boolean;
  selectDrugButtonLabel: string;
}

const AtoZDrugList: React.FunctionComponent<
  Partial<HelpDrawerWrapperProps> & AtoZDrugListProps
> = ({
  onAddDrug,
  onOpen = () => {},
  PAP,
  selectDrugButtonLabel,
  ...props
}) => {
  const t = useTranslate();
  const { state, dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [drugList, setDrugList] = useState(
    [] as AutocompletePapDrug[] | AutocompleteDrug[]
  );
  const [drugLetter, setDrugLetter] = useState("");
  const [selectedDrug, setSelectedDrug] = useState(
    {} as AutocompleteDrug | AutocompletePapDrug
  );

  const observeScroll = (event: Event): void => {
    const { target } = event;
    const selectContainer = document.querySelector(
      ".AtoZDrugList__selectContainer"
    );

    if (target && selectContainer) {
      if ((target as HTMLDivElement).scrollTop > 10) {
        selectContainer.classList.add(
          "AtoZDrugList__selectContainer--shadowed"
        );
      } else {
        selectContainer.classList.remove(
          "AtoZDrugList__selectContainer--shadowed"
        );
      }
    }
  };

  const ucFirst = (drugName: string): string => {
    return drugName.substring(0, 1).toUpperCase() + drugName.substring(1);
  };

  const makeAlphabet = (): string[] => {
    let curr = "A".charCodeAt(0);
    const final = "Z".charCodeAt(0);
    const alphabet = [];
    for (; curr <= final; curr++) {
      alphabet.push(String.fromCharCode(curr));
    }
    return alphabet;
  };

  useEffect(() => {
    if (open) {
      const drawer = document.querySelector(
        ".AtoZDrugList .ds-c-help-drawer__body"
      );

      if (drawer) {
        drawer.addEventListener("scroll", observeScroll);
      }

      setDrugLetter("A");
    }
  }, [open]);

  useEffect(() => {
    // look up drugs by letter
    (async (): Promise<void> => {
      if (drugLetter) {
        try {
          setDrugList(
            PAP
              ? await getPapDrugsByLetter(drugLetter)
              : await getDrugsByLetter(drugLetter, state.year)
          );
        } catch (_e) {
          // do nothing, the list will be empty
        }
      }
    })();
  }, [PAP, drugLetter, state.year]);

  const resetSelections = (): void => {
    setDrugList([]);
    setDrugLetter("");
    setSelectedDrug({} as AutocompleteDrug);
  };

  const footerContent: MctHelpDrawerFooterProps = {
    className: "AtoZDrugList__confirm",
    id: "drug-list-footer",
    children: selectedDrug.name && <p>{selectedDrug.name}</p>,
    buttons: [
      {
        text: selectDrugButtonLabel,
        id: "aToZAddDrug",
        "aria-label": `${t("drug_search.add_drug")} ${selectedDrug.name}`,
        shouldCloseDrawer: true,
        variation: "solid",
        onDark: true,
        isAlternate: true,
        onClick: (): void => {
          resetSelections();
          setOpen(false);
          onAddDrug(selectedDrug);
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: {
              event_action: "Browse drugs A-Z",
              event_label: PAP ? "See Programs" : "Add Drugs",
              other_props: PAP ? { event_category: "PAP" } : undefined,
            },
          });
        },
      },
      {
        className: "ds-u-margin-left--2",
        onDark: true,
        text: t("cancel"),
        shouldCloseDrawer: true,
        onClick: () => {
          setSelectedDrug({} as AutocompleteDrug);
        },
      },
    ],
  };

  const renderedFooter = selectedDrug.name ? { footer: footerContent } : {};

  return (
    <div className="AtoZDrugList">
      <HelpDrawerWrapper
        inlineToggle={true}
        toggleText={t("drug_search.browse_drugs_az")}
        headingLevel="2"
        className="AtoZDrugList__drawer"
        drawerTitle={t("drug_search.browse_drugs_az")}
        onOpenDrawer={() => {
          onOpen();
          setOpen(true);
          // Trigger aria-live update
          if (drugLetter) {
            setDrugLetter(drugLetter);
          }
        }}
        onCloseDrawer={() => {
          setOpen(false);
          resetSelections();
        }}
        enableDefaultAnalytics={true}
        {...renderedFooter}
        {...props}
      >
        <div className="AtoZDrugList__drawerBody">
          <div className="AtoZDrugList__selectContainer">
            <Dropdown
              label={t("drug_search.select_a_letter")}
              labelClassName="AtoZDrugList__selectLabel"
              size="small"
              name="A to Z Drug List Select"
              className="AtoZDrugList__select"
              value={drugLetter}
              id="browse-select-letter"
              onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                resetSelections();
                setDrugLetter(event.target.value);
              }}
              options={makeAlphabet().map(letter => ({
                label: letter,
                value: letter,
              }))}
            />
          </div>
          <div className="AtoZDrugList__resultsContainer">
            <div
              role="region"
              aria-live="polite"
              aria-atomic="true"
              className="AtoZDrugList__total"
            >
              <b>{drugLetter}</b>{" "}
              <div className="ds-u-visibility--screen-reader">
                <FormattedMessage
                  id="drug_search.drugs_found.optional_plural"
                  values={{
                    count: drugList.length,
                    plural_s:
                      drugList.length > 1 || drugList.length === 0 ? "s" : "",
                  }}
                />
              </div>
              <div aria-hidden="true" className="ds-u-display--inline">
                (
                <FormattedMessage
                  id="drug_search.drugs_found"
                  values={{ count: drugList.length }}
                />
                )
              </div>
            </div>
            <ul className="AtoZDrugList__results">
              {(drugList as Array<AutocompleteDrug | AutocompletePapDrug>).map(
                (drug, index) => {
                  const id = PAP
                    ? (drug as AutocompletePapDrug).id
                    : (drug as AutocompleteDrug).rxcui;
                  return (
                    <li key={index}>
                      <label
                        htmlFor={id}
                        className={
                          drug.name === selectedDrug.name ? "selected" : ""
                        }
                      >
                        {ucFirst(drug.name)}
                      </label>
                      <input
                        className="ds-u-visibility--screen-reader"
                        id={id}
                        type="radio"
                        name="AtoZDrug"
                        onChange={(): void => {
                          setSelectedDrug(drug);
                        }}
                      />
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </HelpDrawerWrapper>
    </div>
  );
};

export default AtoZDrugList;
