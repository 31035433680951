import URI from "urijs";
import { Location } from "history";
import { ActionType, UserLanguage } from "../@types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import routes from "../app/routes";
import { useAppContext } from "./context-hooks/useAppContext";
import { parseSearchParams } from "./objectUtilities";

export const parseLangFromRelayParam = (relay = ""): UserLanguage | null => {
  try {
    // relay should be a base64-encoded string
    const { lang }: { lang: string } = JSON.parse(atob(relay));
    // Get valid values from UserLanguage enum
    if (!Object.values(UserLanguage).find(val => lang === val)) {
      throw new Error(
        "Encoded 'lang' value in URL's 'relay' param is not a valid UserLanguage"
      );
    }
    return lang as UserLanguage;
  } catch (e) {
    return null;
  }
};

export const getLanguageFromLocationOrState = ({
  location: { search },
  stateLang,
}: {
  location: Location;
  stateLang: UserLanguage;
}): UserLanguage => {
  const { relay, lang: searchLang } = parseSearchParams(URI.parseQuery(search));
  let language = stateLang;
  if (searchLang && searchLang !== stateLang) {
    language = searchLang as UserLanguage;
  }
  if (relay) {
    const relayLang = parseLangFromRelayParam(relay);
    language = relayLang || language;
  }
  return language;
};

export const useSetLangFromRelayParam = () => {
  const location = useLocation();

  const isLoginCallbackRoute = location.pathname === routes.slsCallback;
  const { state, dispatch } = useAppContext();
  const [userLang, setUserLang] = useState(state.language);

  if (isLoginCallbackRoute) {
    const { relay } = parseSearchParams(URI.parseQuery(location.search));
    const langFromRelay =
      parseLangFromRelayParam(relay) || UserLanguage.ENGLISH;
    if (userLang !== langFromRelay) {
      setUserLang(langFromRelay);
    }
  }

  useEffect(() => {
    dispatch({ type: ActionType.UPDATE_LANGUAGE, payload: userLang });
  }, [userLang, dispatch]);
};
