import React, { FunctionComponent } from "react";
import { toTitleCase } from "../helpers/formatHelpers";
import { Button } from "@cmsgov/ds-medicare-gov";
import { Pharmacy } from "../@types";
import { useTranslate } from "../helpers/intlHooks";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export interface PharmacyListItemProps {
  handleRemoveClick: (pharmacy: Partial<Pharmacy>) => void;
  pharmacy: Pharmacy;
  shouldHaveBorder: boolean;
}

export const PharmacyListItem: FunctionComponent<PharmacyListItemProps> = ({
  handleRemoveClick,
  pharmacy,
  shouldHaveBorder,
}) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();
  const { name, npi, street, city, state, zipcode } = pharmacy;
  return (
    <li className="ds-u-margin-bottom--6 ds-u-measure--wide">
      <div className="ds-u-margin-bottom--6 ds-u-padding-left--6">
        <h2 className="ds-text-heading--xl" id={npi}>
          {toTitleCase(name)}
        </h2>
        {street && (
          <p className="ds-u-margin-top--1 ds-u-margin-bottom--3">
            {toTitleCase(street)}, {toTitleCase(city)}, {state}, {zipcode}{" "}
          </p>
        )}
        <Button
          className="ds-u-padding--0"
          variation="ghost"
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: t("manage_pharmacies.pharmacy.remove_pharmacy"),
                },
              },
            });
            handleRemoveClick(pharmacy);
          }}
          aria-describedby={npi}
        >
          {t("manage_pharmacies.pharmacy.remove_pharmacy")}
        </Button>
      </div>
      {shouldHaveBorder && <hr className="ds-u-border--1" />}
    </li>
  );
};
