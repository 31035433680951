import React, { FC } from "react";
import Routes from "../../../routes";
import { AlertMessageConditions } from "../types";
import { AlertMessageLink } from "../../../../components";
import { AlertMessageLinkWrapperProps } from "./types";

export const DefaultOneSavedPharmacyLink: FC<AlertMessageLinkWrapperProps> = ({
  children,
  ...props
}) => {
  return (
    <AlertMessageLink
      {...props}
      to={Routes.managePharmacies}
      condition={AlertMessageConditions.ONE_SAVED_PHARMACY}
    >
      {children}
    </AlertMessageLink>
  );
};

export const OneSavedPharmacyLink: FC<AlertMessageLinkWrapperProps> = ({
  children,
  alertMessageContext,
  ...props
}) => {
  const hasPlan = !!alertMessageContext.planId;
  const inNetworkPharmacyFinderLink = Routes.inNetworkPharmacy.replace(
    ":id",
    alertMessageContext?.planId || ""
  );
  const summaryPageAddPharmaciesLink = hasPlan
    ? inNetworkPharmacyFinderLink
    : Routes.pharmacy;
  return (
    <AlertMessageLink
      {...props}
      condition={AlertMessageConditions.ONE_SAVED_PHARMACY}
      to={{
        pathname: alertMessageContext.isFromSummaryPage
          ? summaryPageAddPharmaciesLink
          : inNetworkPharmacyFinderLink,
        state: alertMessageContext?.routerState,
        search: alertMessageContext?.search,
      }}
    >
      {children}
    </AlertMessageLink>
  );
};
