import { Plan, StarRating } from "../@types";

export function getStarRating(
  plan: Plan,
  ratingType: string
): StarRating | undefined {
  const { star_ratings } = plan;

  if (!star_ratings) {
    return undefined;
  }

  const starRating = star_ratings.find(s => s.category === ratingType);

  return starRating ? starRating : undefined;
}

export const hideStarRatings = (
  year: string | undefined,
  {
    enableNextPlanYearStarRatings,
    mctCurrentPlanYear,
  }: { enableNextPlanYearStarRatings: boolean; mctCurrentPlanYear: number }
): boolean => {
  return (
    !enableNextPlanYearStarRatings &&
    !!year &&
    !!mctCurrentPlanYear &&
    year !== mctCurrentPlanYear.toString()
  );
};
