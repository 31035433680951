import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import CCXPBaseDrop from "./CCXPBaseDrop";

interface Props {
  type?: "checkbox" | "radio";
  label: string;
  values: Array<string>;
  hint?: React.ReactNode;
  options: { label: string; value: string }[];
  onUpdate: (value: string[]) => void;
  noColorChange?: boolean;
  size?: "wide" | "widest";
  children?: ReactNode;
}

const CCXPMultiSelect: FunctionComponent<Props> = ({
  type = "checkbox",
  label,
  hint,
  values = [],
  options,
  onUpdate,
  noColorChange,
  size,
  children,
}) => {
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);

  const choices = useMemo(() => {
    return options.map(o => ({
      ...o,
      checked: currentSelection.includes(o.value),
    }));
  }, [currentSelection, options]);

  useEffect(() => {
    setCurrentSelection(values);
  }, [values]);

  return (
    <CCXPBaseDrop
      label={label}
      onApply={() => onUpdate(currentSelection)}
      onClear={() => {
        setCurrentSelection([]);
      }}
      onMenuOpen={() => {
        setCurrentSelection(values);
      }}
      onMenuClose={() => {
        setCurrentSelection([]);
      }}
      hasValue={noColorChange ? false : values.length > 0}
      size={size}
    >
      {children}
      <ChoiceList
        className="ds-u-margin--0"
        type={type}
        name={label}
        hint={hint}
        labelClassName="ds-u-visibility--screen-reader"
        label={label}
        choices={choices}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          const { checked, value } = event.currentTarget;

          const selection =
            checked && !currentSelection.includes(value)
              ? [...currentSelection, value]
              : currentSelection.filter(c => c !== value);
          setCurrentSelection(selection);
        }}
      />
    </CCXPBaseDrop>
  );
};

export default CCXPMultiSelect;
