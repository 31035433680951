import { TranslationKey } from "../../../helpers/intlHooks";

export enum ReferrerContextValueKey {
  GoBackTextId = "goBackTextId",
  IconKey = "iconKey",
  PreviousRoute = "previousRoute",
}

/** Alternative icons for the Actions Bar (top of page, back link) */
export enum RouteReferrerIconKey {
  Back = "back",
  Home = "home",
}

/**
 * The values passed down by the ReferrerContext
 * The Phrase key is required, and an alternative back link icon is optional
 */
export interface ReferrerContextValues {
  [ReferrerContextValueKey.GoBackTextId]: TranslationKey | "";
  [ReferrerContextValueKey.IconKey]?: RouteReferrerIconKey;
  [ReferrerContextValueKey.PreviousRoute]: string;
}
