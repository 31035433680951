import { Envs, TealiumEnvironment } from "../@types";

export const scriptSources = {
  tiqCdn: {
    syncUrl:
      "//tags.tiqcdn.com/utag/cmsgov/medicare-www/{environment}/utag.sync.js",
    asyncUrl:
      "//tags.tiqcdn.com/utag/cmsgov/medicare-www/{environment}/utag.js",
  },
  tealiumTags: {
    syncUrl:
      "https://tealium-tags.medicare.gov/medicare-www/{environment}/utag.sync.js",
    asyncUrl: "//tealium-tags.medicare.gov/medicare-www/{environment}/utag.js",
  },
};

export function getTealiumEnvironment(env: Envs): TealiumEnvironment {
  const tealiumEnvironmentMapping = {
    [Envs.local]: TealiumEnvironment.DEV,
    [Envs.dev]: TealiumEnvironment.DEV,
    [Envs.test]: TealiumEnvironment.DEV,
    [Envs.imp]: TealiumEnvironment.QA,
    [Envs.prod]: TealiumEnvironment.PROD,
  };

  return tealiumEnvironmentMapping[env];
}

export function getTealiumAsyncScriptUrl(
  env: TealiumEnvironment,
  enableFirstPartyTealiumSource: boolean
) {
  const firstPartySource = scriptSources.tealiumTags.asyncUrl.replace(
    "{environment}",
    env
  );
  const thirdPartySource = scriptSources.tiqCdn.asyncUrl.replace(
    "{environment}",
    env
  );

  return enableFirstPartyTealiumSource ? firstPartySource : thirdPartySource;
}

export function getTealiumSyncScriptUrl(
  env: TealiumEnvironment,
  enableFirstPartyTealiumSource: boolean
) {
  const firstPartySource = scriptSources.tealiumTags.syncUrl.replace(
    "{environment}",
    env
  );
  const thirdPartySource = scriptSources.tiqCdn.syncUrl.replace(
    "{environment}",
    env
  );

  return enableFirstPartyTealiumSource ? firstPartySource : thirdPartySource;
}
