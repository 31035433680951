import { CoverageSelectorCoverageType } from "../../@types";
import { MctPlanTypeCoverageSelector } from "../../app/contexts/Analytics/types";

export const choiceIdMap: Record<CoverageSelectorCoverageType, string> = {
  [CoverageSelectorCoverageType.MAPD]: "what-coverage-mapd",
  [CoverageSelectorCoverageType.PDP]: "what-coverage-pdp",
  [CoverageSelectorCoverageType.MedigapPolicy]: "what-coverage-medigap",
  [CoverageSelectorCoverageType.learnMore]: "learn-more",
};

export const planTypeGa4EventMap: Record<
  CoverageSelectorCoverageType,
  MctPlanTypeCoverageSelector
> = {
  [CoverageSelectorCoverageType.MAPD]: MctPlanTypeCoverageSelector.MAPD,
  [CoverageSelectorCoverageType.PDP]: MctPlanTypeCoverageSelector.PDP,
  [CoverageSelectorCoverageType.MedigapPolicy]:
    MctPlanTypeCoverageSelector.MedigapPolicy,
  [CoverageSelectorCoverageType.learnMore]:
    MctPlanTypeCoverageSelector.learnMore,
};

/**
 * in Phrase, these keys are used to differentiate plan types for description text.
 */
export const planTypePhraseMap = {
  [CoverageSelectorCoverageType.MAPD]: "part_c",
  [CoverageSelectorCoverageType.PDP]: "part_d",
  [CoverageSelectorCoverageType.MedigapPolicy]: "medigap",
} as const;
