import { MedigapCoverage } from "../../@types";
import { getBeneMedigapCoverages } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useLoggedIn } from "../loginHelpers";
import { QueryKey } from "./types";

/**
 * a hook to access a bene's medigap coverage insurance
 */
export const useMedigapCoverages = () => {
  // * Hooks
  const isLoggedIn = useLoggedIn();

  // * Queries
  const medigapCoveragesQuery = useQuery({
    queryKey: [QueryKey.MedigapCoverages],
    queryFn: () => {
      return getBeneMedigapCoverages();
    },
    enabled: isLoggedIn,
  });

  // * Constants
  const medigapCoverages: MedigapCoverage[] = medigapCoveragesQuery.data || [];

  return {
    /** an object containing medigap coverages (from other-insurances endpoint) */
    medigapCoverages,
  };
};
