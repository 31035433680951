import React from "react";
import { useTranslate } from "../../helpers/intlHooks";
import { TooltipWithClose } from "..";
import { FCWithChildren } from "../../@types";

export const TooltipCreditableDrugCoverage: FCWithChildren = ({ children }) => {
  const t = useTranslate();
  return (
    <TooltipWithClose
      contentHeading={
        <div
          className="ds-text-heading--lg ds-u-margin-bottom--0"
          role="heading"
          aria-level={2}
        >
          {t("ntm.tooltip.creditable_prescription_drug_coverage.heading")}
        </div>
      }
      title={t("ntm.tooltip.creditable_prescription_drug_coverage.body")}
      className="ds-c-tooltip__trigger-link"
      component="a"
    >
      {children}
    </TooltipWithClose>
  );
};
