import React from "react";
import { AlertMessageLink, PiggyBankCircleIcon } from "../../../components";
import {
  AlertMessageConditions,
  AlertMessageHierarchyKeys,
  AlertMessageType,
} from "./types";
import Routes from "../../routes";
import {
  DefaultNoSavedDrugsLink,
  DefaultNoSavedPharmaciesLink,
  DefaultOneSavedPharmacyLink,
  DefaultOneSavedPharmacyStaleAccountLink,
} from "./components";

/**
 * @constant
 * Object map with the intended location as a key and a ranked list of alert message types ordered from most import to least important.
 * Only the lowest index, active === true message will be displayed for the given key/location.
 */
export const alertMessageHierarchy: Record<
  AlertMessageHierarchyKeys,
  AlertMessageConditions[]
> = {
  [AlertMessageHierarchyKeys.SUMMARY_PAGE__PHARMACY_SECTION]: [
    AlertMessageConditions.NO_SAVED_PHARMACIES,
    AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT,
    AlertMessageConditions.ONE_SAVED_PHARMACY,
    AlertMessageConditions.STALE_ACCOUNT_PHARMACIES,
    AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES,
    AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES,
  ],
  [AlertMessageHierarchyKeys.SUMMARY_PAGE__DRUG_SECTION]: [
    AlertMessageConditions.NO_SAVED_DRUGS,
    AlertMessageConditions.STALE_ACCOUNT_DRUGS,
  ],
  [AlertMessageHierarchyKeys.PLAN_DETAILS__PHARMACY_SECTION]: [
    AlertMessageConditions.NO_SAVED_PHARMACIES,
    AlertMessageConditions.NO_SAVED_DRUGS,
    AlertMessageConditions.ONE_SAVED_PHARMACY,
    AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES,
    AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES,
  ],
  [AlertMessageHierarchyKeys.PLAN_COMPARISON_TABLE]: [
    AlertMessageConditions.NO_SAVED_PHARMACIES,
    AlertMessageConditions.NO_SAVED_DRUGS,
    AlertMessageConditions.ONE_SAVED_PHARMACY,
  ],
  [AlertMessageHierarchyKeys.PLAN_COMPARISON_NETWORK_STATUS]: [
    AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES,
    AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES,
  ],
  [AlertMessageHierarchyKeys.IN_NETWORK_PHARMACY_FINDER]: [
    AlertMessageConditions.NO_SAVED_DRUGS,
  ],
};

export const defaultAlertMessageState: Record<
  AlertMessageConditions,
  AlertMessageType
> = {
  [AlertMessageConditions.NO_SAVED_PHARMACIES]: {
    key: AlertMessageConditions.NO_SAVED_PHARMACIES,
    active: false,
    title: t => {
      return t("lcp.messages.add_pharmacies.headline");
    },
    body: t => {
      return t("lcp.messages.add_pharmacies.description");
    },
    action: (t, alertMessageContext) => {
      return (
        <DefaultNoSavedPharmaciesLink
          alertMessageContext={alertMessageContext}
          className="ds-c-button ds-c-button--solid ds-c-button--alternate"
          treatAsButton
        >
          {t("lcp.messages.add_pharmacies.button")}
        </DefaultNoSavedPharmaciesLink>
      );
    },
  },
  [AlertMessageConditions.NO_SAVED_DRUGS]: {
    key: AlertMessageConditions.NO_SAVED_DRUGS,
    active: false,
    title: t => {
      return t("lcp.messages.add_drugs.headline");
    },
    body: t => {
      return t("lcp.messages.add_drugs.description");
    },
    action: (t, alertMessageContext) => (
      <DefaultNoSavedDrugsLink
        alertMessageContext={alertMessageContext}
        className="ds-c-button ds-c-button--solid ds-c-button--alternate"
        treatAsButton
      >
        {t("lcp.messages.add_drugs.button")}
      </DefaultNoSavedDrugsLink>
    ),
  },
  [AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT]: {
    active: false,
    key: AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT,
    title: t => {
      return t("lcp.messages.add_more_pharmacies_stale.headline");
    },
    body: t => {
      return t("lcp.messages.add_more_pharmacies_stale.description");
    },
    action: (t, alertMessageContext) => (
      <DefaultOneSavedPharmacyStaleAccountLink
        alertMessageContext={alertMessageContext}
        className="ds-c-button ds-c-button--solid ds-c-button--alternate"
        treatAsButton
      >
        {t("lcp.messages.add_more_pharmacies_stale.button")}
      </DefaultOneSavedPharmacyStaleAccountLink>
    ),
  },
  [AlertMessageConditions.ONE_SAVED_PHARMACY]: {
    active: false,
    key: AlertMessageConditions.ONE_SAVED_PHARMACY,
    title: t => {
      return t("lcp.messages.add_more_pharmacies_standard.headline");
    },
    body: t => {
      return t("lcp.messages.add_more_pharmacies_standard.description");
    },
    action: (t, alertMessageContext) => (
      <DefaultOneSavedPharmacyLink
        className="ds-c-button ds-c-button--solid ds-c-button--alternate"
        alertMessageContext={alertMessageContext}
        treatAsButton
      >
        {t("lcp.messages.add_more_pharmacies_standard.button")}
      </DefaultOneSavedPharmacyLink>
    ),
  },
  [AlertMessageConditions.STALE_ACCOUNT_PHARMACIES]: {
    action: undefined,
    active: false,
    body: t => t("summary_page.pharmacies.alert.no_pharmacies"),
    icon: <PiggyBankCircleIcon />,

    key: AlertMessageConditions.STALE_ACCOUNT_PHARMACIES,
    title: undefined,
  },
  [AlertMessageConditions.STALE_ACCOUNT_DRUGS]: {
    action: undefined,
    active: false,
    body: t => t("summary_page.drugs.alert.no_drugs"),
    icon: <PiggyBankCircleIcon />,
    key: AlertMessageConditions.STALE_ACCOUNT_DRUGS,
    title: undefined,
  },
  [AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES]: {
    key: AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES,
    active: false,
    title: t => {
      return t("lcp.messages.in_network_pharmacies.headline");
    },
    body: t => {
      return t("lcp.messages.in_network_pharmacies.description");
    },
    action: (t, alertMessageContext) => {
      return (
        <AlertMessageLink
          to={{
            pathname: Routes.inNetworkPharmacy.replace(
              ":id",
              alertMessageContext?.planId || ""
            ),
            state: alertMessageContext?.routerState,
            search: alertMessageContext?.search,
          }}
          className="ds-c-button ds-c-button--solid ds-c-button--alternate"
          condition={AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES}
          treatAsButton
        >
          {t("lcp.messages.in_network_pharmacies.button")}
        </AlertMessageLink>
      );
    },
  },
  [AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES]: {
    active: false,
    key: AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES,
    title: t => {
      return t("lcp.messages.preferred_pharmacies.headline");
    },
    body: t => {
      return t("lcp.messages.preferred_pharmacies.description");
    },
    action: (t, alertMessageContext) => {
      return (
        <AlertMessageLink
          to={{
            pathname: Routes.inNetworkPharmacy.replace(
              ":id",
              alertMessageContext?.planId || ""
            ),
            state: alertMessageContext?.routerState,
            search: alertMessageContext?.search,
          }}
          className="ds-c-button ds-c-button--solid ds-c-button--alternate"
          condition={AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES}
          treatAsButton
        >
          {t("lcp.messages.preferred_pharmacies.button")}
        </AlertMessageLink>
      );
    },
  },
};
