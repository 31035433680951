import React from "react";

interface EmptyTableFieldProps {
  withDash?: boolean;
}

export const emptyTableFieldTestId = "empty-table-field";

const EmptyTableField = ({ withDash }: EmptyTableFieldProps): JSX.Element => (
  <span data-testid={emptyTableFieldTestId}>
    {withDash ? <>&mdash;</> : <>&nbsp;</>}
  </span>
);

export default EmptyTableField;
