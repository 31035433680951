export enum EnrollButtonReplacmentHierarchy {
  CANT_ENROLL_CURRENT_YEAR = "CANT_ENROLL_CURRENT_YEAR",
  CANT_ENROLL_CURRENT_YEAR_NEXT_YEAR = "CANT_ENROLL_CURRENT_YEAR_NEXT_YEAR",
  ENROLLMENT_DISABLED = "ENROLLMENT_DISABLED",
  DSNP_SUPPRESSION = "DSNP_SUPPRESSION",
  NO_OPT_IN_STATUS = "NO_OPT_IN_STATUS",
  PLAN_FROZEN = "PLAN_FROZEN",
  NO_ENROLLMENT_AT_THIS_TIME = "NO_ENROLLMENT_AT_THIS_TIME",
}

export type EnrollButtonContentReplacementMap = [JSX.Element, boolean][];
