import cookies from "browser-cookies";
import { v1 as uuidv1 } from "uuid";
import { LDContext, LDOptionsBase } from "launchdarkly-js-sdk-common";
import { cachedStore } from "../helpers/storeHelpers";
import { ProviderConfig } from "launchdarkly-react-client-sdk";
import { AppState } from "../@types";
import { config } from "../config";
import { LDClient } from "launchdarkly-js-client-sdk";
import { getBeneIsCsr } from "./csrHelpers";

export const ldOptions = {
  baseUrl: config.LD_BASE_URL,
  eventsUrl: config.LD_EVENTS_URL,
  streamUrl: config.LD_STREAM_URL,
  fetchGoals: false,
  sendLDHeaders: false,
  streaming: true,
  streamReconnectDelay: 5000,
};

/**
 * Get the most up-to-date values for a Launch Darkly user object
 * @param [state] - Application state from the store
 * @returns - the LaunchDarkly context object
 */
export const getLdUser = (
  state: AppState | Partial<AppState> | null = {}
): LDContext => {
  let key = "";
  let custom = {};
  if (state?.beneficiary) {
    key = state.beneficiary.meta_data.beneficiary_key;
    custom = {
      beneficiaryKey: key,
      isCSR: getBeneIsCsr(state.beneficiary),
      isLoggedIn: state.beneficiary.meta_data.is_logged_in,
      isSynthetic: state.beneficiary.meta_data.is_synthetic,
    };
    if (state.beneficiary.meta_data.last_login_date) {
      custom["lastLoginDate"] = state.beneficiary.meta_data.last_login_date;
    }
  } else {
    key = cookies.get("mct-anonymous-session-id") || uuidv1();
  }

  const ldUser: LDContext = {
    kind: "user",
    key,
    anonymous: true,
    ...custom,
  };

  return ldUser;
};

export const ldProviderConfig: ProviderConfig & { options: LDOptionsBase } = {
  clientSideID: config.LD_CLIENT_ID,
  user: getLdUser(cachedStore),
  options: ldOptions,
};

export const trackLdEvent = ({
  eventName,
  data,
  metricValue,
  ldClient,
  variation,
}: {
  eventName: string;
  data?: Record<string, unknown>;
  metricValue?: number;
  ldClient?: LDClient;
  variation?: string;
}): void => {
  // For testing purposes, use optional chaining for `utag.gdpr.getConsentState`
  const userPrivacyConsentValues = window.utag?.gdpr?.getConsentState();
  let analyticsConsent = false;

  if (Array.isArray(userPrivacyConsentValues)) {
    const consent = userPrivacyConsentValues?.find(
      value => value.name === "analytics"
    );
    analyticsConsent = consent?.ct === "1";
  } else {
    analyticsConsent = userPrivacyConsentValues === 1;
  }

  if (analyticsConsent) {
    ldClient?.track(
      eventName,
      { ...data, browserViewport: window.innerWidth, variation },
      metricValue
    );
  }
};
