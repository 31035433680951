import React, { useContext } from "react";
import { Tooltip } from "@cmsgov/ds-medicare-gov";
import { TooltipProps } from "@cmsgov/design-system/dist/types/Tooltip/Tooltip";
import classNames from "classnames";
import { IntlContext } from "../helpers/intlHooks";
import messages from "../translations/en-US.json";

interface TooltipWithCloseProps extends TooltipProps {
  /**
   * `title` is the tooltip's content.
   * `headerText` is an optional heading for the tooltip's content. The required
   * `children` prop is used for the tooltip's trigger content (by default) and
   *  also the tooltip heading, unless `headerText` is also set.
   * If `headerText` is passed an empty string, no header will be displayed
   */
  headerText?: React.ReactNode;
}

/**
 * Customized tooltip to match previous MPF implementation but leverage core DS
 * component functionality.
 *   existing implementation
 * - Uses as a prefix for the tooltip trigger `aria-label`, in this order:
 *     - the optional `ariaLabel` prop, which is a string
 *     - the optional `headerText` prop, if it's a string
 *     - `children`, if it's a string
 * - NB: The `title` prop is for dialog **content**
 *
 * For additional prop and usage documentation:
 * @see https://github.cms.gov/pages/MedicareGov/mgov-design-system/components/tooltip/
 * @see https://design.cms.gov/components/tooltip/
 */
export const TooltipWithClose = ({
  title,
  ariaLabel,
  headerText,
  children,
  className,
  activeClassName,
  maxWidth = "400px",
  ...remainingProps
}: TooltipWithCloseProps) => {
  const fm = useContext(IntlContext).formatMessage;

  //Get a string prefix to describe the tooltip trigger to screen readers
  let ariaLabelPrefix = "";
  if (ariaLabel) {
    // ariaLabel` is optional and a `string`
    ariaLabelPrefix = `${ariaLabel}: `;
  } else if (headerText && typeof headerText === "string") {
    // `headerText` is optional and a `ReactNode`
    ariaLabelPrefix = `${headerText}: `;
  } else if (typeof children === "string") {
    // `children` is required and a `ReactNode`
    ariaLabelPrefix = `${children}: `;
  }

  const ariaLabelContent = fm
    ? `${ariaLabelPrefix}${fm({
        id: "tooltip_trigger_aria_label",
      })}`
    : `${ariaLabelPrefix}${messages.tooltip_trigger_aria_label}`;

  const toggleButtonClasses = "ds-c-button ds-c-button--ghost";

  return (
    <div
      className={classNames("Tooltip Tooltip--with-close", {
        "Tooltip--inline": className?.includes("ds-c-tooltip__trigger-link"),
      })}
    >
      <Tooltip
        title={
          <div className="Tooltip__body" data-testid="Tooltip__body">
            {title}
          </div>
        }
        contentHeading={
          headerText === "" ? (
            <span></span>
          ) : (
            <h2 className="ds-text-heading--lg ds-u-margin-bottom--0">
              {headerText || children}
            </h2>
          )
        }
        closeButtonLabel={fm ? fm({ id: "close" }) : "close"}
        showCloseButton={true}
        dialog={true}
        className={classNames(
          className,
          toggleButtonClasses,
          "Tooltip__trigger"
        )}
        activeClassName={classNames(
          activeClassName,
          toggleButtonClasses,
          "Tooltip__trigger--open"
        )}
        ariaLabel={ariaLabelContent}
        placement="top-start"
        maxWidth={maxWidth}
        {...remainingProps}
      >
        {children}
      </Tooltip>
    </div>
  );
};
