import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from "react";

const defaultProps = {
  title: "current location",
  viewBox: "0 0 20 20",
};

const CurrentLocationIconComponent: ForwardRefRenderFunction<
  SVGSVGElement,
  IconCommonProps
> = (
  props: IconCommonProps,
  iconRef: React.ForwardedRef<SVGSVGElement>
): React.ReactElement => {
  const iconCssClasses = `mct-c-icon--location-arrow ${props.className || ""}`;
  const innerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(
    iconRef,
    () => innerRef.current?.firstElementChild as SVGSVGElement
  );

  return (
    <div ref={innerRef}>
      <SvgIcon {...defaultProps} {...props} className={iconCssClasses}>
        <path d="M15.5982192,0.159216355 L0.977965913,6.90531387 C-0.657502768,7.6601537 -0.119190291,10.1249935 1.68513385,10.1249935 L7.87498906,10.1249935 L7.87498906,16.3122473 C7.87498906,18.1307746 10.3415866,18.6510519 11.0946686,17.0194152 L17.8375668,2.39852881 C18.5021956,0.958493682 17.0025356,-0.488959413 15.5982192,0.159216355 Z" />
      </SvgIcon>
    </div>
  );
};

export const CurrentLocationIcon = forwardRef(CurrentLocationIconComponent);
