import React, { useEffect, useRef } from "react";
import { SimpleFooter } from "@cmsgov/ds-medicare-gov";
import { useTypedTranslate } from "../helpers/intlHooks";
import { getRouteName } from "../helpers/routeHelpers";
import { useLocation } from "react-router-dom";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import { UserLanguage } from "../@types";

export const Footer = () => {
  const {
    state: { language },
    dispatch,
  } = useAppContext();
  const t = useTypedTranslate();
  const location = useLocation();
  const routeNameRef = useRef(getRouteName(location.pathname));

  useEffect(() => {
    const routeName = getRouteName(location.pathname);

    routeNameRef.current = routeName;
  }, [location.pathname]);

  useEffect(() => {
    const footerEl = document.querySelector(".m-c-footer");
    if (!footerEl) {
      return;
    }
    const hrefEls = document.querySelectorAll(
      "[href^='https://www.medicare.gov'], [href^='https://es.medicare.gov']"
    ) as NodeListOf<HTMLAnchorElement>;
    const langSubdomains = ["www.", "es."];
    const langIsEn = language === UserLanguage.ENGLISH;
    const expectedLangSubdomain = langIsEn
      ? langSubdomains[0]
      : langSubdomains[1];
    const incorrectLangSubdomain = langIsEn
      ? langSubdomains[1]
      : langSubdomains[0];
    hrefEls.forEach(
      el =>
        (el.href = el.href.replace(
          incorrectLangSubdomain,
          expectedLangSubdomain
        ))
    );
  }, [language]);

  return (
    <SimpleFooter
      language={language}
      aboutMedicareLabel={t("footer.about_medicare")}
      nondiscriminationLabel={t("footer.nondiscrimination_accessibility")}
      privacyPolicyLabel={t("footer.privacy_policy")}
      privacySettingLabel={t("footer.privacy_setting")}
      linkingPolicyLabel={t("footer.linking_policy")}
      usingThisSiteLabel={t("footer.using_this_site")}
      plainWritingLabel={t("footer.plain_writing")}
      websiteInfo={t("footer.website_info")}
      onClickLinkAnalytics={(url: string): void => {
        dispatch({
          type: AnalyticsActionType.SEND_TEALIUM_EVENT,
          settings: {
            event_action: routeNameRef.current,
            event_label: `Footer Link: ${url}`,
          },
        });
      }}
    />
  );
};
