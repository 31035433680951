import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React from "react";

const defaultProps = {
  className: "",
  title: "vial-check",
  viewBox: "0 0 24 23",
};

function InterchangeableBiologicsIcon(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `mct-c-icon--interchangeable-biologics ${
    props.className || ""
  }`;

  return (
    <SvgIcon {...defaultProps} {...props} className={iconCssClasses}>
      <path
        d="M0 1.51C0 .675.672 0 1.5 0h9c.83 0 1.5.676 1.5 1.51 0 .833-.67 1.509-1.5 1.509v8.056A8.278 8.278 0 0 0 9 15.85c0 1.189.248 2.316.694 3.34A4.485 4.485 0 0 1 6 21.132c-2.485 0-4.5-2.028-4.5-4.528V3.019c-.828 0-1.5-.676-1.5-1.51Zm4.5 6.037h3V3.02h-3v4.528ZM24 15.85c0 3.75-3.023 6.793-6.75 6.793s-6.75-3.043-6.75-6.793 3.023-6.792 6.75-6.792S24 12.099 24 15.849Zm-4.655-2.042L16.5 16.67l-1.345-1.354a.74.74 0 0 0-1.06 0 .752.752 0 0 0 0 1.066l1.875 1.887a.74.74 0 0 0 1.06 0l3.375-3.396a.752.752 0 0 0 0-1.066.74.74 0 0 0-1.06 0Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default InterchangeableBiologicsIcon;
