import { AlertProps } from "@cmsgov/design-system/dist/types/Alert/Alert";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React, { ReactElement } from "react";
import classNames from "classnames";

export const customAlertIconDataId = "customAlertIcon";

export const AlertWithCustomIcon: React.FC<
  AlertProps & {
    Icon: (props: IconCommonProps) => ReactElement;
  }
> = ({ Icon, children, className, ...props }) => {
  const customClassName = classNames(
    "mct-c-alert",
    "mct-c-alert--with-custom-icon",
    className
  );
  return (
    <Alert {...props} hideIcon className={customClassName}>
      <>
        <Icon
          className="mct-c-alert__icon mct-c-alert__icon--custom"
          data-id={customAlertIconDataId}
        />
        <div className="mct-c-alert__body">{children}</div>
      </>
    </Alert>
  );
};
