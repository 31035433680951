import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useQueryParams } from "../helpers/queryParamsHook";
import { FocusableHeader } from ".";
import { ActionType } from "../@types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export interface AppPageProps extends RouteComponentProps {
  headerExtension?: React.ReactNode;
  heading?: string;
  headingClass?: string;
  headingId?: string;
  hideLogin?: boolean;
  subHeading?: React.ReactNode;
  subHeadingClass?: string;
  outerClassName?: string;
  innerClassName?: string;
  bannerClassName?: string;
  title?: string;
  children: React.ReactNode;
}

const AppPage: React.FunctionComponent<AppPageProps> = props => {
  const {
    headerExtension,
    heading,
    headingClass,
    headingId,
    subHeading,
    subHeadingClass,
    outerClassName,
    innerClassName,
    title,
    children,
    bannerClassName,
    match,
  } = props;
  useQueryParams(props);
  const { dispatch } = useAppContext();

  React.useEffect(() => {
    if (title) {
      document.title = title;
    } else if (heading) {
      document.title = heading;
    }
  }, [title, heading]);

  React.useEffect(() => {
    // Trigger only on page load
    dispatch({ type: ActionType.UPDATE_ROUTE_PARAMS, payload: match.params });
  }, []);

  return (
    <>
      <div className={`appPage ${outerClassName ? outerClassName : ""}`}>
        {headerExtension}
        {(heading || subHeading) && (
          <div
            className={`appPage__banner ${
              bannerClassName ? bannerClassName : ""
            }`}
          >
            {heading && (
              <FocusableHeader
                className={headingClass || ""}
                text={heading}
                id={headingId}
              />
            )}
            {subHeading && (
              <div
                className={
                  subHeadingClass || "ds-text-body--md ds-u-margin-top--1"
                }
              >
                {subHeading}
              </div>
            )}
          </div>
        )}
        <div
          className={`appPage__content ${innerClassName ? innerClassName : ""}`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default withRouter(AppPage);
