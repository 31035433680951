import React from "react";
import { Badge as OriginalBadge } from "@cmsgov/ds-medicare-gov";
import classNames from "classnames";
import uniqueId from "lodash.uniqueid";

export const baseBadgeClassName = "mct-c-badge";
enum IconPlacements {
  left = "left",
  right = "right",
}

interface ExtendedBadgeProps {
  iconPlacement?: keyof typeof IconPlacements;
  /**
   * `theme` prop overrides `variation`, similar to `weight` prop for an `Alert`
   * @see https://design.cms.gov/components/alert/
   */
  theme?: "white" | "primary-alt-lightest";
  /**
   * BadgeWithIcon will create a unique `id` for the `svgIcon`, or the `SvgIcon`
   * component from the DS will assign one.
   * If you intend to have more than one badge on a page, ensure that `id`s on
   * any of the generated SVG's children elements will also be unique for each instance.
   * Recommend, e.g., using `lodash.uniqueId`
   */
  svgIcon: React.ReactElement;
}

interface BadgeProps
  extends React.ComponentPropsWithoutRef<typeof OriginalBadge>,
    ExtendedBadgeProps {}

const ChildrenWithIcon = ({
  svgIcon,
  children,
  iconPlacement,
  idSuffix,
}: ExtendedBadgeProps &
  Pick<BadgeProps, "children"> & { idSuffix?: string }) => {
  const iconClassName = `${baseBadgeClassName}__icon`;
  const className = classNames(
    `${iconClassName} ${iconClassName}--${iconPlacement}`
  );
  const iconRight = iconPlacement === IconPlacements.right;
  const newId = svgIcon.props.id
    ? `${svgIcon.props.id}-${idSuffix}`
    : undefined;
  const icon = React.cloneElement(svgIcon, {
    className,
    "aria-hidden": true, // Badge provides content, icon is decoration
    width: "18px",
    height: "18px",
    id: newId,
  });
  return (
    <>
      {!iconRight && icon}
      <span className="mct-c-badge__content">{children}</span>
      {iconRight && icon}
    </>
  );
};

/**
 * `BadeWithIcon` extends the CMSDS / MGov DS `Badge` Component
 * - Takes an optional `SvgIcon` Component as a prop
 *     - `SvgIcon` wraps any valid SVG inner content to ensure an accessible SVG is output
 * - Can position the icon on either side of `Badge` content
 * - Adds an optional `theme` prop, modeled on that available on the `Alert` component,
 *   to render a badge with custom background and content color (relies on CSS)
 * - Badge `id` will also get a unique suffix and pass to the `svgIcon`
 * @see https://github.cms.gov/pages/MedicareGov/mgov-design-system/components/badge
 * @see https://design.cms.gov/components/icon
 */
export const BadgeWithIcon: React.FC<BadgeProps> = ({
  svgIcon,
  iconPlacement = IconPlacements.left,
  children,
  className,
  variation,
  theme,
  id,
  ...props
}: BadgeProps) => {
  const combinedClassNames = classNames(className, "mct-c-badge", {
    [`ds-c-badge--${variation} mct-c-badge--${variation}`]: !!variation,
    [`mct-c-badge--${theme}`]: !!theme,
  });
  const idSuffix = uniqueId();
  const newId = id ? `${id}-${idSuffix}` : undefined;
  return (
    <OriginalBadge
      className={combinedClassNames}
      data-testid={baseBadgeClassName}
      id={newId}
      {...props}
    >
      <ChildrenWithIcon {...{ svgIcon, iconPlacement, idSuffix }}>
        {children}
      </ChildrenWithIcon>
    </OriginalBadge>
  );
};
