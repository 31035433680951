import { useMemo } from "react";
import { useLoggedIn } from "./loginHelpers";
import {
  Action,
  ActionType,
  AppState,
  CoverageSelectorCoverageType,
  CoverageSelectorFormValues,
  CoverageSelectorSelectedValues,
  Pharmacy,
  PharmacyType,
  PlanType,
  PrescriptionDrug,
  SharedCoverageSelectorAnalyticsValues,
} from "../@types";
import routes from "../app/routes";
import { hasMailOrderPharmacy } from "../app/store/appStoreHelpers";
import { useAppContext } from "./context-hooks/useAppContext";
import {
  AnalyticsActionType,
  AnalyticsActions,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
  Ga4EventType,
} from "../app/contexts/Analytics/types";
import { planTypeGa4EventMap } from "../components/CoverageSelector/constants";
import { useIsBEDAPDown } from "./beneficiaryInfoHelpers";

export interface LiCoverageSelectorRoutingParams {
  pharmacies: Pharmacy[];
  prescriptions: PrescriptionDrug[];
  pharmacyType: PharmacyType;
  isBEDAPDown: boolean;
}

export interface CoverageSelectorRoutingParams {
  isBEDAPDown: boolean;
  isLoggedIn: boolean;
  pharmacies: Pharmacy[];
  prescriptions: PrescriptionDrug[];
  pharmacyType: PharmacyType;
  zipcode?: string;
}

/**
 * Determines some of the possible next routes from the coverage selector,
 * based on whether they're LI or anonymous, the status of BEDAP, and the current
 * status of the bene's pharmacies and prescription drugs
 */
export const getNextRouteFromCoverageSelector = ({
  isBEDAPDown,
  isLoggedIn,
  pharmacies,
  prescriptions,
  pharmacyType,
}: CoverageSelectorRoutingParams) => {
  const hasMailOrderPharmacies = hasMailOrderPharmacy({
    pharmacyType,
  } as AppState);
  const pharmacyCount = pharmacies.length + (hasMailOrderPharmacies ? 1 : 0);
  const drugsCount = prescriptions.length;
  const isMissingPharmsOrDrugs = pharmacyCount === 0 || drugsCount === 0;
  const baseRoutes = isLoggedIn ? routes.summary : routes;
  if (!isLoggedIn || isBEDAPDown) {
    return baseRoutes.lisQuestions;
  } else {
    return isMissingPharmsOrDrugs
      ? baseRoutes.drugSearchPreferences
      : baseRoutes.searchResults;
  }
};

/**
 * Provides a map of destination routes for a bene based on chosen coverage type
 */
export const getCoverageSelectorDestinationMap = ({
  isBEDAPDown,
  isLoggedIn,
  pharmacies,
  prescriptions,
  pharmacyType,
  zipcode,
}: CoverageSelectorRoutingParams): Record<
  CoverageSelectorCoverageType,
  string | undefined
> => {
  const nextRouteForMaOrPdp = getNextRouteFromCoverageSelector({
    isBEDAPDown,
    isLoggedIn,
    pharmacies,
    prescriptions,
    pharmacyType,
  });

  return {
    [CoverageSelectorCoverageType.MAPD]: nextRouteForMaOrPdp,
    [CoverageSelectorCoverageType.PDP]: nextRouteForMaOrPdp,
    [CoverageSelectorCoverageType.MedigapPolicy]: zipcode
      ? routes.medigap.plans
      : undefined,
    [CoverageSelectorCoverageType.learnMore]: routes.coverageWizard.options,
  };
};

/**
 * Hook that provides a map of destination routes for a bene based on chosen
 * coverage type
 */
export const useCoverageSelectorDestinationMap = () => {
  const {
    state: { pharmacies, pharmacyType, prescriptions, zipcode },
  } = useAppContext();

  const isBEDAPDown = useIsBEDAPDown();
  const isLoggedIn = useLoggedIn();
  const destinationMap = useMemo(
    () =>
      getCoverageSelectorDestinationMap({
        isBEDAPDown,
        isLoggedIn,
        pharmacies,
        pharmacyType,
        prescriptions,
        zipcode,
      }),
    [isBEDAPDown, isLoggedIn, pharmacies, pharmacyType, prescriptions, zipcode]
  );
  return destinationMap;
};

/**
 * Populate initial coverage selector form values
 */
export const getInitialFormValues = ({
  beneficiary,
  county,
  zipcode,
}: {
  beneficiary: AppState["beneficiary"];
  county: AppState["county"];
  zipcode: AppState["zipcode"];
}) => ({
  zipcode: zipcode || beneficiary?.mailing_address.zipcode,
  fips: county?.fips || beneficiary?.mailing_address.fips_state_county || "",
});

/**
 * an easy way to grab initial values from state
 * either from a top level attribute or falling back to beneficiary for logged in users.
 */
export function useInitialFormValues() {
  const {
    state: { beneficiary, county, zipcode },
  } = useAppContext();
  const initialFormValues = getInitialFormValues({
    beneficiary,
    county,
    zipcode,
  });

  return {
    initialFormValues,
  };
}

const planTypeMap: Record<CoverageSelectorCoverageType, PlanType | undefined> =
  {
    [CoverageSelectorCoverageType.MAPD]: PlanType.MAPD,
    [CoverageSelectorCoverageType.PDP]: PlanType.PDP,
    [CoverageSelectorCoverageType.MedigapPolicy]: undefined,
    [CoverageSelectorCoverageType.learnMore]: undefined,
  };

export const updateStates = (
  coverageSelectorValues: CoverageSelectorSelectedValues,
  dispatch: React.Dispatch<Action>
): void => {
  dispatch({
    type: ActionType.UPDATE_YEAR,
    payload: coverageSelectorValues.coverageYear.toString(),
  });
  dispatch({
    type: ActionType.UPDATE_COUNTY,
    payload: coverageSelectorValues.county,
  });
  dispatch({
    type: ActionType.UPDATE_FIPS,
    payload: coverageSelectorValues.county?.fips,
  });
  dispatch({
    type: ActionType.UPDATE_ZIPCODE,
    payload: coverageSelectorValues.zipcode,
  });

  if (coverageSelectorValues.coverageType) {
    const planType = planTypeMap[coverageSelectorValues.coverageType];
    if (planType) {
      dispatch({
        type: ActionType.UPDATE_PLAN_TYPE,
        payload: planType,
      });
    }
  }
};

interface SendCoverageSelectorAnalyticsArgs
  extends SharedCoverageSelectorAnalyticsValues {
  analyticsButton: {
    text: string;
    buttonStyle: AnalyticsButtonStyle;
    buttonType: AnalyticsButtonType;
  };
  currentFormValues: CoverageSelectorFormValues;
  dispatch: React.Dispatch<Action | AnalyticsActions>;
  linkUrl: string;
}

export const sendCoverageSelectorAnalytics = ({
  analyticsButton,
  analyticsEventAction,
  currentFormValues,
  dispatch,
  linkUrl,
}: SendCoverageSelectorAnalyticsArgs) => {
  if (currentFormValues.coverageType) {
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: analyticsButton,
        linkUrl,
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.PLAN_FINDER_STARTED,
        event_type: Ga4EventType.UI_INTERACTION,
        link_text: analyticsButton.text,
        link_url: linkUrl,
        mct_plan_type: planTypeGa4EventMap[currentFormValues.coverageType],
        mct_plan_year: currentFormValues.coverageYear,
        text: analyticsButton.text,
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: analyticsEventAction || "Coverage Selector - Plan Search",
        event_label: planTypeGa4EventMap[currentFormValues.coverageType],
      },
    });
  }
};
