const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
    fipsCode: "01",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
    fipsCode: "02",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
    fipsCode: "60",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
    fipsCode: "04",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
    fipsCode: "05",
  },
  {
    name: "California",
    abbreviation: "CA",
    fipsCode: "06",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
    fipsCode: "08",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
    fipsCode: "09",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
    fipsCode: "10",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
    fipsCode: "11",
  },
  {
    name: "Florida",
    abbreviation: "FL",
    fipsCode: "12",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
    fipsCode: "13",
  },
  {
    name: "Guam",
    abbreviation: "GU",
    fipsCode: "66",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
    fipsCode: "15",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
    fipsCode: "16",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
    fipsCode: "17",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
    fipsCode: "18",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
    fipsCode: "19",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
    fipsCode: "20",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
    fipsCode: "21",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
    fipsCode: "22",
  },
  {
    name: "Maine",
    abbreviation: "ME",
    fipsCode: "23",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
    fipsCode: "24",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
    fipsCode: "25",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
    fipsCode: "26",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
    fipsCode: "27",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
    fipsCode: "28",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
    fipsCode: "29",
  },
  {
    name: "Montana",
    abbreviation: "MT",
    fipsCode: "30",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
    fipsCode: "31",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
    fipsCode: "32",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
    fipsCode: "33",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
    fipsCode: "34",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
    fipsCode: "35",
  },
  {
    name: "New York",
    abbreviation: "NY",
    fipsCode: "36",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
    fipsCode: "37",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
    fipsCode: "38",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
    fipsCode: "69",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
    fipsCode: "39",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
    fipsCode: "40",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
    fipsCode: "41",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
    fipsCode: "42",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
    fipsCode: "72",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
    fipsCode: "44",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
    fipsCode: "45",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
    fipsCode: "46",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
    fipsCode: "47",
  },
  {
    name: "Texas",
    abbreviation: "TX",
    fipsCode: "48",
  },
  {
    name: "Utah",
    abbreviation: "UT",
    fipsCode: "49",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
    fipsCode: "50",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
    fipsCode: "78",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
    fipsCode: "51",
  },
  {
    name: "Washington",
    abbreviation: "WA",
    fipsCode: "53",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
    fipsCode: "54",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
    fipsCode: "55",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
    fipsCode: "56",
  },
];

/** pass a state abbreviation or a fips code to return the state name
 * @example getStateName("VA") // "Virginia"
 * @example getStateName("55") // "Wisconsin"
 */
export const getStateName = (match?: string) => {
  return (
    states.find(
      state => match === state.abbreviation || match === state.fipsCode
    )?.name || ""
  );
};

export default states;
