import { County } from "..";

export enum CoverageSelectorCoverageType {
  MAPD = "MEDICARE_ADVANTAGE_PLAN",
  PDP = "PRESCRIPTION_DRUG_PLAN",
  MedigapPolicy = "MEDIGAP_POLICY",
  learnMore = "LEARN_MORE",
}

export enum CoverageSelectorErrorType {
  NONE,
  INVALID,
  MISSING,
  API,
}

export interface CoverageSelectorInitialValues {
  coverageYear?: number;
  zipcode?: string;
  fips?: string;
  coverageType?: CoverageSelectorCoverageType;
}

export interface CoverageSelectorFormValues {
  coverageYear?: number;
  zipcode?: string;
  county?: County;
  coverageType?: CoverageSelectorCoverageType;
}

export type CoverageSelectorSelectedValues =
  Required<CoverageSelectorFormValues>;

export const isCoverageSelectorSelectedValues = (
  values: CoverageSelectorFormValues
): values is CoverageSelectorSelectedValues => {
  return Object.values(values).every(v => typeof v !== "undefined");
};

export interface CoverageSelectorErrorState {
  error: CoverageSelectorErrorType | null;
  message: string | undefined;
}

export interface SharedCoverageSelectorProps {
  showDescription?: boolean;
  newToMedicare?: boolean;
}

export interface SharedCoverageSelectorAnalyticsValues {
  /** string to send to analytics on "Find Plans" */
  analyticsEventAction?: string;
}
