import React from "react";

/**
 * Lazy loaded React Query dev tools to add to production for debugging.
 * @see https://tanstack.com/query/v5/docs/framework/react/devtools#devtools-in-production
 */
export const ReactQueryProductionDevtools = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(
    d => ({
      default: d.ReactQueryDevtools,
    })
  )
);
