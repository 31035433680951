import { HelpDrawerAnalytics } from "../components/HelpDrawerWrapper";
import { sendHelpDrawerOpenAnalyticsEvent } from "../app/contexts/Analytics/events";

export const createSimpleHelpDrawerAnalytics = ({
  heading,
  toggleText,
}: {
  heading: string;
  toggleText: string;
}): HelpDrawerAnalytics => {
  return {
    openDrawer: () => {
      sendHelpDrawerOpenAnalyticsEvent({ heading, text: toggleText });
    },
    closeDrawer: () => {},
  } as HelpDrawerAnalytics;
};
