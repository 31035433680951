import {
  Beneficiary,
  ExtendedCoverageInfo,
  MedicarePartInfo,
  MedigapCoverage,
  NewToMedicareAlertInfo,
  OecStatus,
  OecStatuses,
  PlanType,
} from "../../@types";
import { isValidApiDate } from "../dateHelpers";
import { getIEPStatusFromAPI } from "../iepStatusHelpers";

export const hasPlanType = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[],
  planType: PlanType
): boolean => {
  return !!coverageInfo.find(coverage => coverage.plan_type === planType);
};

export const containsMAPDPlan = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[]
): boolean => {
  return hasPlanType(coverageInfo, PlanType.MAPD);
};

export const containsPDPPlan = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[]
): boolean => {
  return hasPlanType(coverageInfo, PlanType.PDP);
};

export const hasMedicarePart = (
  currentDate: Date,
  partInfo: MedicarePartInfo
): boolean => {
  if (isValidApiDate(partInfo.start_date)) {
    const partStartDate = new Date(partInfo.start_date);

    if (partInfo.stop_date === "") {
      return true;
    } else if (isValidApiDate(partInfo.stop_date)) {
      const partEndDate = new Date(partInfo.stop_date);
      return partEndDate > partStartDate && !(partEndDate < currentDate);
    }
  }
  return false;
};

export const createNewToMedicareAlertInfo = ({
  bene,
  oecStatuses = { currentYear: undefined, nextYear: undefined },
  medigapCoverages = [],
}: {
  bene: Beneficiary;
  oecStatuses?: OecStatuses;
  medigapCoverages: MedigapCoverage[];
}): NewToMedicareAlertInfo => {
  const currentDate = new Date();
  const birthdate = isValidApiDate(bene.birthdate)
    ? new Date(bene.birthdate)
    : undefined;

  const iepStatus = getIEPStatusFromAPI(
    bene.medicare_info.iep_start_date,
    bene.medicare_info.iep_end_date
  );

  const hasMedigap = medigapCoverages.length > 0;

  const pendingPlans: OecStatus[] = [];
  if (oecStatuses.currentYear) {
    pendingPlans.push(oecStatuses.currentYear);
  }
  if (oecStatuses.nextYear) {
    pendingPlans.push(oecStatuses.nextYear);
  }

  const hasPDPPlan =
    containsPDPPlan(bene.coverage_current) ||
    containsPDPPlan(bene.coverage_future) ||
    containsPDPPlan(pendingPlans);
  const hasMAPDPlan =
    containsMAPDPlan(bene.coverage_current) ||
    containsMAPDPlan(bene.coverage_future) ||
    containsMAPDPlan(pendingPlans);
  const hasPartAandB =
    hasMedicarePart(currentDate, bene.medicare_info.part_a) &&
    hasMedicarePart(currentDate, bene.medicare_info.part_b);

  return {
    birthdate,
    iepStatus,
    newToMedicareResult: undefined,
    hasMedigap,
    hasPDPPlan,
    hasMAPDPlan,
    hasPartAandB,
  };
};
