import React, { FunctionComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import AppPage from "./AppPage";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

interface ErrorPageProps extends RouteComponentProps {
  pageHeading?: string;
  messageTitle?: string;
  messageText?: string;
  buttonText?: string;
  redirectPath?: string;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  pageHeading,
  messageTitle,
  messageText,
  buttonText,
  redirectPath,
  history,
}) => {
  const { dispatch } = useAppContext();
  const t = useTranslate();
  return (
    <AppPage heading={pageHeading || t("error_page.heading")}>
      <strong className="ds-lead">
        {messageTitle || t("error_page.title")}
      </strong>
      <p>{messageText || t("error_page.message")}</p>
      <Button
        variation="solid"
        onClick={(): void => {
          dispatch({
            type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
            settings: {
              button: {
                buttonStyle: AnalyticsButtonStyle.PRIMARY,
                buttonType: AnalyticsButtonType.BUTTON,
                text: buttonText || t("error_page.button"),
              },
              linkUrl: redirectPath || "/",
            },
          });
          history.replace(redirectPath || "/");
        }}
      >
        {buttonText || t("error_page.button")}
      </Button>
    </AppPage>
  );
};

export default withRouter(ErrorPage);
