import React from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { FormattedMessage } from "react-intl";
import URI from "urijs";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { useCurrentPlanYear } from "../helpers/yearFlagHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

const YearSwitchBanner: React.FunctionComponent<RouteComponentProps> = ({
  location,
}) => {
  const mctCurrentPlanYear = useCurrentPlanYear();
  const { state, dispatch } = useAppContext();
  const isCurrentYear = state.year === mctCurrentPlanYear.toString();
  const otherYear = isCurrentYear ? mctCurrentPlanYear + 1 : mctCurrentPlanYear;

  const switchYearRoute = new URI(location.search);
  switchYearRoute.removeSearch("year");
  switchYearRoute.addSearch({ year: otherYear });

  return (
    <Alert
      role="alert"
      className="previewBanner mct-hide-print"
      data-testid="e2e-year-switch-banner"
    >
      <span>
        <FormattedMessage
          id="public_preview.viewing_year_plans"
          values={{ year: state.year }}
        />{" "}
        <Link
          to={switchYearRoute.valueOf()}
          onClick={(): void => {
            dispatch({
              type: AnalyticsActionType.SEND_TEALIUM_EVENT,
              settings: {
                event_action: `Find Plans - ${state.year}`,
                event_label: `Show me ${otherYear} plans`,
              },
            });
          }}
        >
          <FormattedMessage
            id="public_preview.show_me_year_plans"
            values={{ year: otherYear }}
          />
        </Link>
      </span>
    </Alert>
  );
};

export default withRouter(YearSwitchBanner);
