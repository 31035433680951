import { RolloverPlanStatus } from "../../@types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";

export const useShouldShowSARNotice = () => {
  const { state } = useAppContext();
  const { tmpFeEnableSarNotice } = useFlags();

  return (
    tmpFeEnableSarNotice &&
    state.rolloverPlanStatus === RolloverPlanStatus.RENEWAL_SAR
  );
};
