import React, { FunctionComponent, ReactNode } from "react";
import classNames from "classnames";

const Footnotes: FunctionComponent<{
  className?: string;
  id?: string;
  children?: ReactNode;
}> = ({ className = "", id = null, children }) => {
  const conditionalProps: { id?: string } = {};
  if (id) {
    conditionalProps.id = id;
  }
  return (
    <dl className={classNames("Footnotes", className)} {...conditionalProps}>
      {children}
    </dl>
  );
};

export default Footnotes;
