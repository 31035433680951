import { useEffect } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { sendAnalyticsEvent } from "../events";
import {
  AlertImpressionEvent,
  CardImpressionEvent,
  M3pImpressionEvent,
  PartialWithExceptions,
} from "../eventTypes";

// * Optionals
// These types are necessary to ensure that we do not get errors trying to read properties that aren't there.

type CardImpressionOptional = Partial<
  Pick<CardImpressionEvent, "heading" | "parent_component_heading">
>;

type AlertImpressionOptional = Partial<
  Pick<AlertImpressionEvent, "text" | "type">
>;

type M3pImpressionOptional = Partial<
  Pick<M3pImpressionEvent, "m3p_component_type" | "m3p_engagement">
>;

// * Impression Parameters
// These are defining the required/optional parameters to pass.

type AlertImpressionParams = PartialWithExceptions<
  AlertImpressionEvent,
  "event_name" | "text" | "type"
> &
  CardImpressionOptional &
  M3pImpressionOptional;

type CardImpressionParams = PartialWithExceptions<
  CardImpressionEvent,
  "event_name" | "heading" | "parent_component_heading"
> &
  AlertImpressionOptional &
  M3pImpressionOptional;

type M3pImpressionParams = PartialWithExceptions<
  M3pImpressionEvent,
  "event_name" | "m3p_component_type"
> &
  CardImpressionOptional &
  AlertImpressionOptional;

type ImpressionParams =
  | AlertImpressionParams
  | CardImpressionParams
  | M3pImpressionParams;

/**
 * This hook takes analytics info and returns a ref to pass to an HTML element.
 * The first time the ref is observed, it will send the analytics info.
 * ? This component can take multiple types of Impression events.
 * ? Over time, we may decide that it's not worth the complication, and we should just break this up into multiple hooks.
 */
export const useAnalyticsImpression = ({
  event_name,
  heading,
  text,
  type,
  parent_component_heading,
  m3p_component_type,
  m3p_engagement,
}: ImpressionParams) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    /** ensures that once `isIntersecting` is set to true, it remains true */
    freezeOnceVisible: true,
    threshold: 1,
  });

  useEffect(() => {
    if (isIntersecting) {
      switch (event_name) {
        case "alert_impression":
          return sendAnalyticsEvent({
            event_name,
            heading: heading || "",
            link_type: "link_other",
            type,
            text,
          });
        case "card_impression":
          return sendAnalyticsEvent({
            event_name,
            heading,
            link_type: "link_other",
            parent_component_heading,
            parent_component_type: "card",
          });
        case "m3p_impression":
          return sendAnalyticsEvent({
            event_name,
            link_type: "link_other",
            m3p_engagement: (() => {
              if (m3p_engagement) return m3p_engagement;
              switch (m3p_component_type) {
                case "content":
                  return "increasing";
                case "coachmark":
                  return "targeted";
                default:
                  return "targeted";
              }
            })(),
            m3p_component_type,
          });
        default:
          return;
      }
    }
    /**
     * ! IMPORTANT: In order for this to work, we must make sure to deconstruct the event object,
     * ! and pass the individual attributes as part of the dependency array.
     * ! If we were to pass the whole params object in to the dependency array,
     * ! it would require the devs to memoize said object manually each time we use this hook.
     * */
  }, [
    event_name,
    heading,
    isIntersecting,
    m3p_component_type,
    m3p_engagement,
    parent_component_heading,
    text,
    type,
  ]);

  return ref;
};
