import React, { useMemo, useCallback, useLayoutEffect } from "react";
import {
  Column,
  ColumnInstance,
  FilterTypes,
  FilterValue,
  IdType,
  Row,
} from "react-table";
import { TableCaption } from "@cmsgov/ds-medicare-gov";
import {
  RowAttributes,
  AugmentedRow,
  CaptionProp,
  AugmentedColumn,
  col,
  AugmentedHeaderProps,
  CaptionRenderObject,
} from "../@types/react-table";
import classNames from "classnames";
import { MessageDescriptor } from "react-intl";

export const filteredRowClassNameModification = {
  ["className"]: "mct-c-table__row--filtered",
};

/**
 * Memoizes table column config or tbody data for react-table
 * @deprecated use `useMemo` instead.
 * @param data - An array of table data/config
 * @returns - The memoized array
 */
export const useTableConfig = function useTableConfig<T>(
  data: T,
  dependencies: Array<unknown> = []
): T {
  return useMemo(() => data, [...dependencies]);
};

/**
 * Takes a table cell value (from the table body) and parses it to
 * return the string value of the `data-row-title` on a nested prop from `children`,
 * the original value if it's not JSX (has no props), or otherwise `undefined`
 * @param cellValue - A table cell value from `react-table`
 * @returns A string, the original value, or undefined
 */
export const reduceTableCellValueToString = <T,>(
  cellValue: T
): string | undefined | T => {
  if (!cellValue) {
    return;
  }
  if (!Object.prototype.hasOwnProperty.call(cellValue, "props")) {
    return cellValue;
  }
  const _cellValue = cellValue as unknown as JSX.Element;
  if (_cellValue?.props?.children) {
    const children = _cellValue?.props?.children as Array<JSX.Element>;
    if (!Array.isArray(children)) {
      return;
    }
    return children?.reduce((acc, child) => {
      if (child?.props) {
        const title = child?.props["data-row-title"];
        if (title) {
          acc = title;
        }
      }
      return acc;
    }, "");
  }
  return;
};

/**
 * A custom hook for returning a memoized object of custom fitler type(s) for use
 * with `react-table`
 * @returns Custom fitlers object
 */
export const useFilterTypes = (): FilterTypes<Record<string, FilterValue>> =>
  useMemo(
    () => ({
      /**
       * Globally filters table rows based on the values in the first column
       * Overrides the default "includes" filter
       * @param rows - The pre-filtered table body rows
       * @param id - Globally, all of the column accessors (ids)
       * @param filterValue - The value (string) passed to the filter
       * @returns - The filtered table body rows
       */
      includes: (
        rows: Row[],
        columnIds: IdType<Column>[],
        filterValue: FilterValue
      ): Row[] => {
        // get the id of the first column, using rows if none is passed in
        const _id = columnIds[0] || rows[0]?.cells[0]?.column?.id;
        return rows
          .map(row =>
            removeRowAttributes(row, filteredRowClassNameModification)
          )
          .filter(row => {
            const value = row?.original["value"];
            const key = value || reduceTableCellValueToString(row.values[_id]);
            return /string|number/.test(typeof key)
              ? String(key)
                  .toLowerCase()
                  .includes(String(filterValue).toLowerCase())
              : false;
          })
          .map(row => {
            if (filterValue.length > 0) {
              addRowAttributes(row, filteredRowClassNameModification);
            }
            return row;
          });
      },
    }),
    []
  );

function isCaptionRenderObject(
  caption: CaptionProp
): caption is CaptionRenderObject {
  return !!(
    caption &&
    typeof caption === "object" &&
    "render" in caption &&
    "title" in caption
  );
}

/**
 * Wraps simple strings in a `div` with the className `mct-c-caption__title`, otherwise
 * returns whatever was set on the caption prop inside a `TableCaption` element,
 * otherwise `null` (no-op)
 * @returns A correctly formatted caption, if one is set
 */
export const useTableCaption = (
  { genericElement } = { genericElement: false }
): ((caption: CaptionProp | null, captionClasses?: string) => JSX.Element) =>
  useCallback(
    (caption: CaptionProp | null, captionClasses = "") => {
      if (!caption) {
        return <TableCaption className="ds-u-display--none"></TableCaption>;
      }

      const fullCaption = (() => {
        if (typeof caption === "string") {
          return (
            <h3
              className={classNames(
                "mct-c-table__caption-title",
                captionClasses
              )}
            >
              {caption}
            </h3>
          );
        }
        if (isCaptionRenderObject(caption)) {
          return caption.render(
            <h3
              className={classNames(
                "mct-c-table__caption-title",
                captionClasses
              )}
            >
              {caption.title}
            </h3>
          );
        }
        return caption;
      })();
      return genericElement ? (
        /**
         * @TODO - For WAI-ARIA, the caption-like element should have a unique ID
         * and the table should have access to that ID to use in `aria-describedby`
         * @see https://www.w3.org/WAI/tutorials/tables/caption-summary/#using-aria-describedby-to-provide-a-table-summary
         * @see https://jira.cms.gov/browse/MCT-10662 */
        <div
          className={classNames("mct-c-table-caption", captionClasses)}
          data-testid="tableCaption"
        >
          {fullCaption}
        </div>
      ) : (
        <TableCaption
          className={classNames("mct-c-table-caption", captionClasses)}
          data-testid="tableCaption"
        >
          {fullCaption}
        </TableCaption>
      );
    },
    [genericElement]
  );

/**
 * Calls a callback, passing in the media query list match for below our large
 * breakpoint (1024px and below / 1025px and above)
 * @param cb - Callback that takes in the match (boolean) and does something with it!
 */
export const useBelowLargeBreakpoint = (
  cb: (T: MediaQueryListEvent["matches"] | MediaQueryList["matches"]) => void,
  dependencies: React.DependencyList = []
): void => {
  const mqlListener = (e: MediaQueryListEvent) => {
    cb(e.matches);
  };
  // useLayoutEffect to set layout-related props the table on media query matches
  useLayoutEffect(() => {
    const mql = window?.matchMedia("screen and (max-width:1024px)");
    cb(mql?.matches);
    mql &&
      mql.addEventListener &&
      mql.addEventListener("change", mqlListener, false);
    return () =>
      mql &&
      mql.removeEventListener &&
      mql.removeEventListener("change", mqlListener);
  }, dependencies);
};

/**
 * Adds attributes to a table row
 * @param row
 * @param attributes
 * @returns - Modified row with added attributes
 */
export const addRowAttributes = (
  row: AugmentedRow,
  attributes: RowAttributes
): AugmentedRow => {
  row.attributes = { ...row?.attributes, ...attributes };
  return row;
};

/**
 * Removes specified attribute key/values from a table row
 * @param row
 * @param attributes
 * @returns - Modified row with removed attributes
 */
export const removeRowAttributes = (
  row: AugmentedRow,
  attributes: RowAttributes
): AugmentedRow => {
  for (const [key, value] of Object.entries(attributes)) {
    if (row?.attributes && row.attributes[key] === value) {
      delete row.attributes[key];
    }
  }
  return row;
};

/**
 * Determine what colspan value, if any, to apply to a cell, assuming cells without
 * contents return `null`
 * @param params
 * @param params.index - Current cell / cell-contents index
 * @param params.cellContentsArray - The array of cells or cell-contents
 * @returns - Number of columns to span for the current cell
 */
export const getColSpanValue = ({
  index,
  cellContentsArray,
}: {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellContentsArray: any[];
}): number => {
  let i = index;
  // Determine whether the current cell should have a colspan attribute
  return cellContentsArray.reduce(acc => {
    const nextCell = cellContentsArray[i + 1];
    if (null === nextCell || (nextCell && null === nextCell?.value)) {
      acc++;
      i++;
    }
    return acc;
  }, 1);
};

/**
 * Set undefined Footers to null
 * @param - column
 * @returns - column
 */
export const setEmptyFooterToNull = (
  column: AugmentedColumn
): AugmentedColumn => {
  if (!column.Footer) {
    column.Footer = null;
  }
  return column;
};

/**
 * Determine whether a table column header is empty
 * @param column - A table column instance
 * @returns - Whether the column header is empty
 */
export const hasEmptyHeader = ({
  column,
  isFooter,
}: {
  column: Column | ColumnInstance;
  isFooter?: boolean;
}): boolean => {
  const element = isFooter ? "Footer" : "Header";
  let empty = false;
  if (null === column[element] || column[element] === "") {
    empty = true;
  }
  if (typeof column[element] === "function") {
    try {
      // TypeScript would correctly warning us that `_Header` may not be callable if not for the unknown.
      // However, this function is written in such a way that the failure to call the function is a feature
      // causing the `try/catch` to error out.
      const _Header = column[element] as unknown as () => {
        props: { children: unknown };
      };
      const rendered = _Header();
      const children = rendered?.props?.children;
      if (typeof children === "string") {
        empty = children.trim() === "";
      }
    } catch (e) {
      //do nothing
    }
  }
  return empty;
};

/**
 * A hammer approach to getting at a cell's value in a react-table Header function.
 * Cannot access these values via existing typings, from what I can tell.
 * NOTE: This helper assumes that the value returned by FormatMessage does not
 * contain HTML. Existing typing may not prevent substitution of FormatHTMLMessage,
 * (same function signature) which also returns a string.
 * Inlining this value as a string should not be harmful, but might
 * throw off screenreaders if appended to aria attribute content.
 * @see https://react-table.tanstack.com/docs/api/useTable#column-options -> Header/Function
 */
export const getCellStringValueFromHeaderProps = (
  props: AugmentedHeaderProps<col> | undefined,
  fm?: (
    messageDescriptor: MessageDescriptor,
    values?:
      | {
          [key: string]: string;
        }
      | undefined
  ) => string
): string => {
  if (!props || !("value" in props) || !props.value) {
    return "";
  } else {
    const { value } = props;
    switch (typeof value) {
      case "string":
        return value;
      case "number":
        return String(value);
      case "boolean":
        return "";
      default:
        if ("props" in value) {
          const { children, id, values } = value.props;
          if (children) {
            if (typeof children === "string" || typeof children === "number") {
              return String(children);
            }
            return "";
          }
          if (id && fm) {
            return fm({ id }, values || {});
          }
        }
    }
    return "";
  }
};
