import React from "react";

export const CoverageWizardInfoPage = React.lazy(
  () => import("../pages/CoverageWizard/CoverageWizardInfoPage")
);

export const CoverageWizardLandingPage = React.lazy(
  () => import("../pages/CoverageWizard/CoverageWizardLandingPage")
);

export const CoverageWizardOptionsPage = React.lazy(
  () => import("../pages/CoverageWizard/CoverageWizardOptionsPage")
);

export const CSRLandingPage = React.lazy(() =>
  import("../pages/CSRLandingPage").then(module => ({
    default: module.CSRLandingPage,
  }))
);

export const DrugSearchPreferencesPage = React.lazy(
  () => import("../pages/DrugSearchPreferencesPage/DrugSearchPreferencesPage")
);

export const EnrollmentFormPage = React.lazy(
  () => import("../pages/EnrollmentFormPage/EnrollmentFormPage")
);

export const FindPlansNowPage = React.lazy(() =>
  import("../pages/FindPlansNowPage/FindPlansNowPage").then(module => ({
    default: module.FindPlansNowPage,
  }))
);

export const InNetworkPharmaciesPage = React.lazy(() =>
  import("../pages/SelectPharmaciesPage/InNetworkPharmaciesPage").then(
    module => ({
      default: module.InNetworkPharmaciesPage,
    })
  )
);

export const LandingPage = React.lazy(
  () => import("../pages/LandingPage/LandingPage")
);

export const LISQuestionsPage = React.lazy(() =>
  import("../pages/LISQuestionsPage/LISQuestionsPage").then(module => ({
    default: module.LISQuestionsPage,
  }))
);

export const LoadingPage = React.lazy(() =>
  import("../pages/LoadingPage").then(module => ({
    default: module.LoadingPage,
  }))
);

export const LogoutPage = React.lazy(() =>
  import("../pages/LogoutPage/LogoutPage").then(module => ({
    default: module.LogoutPage,
  }))
);

export const MaintenancePage = React.lazy(
  () => import("../components/MaintenancePage")
);

export const ManagePharmaciesPage = React.lazy(
  () => import("../pages/ManagePharmaciesPage/ManagePharmaciesPage")
);

export const ManagePrescriptionsPage = React.lazy(
  () => import("../pages/ManagePrescriptionsPage/ManagePrescriptionsPage")
);

export const MedigapLandingPage = React.lazy(
  () => import("../pages/Medigap/MedigapLandingPage/MedigapLandingPage")
);

export const MedigapPlanDetailsPage = React.lazy(() =>
  import("../pages/Medigap/MedigapPlanDetailsPage/MedigapPlanDetailsPage").then(
    module => ({ default: module.MedigapPlanDetailsPage })
  )
);

export const MedigapPlans = React.lazy(
  () => import("../pages/Medigap/Plans/MedigapPlans")
);

export const MedigapPoliciesPage = React.lazy(() =>
  import("../pages/Medigap/Policies/MedigapPoliciesPage").then(module => ({
    default: module.MedigapPoliciesPage,
  }))
);

export const NewToMedicarePage = React.lazy(() =>
  import("../pages/NewToMedicarePage/NewToMedicarePage").then(module => ({
    default: module.NewToMedicarePage,
  }))
);

export const PaceLandingPage = React.lazy(
  () => import("../pages/Pace/PaceLandingPage")
);

export const PacePlansPage = React.lazy(
  () => import("../pages/Pace/PacePlansPage")
);

export const PapLandingPage = React.lazy(
  () => import("../pages/PAP/PapLandingPage")
);

export const PapPlansPage = React.lazy(
  () => import("../pages/PAP/PapPlansPage")
);

export const PDEPage = React.lazy(() => import("../pages/PDEPage/PDEPage"));

export const PlanComparePage = React.lazy(() =>
  import("../pages/PlanComparePage/PlanComparePage").then(module => ({
    default: module.PlanComparePage,
  }))
);

export const PlanDetailsPage = React.lazy(() =>
  import("../pages/PlanDetailsPage/PlanDetailsPage").then(module => ({
    default: module.PlanDetailsPage,
  }))
);

export const PlanPreviewPage = React.lazy(
  () => import("../pages/PlanPreviewPage/PlanPreviewPage")
);

export const QuestionRoutingPage = React.lazy(
  () => import("../pages/QuestionRoutingPage/QuestionRoutingPage")
);

export const SanctionedPlansPage = React.lazy(
  () => import("../pages/SanctionedPlansPage/SanctionedPlansPage")
);

export const SearchResultsPage = React.lazy(
  () => import("../pages/SearchResultsPage/SearchResultsPage")
);

export const SelectPharmaciesPage = React.lazy(() =>
  import("../pages/SelectPharmaciesPage/SelectPharmaciesPage").then(module => ({
    default: module.SelectPharmaciesPage,
  }))
);

export const SpapLandingPage = React.lazy(
  () => import("../pages/SPAP/SpapLandingPage")
);

export const SpapPlansPage = React.lazy(
  () => import("../pages/SPAP/SpapPlansPage")
);

export const SummaryPage = React.lazy(
  () => import("../pages/SummaryPage/SummaryPage")
);

export const VersionPage = React.lazy(
  () => import("../pages/VersionPage/VersionPage")
);

export const CSRGuestAccessPage = React.lazy(() =>
  import("../pages/CSRGuestAccessPage").then(module => ({
    default: module.CSRGuestAccessPage,
  }))
);
