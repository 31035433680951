import { useEffect } from "react";
import { logError } from "./errors";

/**
 * @param {unknown} exp - a truthy/falsy assertion we assume is true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the assert error.
 *
 * @example assert(planType, 'We should have a valid planType from route');
 */
export function assert(exp: unknown, message: string): asserts exp {
  // no-op if true
  if (exp) return;

  // log an error if false
  const err = new Error();
  logError(message, err);

  // if not in production, open a dialog box to ensure devs catch the error
  if (process.env.NODE_ENV !== "production") {
    // Disabled so that we can have open a confirm dialog ONLY in development / test.
    // eslint-disable-next-line no-restricted-globals
    if (confirm(message)) {
      // Disabled so we can pause on this breakpoint when debugging.
      // eslint-disable-next-line no-debugger
      debugger;
      console.error(err);
    }
  }
}

/**
 * assert to be used within React components (as opposed to within functions) to avoid multiple errors.
 * @param {unknown} exp - a truthy/falsy assertion we assume is true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the assert error.
 *
 * @example useAssert(county?.state, 'We should have a valid state from context');
 *  */
export function useAssert(exp: unknown, message: string): asserts exp {
  useEffect(() => {
    assert(exp, message);
  }, [exp, message]);
}
