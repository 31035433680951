import React from "react";
import classnames from "classnames";
import { CloseIcon, CheckIcon } from "@cmsgov/ds-medicare-gov";

const CheckmarkIcon: React.FunctionComponent<{
  checked: boolean;
  classNames?: string;
}> = ({ checked = false, classNames }) => {
  const icon = checked ? "ds-u-color--success" : "ds-u-color--error";
  const cNames = classnames(icon, "ds-u-valign--top", classNames);
  return (
    <span data-testid={checked ? "checkmarkIcon" : "xIcon"}>
      {checked ? (
        <CheckIcon className={cNames} />
      ) : (
        <CloseIcon className={cNames} />
      )}
    </span>
  );
};

export default CheckmarkIcon;
