import routes from "../../routes";
import { AnalyticsKey, Ga4Event, Ga4EventDimension } from "./types";

export const ga4EventDimensionValues = Object.values(Ga4EventDimension);

export const Ga4EventDimensionsConfig: Record<Ga4Event, AnalyticsKey[]> = {
  [Ga4Event.ENROLLMENT_COMPLETED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_INSURANCE_PROVIDER,
    Ga4EventDimension.MCT_INSURANCE_TYPE,
  ],
  [Ga4Event.ENROLLMENT_STARTED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_INSURANCE_PROVIDER,
    Ga4EventDimension.MCT_INSURANCE_TYPE,
  ],
  [Ga4Event.CW_COMPLETED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
  ],
  [Ga4Event.CW_STARTED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
  ],
  [Ga4Event.PLAN_FINDER_COMPLETED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_INSURANCE_PROVIDER,
    Ga4EventDimension.MCT_INSURANCE_TYPE,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_VIEWED_DRUGS,
  ],
  [Ga4Event.DRUG_SEARCH_PREF]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PLAN_TYPE,
  ],
  [Ga4Event.DRUGS_MANAGED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PLAN_TYPE,
  ],
  [Ga4Event.PHARMACY_SELECTED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.CLICK_TEXT,
    Ga4EventDimension.PHARMACY_COUNT,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.LINK_TYPE,
    Ga4EventDimension.SEARCH_RESULT_COUNT,
    Ga4EventDimension.PHARMACY_COST,
    Ga4EventDimension.PHARMACY_DISTANCE,
  ],
  [Ga4Event.PLAN_DETAILS]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.MCT_VIEWED_DRUGS,
  ],
  [Ga4Event.PLAN_DETAILS_NAVIGATION_CLICKED]: [Ga4EventDimension.CLICK_TEXT],
  [Ga4Event.PLAN_DETAILS_HELP_CARET_CLICKED]: [
    Ga4EventDimension.HEADER,
    Ga4EventDimension.SUB_SECTION,
  ],
  [Ga4Event.PLAN_DETAILS_VIEW_MORE_EXPANDED]: [Ga4EventDimension.CLICK_TEXT],
  [Ga4Event.PLAN_DETAILS_VIEW_MORE_CLOSED]: [Ga4EventDimension.CLICK_TEXT],
  [Ga4Event.PLAN_ENROLL_CLICKED]: [
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_INSURANCE_PROVIDER,
    Ga4EventDimension.MCT_INSURANCE_TYPE,
    Ga4EventDimension.MCT_LIS,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.MCT_VIEWED_DRUGS,
    Ga4EventDimension.CONTENT_TYPE,
  ],
  [Ga4Event.PLAN_FINDER_STARTED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_TEXT,
    Ga4EventDimension.LINK_URL,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_PLAN_YEAR,
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.FILTER_CLEARED]: [Ga4EventDimension.TEXT],
  [Ga4Event.RESULTS_FILTERED]: [
    Ga4EventDimension.TEXT,
    Ga4EventDimension.CLICK_TEXT,
    Ga4EventDimension.RADIO_BUTTON_TEXT,
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.FILTER_ACTION,
    Ga4EventDimension.FILTER_NAME,
    Ga4EventDimension.FILTER_TYPE,
  ],
  [Ga4Event.MODAL_CLOSED]: [
    Ga4EventDimension.HEADING,
    Ga4EventDimension.EVENT_TYPE,
  ],
  [Ga4Event.MODAL_IMPRESSION]: [
    Ga4EventDimension.HEADING,
    Ga4EventDimension.EVENT_TYPE,
  ],
  [Ga4Event.JUMP_LINK_CLICKED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.LINK_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.VIEW_DRUG_LIST]: [Ga4EventDimension.TEXT],
  [Ga4Event.VIEW_DRUG_COVERAGE]: [Ga4EventDimension.TEXT],
  [Ga4Event.LIST_SORTED]: [Ga4EventDimension.TEXT],
  [Ga4Event.COVERAGE_SELECTED]: [
    Ga4EventDimension.COVERAGE_TYPE,
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.DRUG_ADDED]: [Ga4EventDimension.DRUG_TYPE],
  [Ga4Event.DRUG_EDITED]: [Ga4EventDimension.DRUG_TYPE],
  [Ga4Event.DRUG_REMOVED]: [
    Ga4EventDimension.DRUG_NAME,
    Ga4EventDimension.DRUG_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PHARMACY_REMOVED]: [
    Ga4EventDimension.MCT_PHARMACY_NAME,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.MCT_IN_NETWORK_PHARMACY_FINDER,
    Ga4EventDimension.PHARMACY_COUNT,
  ],
  [Ga4Event.IN_NETWORK_PHARMACY_SELECTED]: [
    Ga4EventDimension.MCT_PHARMACY_NAME,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.MCT_IN_NETWORK_PHARMACY_FINDER,
    Ga4EventDimension.PHARMACY_COUNT,
  ],
  [Ga4Event.ADD_PROVIDER_TO_COMPARE]: [
    Ga4EventDimension.SEARCH_RESULT_COUNT,
    Ga4EventDimension.SEARCH_RESULT_POSITION,
    Ga4EventDimension.MCT_INSURANCE_PROVIDER,
    Ga4EventDimension.MCT_INSURANCE_TYPE,
    Ga4EventDimension.STAR_RATING,
  ],
  [Ga4Event.COMPARE_PROVIDERS]: [Ga4EventDimension.COMPARE_COUNT],
  [Ga4Event.PRINT]: [Ga4EventDimension.EVENT_NAME, Ga4EventDimension.LINK_TYPE],
  [Ga4Event.RADIO_BUTTON_SELECTED]: [
    Ga4EventDimension.TEXT,
    Ga4EventDimension.HEADING,
  ],
  [Ga4Event.IN_NETWORK_PHARMACY_BACK_TO_PLAN_DETAILS]: [
    Ga4EventDimension.CLICK_TEXT,
  ],
  [Ga4Event.HELP_DRAWER_OPENED]: [
    Ga4EventDimension.CLICK_TEXT,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.EXTENSION,
    Ga4EventDimension.LINK_TYPE,
  ],
  [Ga4Event.HELP_DRAWER_CLOSED]: [
    Ga4EventDimension.CLICK_TEXT,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PHARMACY_SEARCH_RESULTS]: [Ga4EventDimension.CLICK_TEXT],
  [Ga4Event.SAVED_PHARMACIES_VIEWED]: [Ga4EventDimension.EVENT_NAME],
  /**
   * ! FIXME: This property is identical to `Ga4Event.PHARMACY_SEARCH_RESULTS`
   * https://jira.cms.gov/browse/MCT-10184
   * */
  [Ga4Event.PLAN_FINDER_PHARMACY_SEARCH_RESULTS]: [
    Ga4EventDimension.TEXT,
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.PHARMACY_COUNT,
  ],
  [Ga4Event.PLAN_FINDER_SAVED_PHARMACIES_MANAGED]: [
    Ga4EventDimension.TEXT,
    Ga4EventDimension.EVENT_NAME,
  ],
  /**
   * ! FIXME: This property is identical to `Ga4Event.PHARMACY_REMOVED`
   * https://jira.cms.gov/browse/MCT-10184
   * */
  [Ga4Event.PLAN_FINDER_PHARMACY_REMOVED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.PHARMACY_COUNT,
    Ga4EventDimension.MCT_PHARMACY_NAME,
    Ga4EventDimension.MCT_PHARMACY_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PACE_PLANS_CTA_CLICKED]: [
    Ga4EventDimension.CONTENT_LANGUAGE,
    Ga4EventDimension.CONTENT_TYPE,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_TEXT,
    Ga4EventDimension.LINK_URL,
    Ga4EventDimension.LOGGED_IN,
    Ga4EventDimension.OPEN_ENROLLMENT,
    Ga4EventDimension.PAGE_NAME,
    Ga4EventDimension.ROLE,
    Ga4EventDimension.SITE_DOMAIN,
    Ga4EventDimension.SITE_ENVIRONMENT,
    Ga4EventDimension.SITE_SECTION,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PLAN_FINDER_ADD_RECENT_FILLED_DRUGS]: [Ga4EventDimension.TEXT],
  [Ga4Event.PLAN_FINDER_SAVED_DRUGS_MANAGED]: [
    Ga4EventDimension.DRUG_COUNT,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PLAN_FINDER_EDIT_DRUG]: [
    Ga4EventDimension.DRUG_NAME,
    Ga4EventDimension.DRUG_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.ACCORDION_CLOSED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.ACCORDION_OPENED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.ALERT_IMPRESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.INTERNAL_LINK_CLICKED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_TEXT,
    Ga4EventDimension.LINK_URL,
    Ga4EventDimension.CUSTOM,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.LINK_TYPE,
  ],
  [Ga4Event.BUTTON_ENGAGEMENT]: [
    Ga4EventDimension.BUTTON_STYLE,
    Ga4EventDimension.BUTTON_TYPE,
    Ga4EventDimension.CUSTOM,
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_URL,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.SHOW_IDLE_SESSION_MODAL]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.BENEFICIARY_KEY,
    Ga4EventDimension.CSR_ID,
  ],
  [Ga4Event.CONTINUE_IDLE_SESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.BENEFICIARY_KEY,
    Ga4EventDimension.CSR_ID,
  ],
  [Ga4Event.USER_END_IDLE_SESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.BENEFICIARY_KEY,
    Ga4EventDimension.CSR_ID,
  ],
  [Ga4Event.SYSTEM_END_IDLE_SESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.BENEFICIARY_KEY,
    Ga4EventDimension.CSR_ID,
  ],
  [Ga4Event.BIOSIMILAR_IMPRESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.MCT_BIOSIMILAR_DRUG_NAME,
    Ga4EventDimension.MCT_BIOSIMILAR_REFERENCE_DRUG_NAME,
    Ga4EventDimension.LOGGED_IN,
  ],
  [Ga4Event.BIOSIMILAR_LINK_CLICKED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_TEXT,
    Ga4EventDimension.MCT_BIOSIMILAR_DRUG_NAME,
    Ga4EventDimension.MCT_BIOSIMILAR_REFERENCE_DRUG_NAME,
    Ga4EventDimension.LOGGED_IN,
  ],
  [Ga4Event.LOWEST_COST_PHARMA_ALERT_IMPRESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.MCT_ALERT_TOPIC,
    Ga4EventDimension.ALERT_TYPE,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.LOWEST_COST_PHARMA_ALERT_CLOSED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.MCT_ALERT_TOPIC,
    Ga4EventDimension.ALERT_TYPE,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.YOY_CTA_ENGAGED]: [
    Ga4EventDimension.BUTTON_STYLE,
    Ga4EventDimension.BUTTON_TYPE,
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.LINK_URL,
    Ga4EventDimension.PARENT_COMPONENT_HEADING,
    Ga4EventDimension.PARENT_COMPONENT_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.PHARMACY_MAP_ENGAGEMENT]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.SEARCH_RESULT_COUNT,
    Ga4EventDimension.SEARCH_RESULT_TYPE,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
  ],
  [Ga4Event.MEDIGAP_SEARCH]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
  ],
  [Ga4Event.PACE_PLAN_SEARCH]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.TEXT,
    Ga4EventDimension.MCT_PLAN_TYPE,
    Ga4EventDimension.MCT_MEDIGAP,
    Ga4EventDimension.MCT_COVERAGE_WIZARD,
  ],
  [Ga4Event.FOOTNOTE_IMPRESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
    Ga4EventDimension.PLAN_ID,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.CONFIRM_PHARMACIES_VIEWED]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
  ],
  [Ga4Event.PLAN_DETAILS_COVERAGE_GAP]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.EVENT_TYPE,
  ],
  [Ga4Event.DROPDOWN_SELECTION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.HEADING,
    Ga4EventDimension.LINK_TYPE,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.BADGE_IMPRESSION]: [
    Ga4EventDimension.EVENT_NAME,
    Ga4EventDimension.DRUG_NAME,
    Ga4EventDimension.LINK_TYPE,
    Ga4EventDimension.PHARMACY_NPI,
    Ga4EventDimension.TEXT,
  ],
  [Ga4Event.TOOLTIP_VIEWED]: [],
};

export const routesWithQueryParamPages: string[] = [routes.enroll];
