import React from "react";
import { CheckmarkIcon } from "..";
import { useTypedTranslate } from "../../helpers/intlHooks";

interface PlanBenefitCheckmarkProps {
  text: string;
  checked: boolean;
}

export const PlanBenefitCheckmark = ({
  text,
  checked,
}: PlanBenefitCheckmarkProps) => {
  const t = useTypedTranslate();

  return (
    <>
      <CheckmarkIcon checked={checked} /> {text}
      <span className="ds-u-visibility--screen-reader">
        {checked
          ? ` ${t("plan_card.benefit.is_available")}`
          : ` ${t("plan_card.benefit.is_not_available")}`}
      </span>
    </>
  );
};
