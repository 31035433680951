import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import React, { SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { AnalyticsActionType } from "../../../app/contexts/Analytics/types";
import routes from "../../../app/routes";
import { useTranslate } from "../../../helpers/intlHooks";
import { MctHelpDrawer } from "../../HelpDrawerWrapper";
import { choiceIdMap } from "../constants";
import { PlanTypeSubHeader } from "./PlanTypeSubHeader";
import { AriaLetter } from "../../AriaLetter";
import { PlanTypeDescription } from "./PlanTypeDescription";
import { MedigapHelpDrawerContents } from "./MedigapHelpDrawerContents";
import {
  CoverageSelectorCoverageType,
  CoverageSelectorErrorState,
  SharedCoverageSelectorProps,
} from "../../../@types";
import { PlanTypeHelpDrawer } from "../../HelpDrawers/PlanTypeHelpDrawer";
import { useAppContext } from "../../../helpers/context-hooks/useAppContext";

interface PlanTypeStepProps extends SharedCoverageSelectorProps {
  planTypeErrorState?: CoverageSelectorErrorState;
  onPlanTypeChange: (value: CoverageSelectorCoverageType) => void;
  /** if `true`, will provide more prominent headers. */
  isSinglePage?: boolean;
  selectedPlanType?: CoverageSelectorCoverageType;
}

export const PlanTypeStep: React.FunctionComponent<PlanTypeStepProps> = ({
  showDescription,
  planTypeErrorState,
  onPlanTypeChange,
  newToMedicare,
  isSinglePage,
  selectedPlanType,
}) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();

  const needHelpText = newToMedicare
    ? t("coverage_selector.ntm.help_link_text")
    : t("coverage_selector.help_drawer.help_link_text");
  const needHelpLinkUrl = routes.coverageWizard.options;
  const [showFindPlansNowHelpDrawer, setShowFindPlansNowHelpDrawer] =
    useState(false);

  const [activeTooltip, setActiveTooltip] =
    useState<CoverageSelectorCoverageType>();

  // * Constants

  /**
   * on single page, we will scroll up to the top, so we don't need to show the error on bottom.
   * when there is no description, we also do not need to show the error on bottom, because there is less screen real estate used.
   */
  const showErrorOnTop = isSinglePage || !showDescription;

  // * Functions

  function toggleFindPlansNowHelpDrawer() {
    setShowFindPlansNowHelpDrawer(x => !x);
  }

  /** adds an 'active' class that is triggered by the tooltip opening.
   * This prevents weird overlapping issues by adding in a z-index where we need it.
   */
  function getChoiceContainerClassName(
    coverageType: CoverageSelectorCoverageType
  ) {
    if (coverageType === activeTooltip) {
      return "mct-c-coverage-selector-v2__plan-type-choice-container mct-c-coverage-selector-v2__plan-type-choice-container--active";
    }
    return "mct-c-coverage-selector-v2__plan-type-choice-container";
  }

  return (
    <div
      className="mct-c-coverage-selector-v2__plantype"
      data-testid="plan-type-section"
    >
      <ChoiceList
        className="ds-u-margin-top--0"
        choices={[
          // * Medicare Advantage Plan (Part C)
          {
            className: getChoiceContainerClassName(
              CoverageSelectorCoverageType.MAPD
            ),
            label: (
              <span
                data-testid={choiceIdMap[CoverageSelectorCoverageType.MAPD]}
              >
                <PlanTypeSubHeader
                  showDescription={showDescription}
                  isSinglePage={isSinglePage}
                >
                  <FormattedMessage
                    id="coverage_selector.plan_types.part_c.heading"
                    values={{
                      message: (
                        <AriaLetter className="mct-c-coverage-selector-v2__plan-type-sub-heading-aria">
                          C
                        </AriaLetter>
                      ),
                    }}
                  />
                </PlanTypeSubHeader>
                {showDescription && (
                  <PlanTypeDescription
                    coverageType={CoverageSelectorCoverageType.MAPD}
                    newToMedicare={newToMedicare}
                    setActiveTooltip={setActiveTooltip}
                  />
                )}
              </span>
            ),
            value: CoverageSelectorCoverageType.MAPD,
            id: choiceIdMap[CoverageSelectorCoverageType.MAPD],
            checked: selectedPlanType === CoverageSelectorCoverageType.MAPD,
          },
          // * Medicare drug plan (Part D)
          {
            className: getChoiceContainerClassName(
              CoverageSelectorCoverageType.PDP
            ),
            label: (
              <span data-testid={choiceIdMap[CoverageSelectorCoverageType.PDP]}>
                <PlanTypeSubHeader
                  showDescription={showDescription}
                  isSinglePage={isSinglePage}
                >
                  <FormattedMessage
                    id="coverage_selector.plan_types.part_d.heading"
                    values={{
                      message: (
                        <AriaLetter className="mct-c-coverage-selector-v2__plan-type-sub-heading-aria">
                          D
                        </AriaLetter>
                      ),
                    }}
                  />
                </PlanTypeSubHeader>
                {showDescription && (
                  <PlanTypeDescription
                    coverageType={CoverageSelectorCoverageType.PDP}
                    newToMedicare={newToMedicare}
                    setActiveTooltip={setActiveTooltip}
                  />
                )}
              </span>
            ),
            value: CoverageSelectorCoverageType.PDP,
            id: choiceIdMap[CoverageSelectorCoverageType.PDP],
            checked: selectedPlanType === CoverageSelectorCoverageType.PDP,
          },
          // * Medigap policy
          {
            className: getChoiceContainerClassName(
              CoverageSelectorCoverageType.MedigapPolicy
            ),
            label: (
              <span
                data-testid={
                  choiceIdMap[CoverageSelectorCoverageType.MedigapPolicy]
                }
              >
                <PlanTypeSubHeader
                  showDescription={showDescription}
                  isSinglePage={isSinglePage}
                >
                  {t("coverage_selector.plan_types.medigap.heading")}
                </PlanTypeSubHeader>
                {showDescription && (
                  <PlanTypeDescription
                    coverageType={CoverageSelectorCoverageType.MedigapPolicy}
                    newToMedicare={newToMedicare}
                    helpDrawerOpen={showFindPlansNowHelpDrawer}
                    showDrawer={toggleFindPlansNowHelpDrawer}
                    setActiveTooltip={setActiveTooltip}
                  />
                )}
              </span>
            ),
            value: CoverageSelectorCoverageType.MedigapPolicy,
            id: choiceIdMap[CoverageSelectorCoverageType.MedigapPolicy],
            checked:
              selectedPlanType === CoverageSelectorCoverageType.MedigapPolicy,
          },
        ]}
        errorMessage={planTypeErrorState?.message}
        errorMessageClassName="mct-c-coverage-selector-v2__plantype-error-message"
        errorPlacement={showErrorOnTop ? "top" : "bottom"}
        label={
          // * Next, select the type of plan you want:
          isSinglePage ? (
            <h2 className="ds-h3 ds-u-margin-top--5">
              {t("coverage_selector.plan_type.heading")}
            </h2>
          ) : (
            <h2 className="ds-text-heading--md ds-u-measure--narrow ds-u-margin-bottom--2">
              {t("coverage_selector.plan_type.heading")}
            </h2>
          )
        }
        name="coverage-selector-select-plan-type"
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          onPlanTypeChange(
            e.currentTarget.value as CoverageSelectorCoverageType
          );
        }}
        type="radio"
      />
      {showDescription ? (
        <Link
          className="ds-u-margin-y--1 ds-u-display--block"
          to={needHelpLinkUrl}
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
              settings: {
                linkText: needHelpText,
                linkUrl: needHelpLinkUrl,
              },
            });
          }}
        >
          {needHelpText}
        </Link>
      ) : (
        <span
          className="ds-text-body--md ds-u-margin-y--1 ds-u-display--block"
          data-testid="need-help-text"
        >
          <PlanTypeHelpDrawer newToMedicare={newToMedicare} />
        </span>
      )}
      {showFindPlansNowHelpDrawer && (
        <MctHelpDrawer
          closeDrawer={toggleFindPlansNowHelpDrawer}
          large
          drawerTitle={t("coverage_selector.new_to_medicare.medigap.when")}
        >
          <MedigapHelpDrawerContents />
        </MctHelpDrawer>
      )}
    </div>
  );
};
