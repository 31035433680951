import { ChoiceListProps } from "@cmsgov/design-system/dist/types/ChoiceList/ChoiceList";
import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import React, {
  FC,
  FocusEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslate } from "../helpers/intlHooks";
import { TranslationKey } from "../helpers/intlHooks";

export type RequiredChoiceListProps = {
  requiredErrorMessage?: ReactNode;
};

export const defaultErrorMessageKey: TranslationKey = "select_an_option";

export const RequiredChoiceList: FC<
  Omit<ChoiceListProps, "errorMessage"> & RequiredChoiceListProps
> = ({
  choices,
  onChange,
  onComponentBlur,
  requiredErrorMessage,
  "aria-describedby": ariaDescribedby,
  ...props
}) => {
  // Hooks
  const t = useTranslate();

  // State
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  // ? Could this be re-written with `useRef` instead?
  const [listContainerEl, setListContainerEl] = useState<HTMLDivElement | null>(
    null
  );

  const validateChoiceList: FocusEventHandler<HTMLInputElement> = () => {
    const message = requiredErrorMessage || t(defaultErrorMessageKey);
    if (!listContainerEl) {
      return;
    }
    const checkedChoice = listContainerEl.querySelectorAll("input:checked");
    setErrorMessage(checkedChoice.length === 0 ? message : null);
  };

  const setAriaDescribedBy = useCallback(() => {
    if (!listContainerEl || !ariaDescribedby) {
      return;
    }
    const choices = listContainerEl.querySelectorAll("input");
    choices.forEach(choice => {
      if (choice.checked) {
        choice.setAttribute("aria-describedby", ariaDescribedby);
      } else {
        choice.removeAttribute("aria-describedby");
      }
    });
  }, [ariaDescribedby, listContainerEl]);

  // Effects
  // On page load, set `aria-describedby` on any checked input
  useEffect(() => {
    setAriaDescribedBy();
  }, [setAriaDescribedBy]);

  // Make all inputs required
  useEffect(() => {
    if (!listContainerEl) {
      return;
    }
    const choices = listContainerEl.querySelectorAll("input");
    choices.forEach(choice => (choice.required = true));
  }, [listContainerEl]);

  return (
    <div
      ref={(node: HTMLDivElement) => {
        setListContainerEl(node);
      }}
    >
      <ChoiceList
        aria-describedby={ariaDescribedby}
        aria-invalid={!choices.some(choice => choice.checked)}
        choices={choices}
        errorMessage={errorMessage}
        onComponentBlur={e => {
          validateChoiceList(e);
          onComponentBlur && onComponentBlur(e);
        }}
        onChange={e => {
          setAriaDescribedBy();
          setErrorMessage(null);
          onChange && onChange(e);
        }}
        {...props}
      />
    </div>
  );
};
