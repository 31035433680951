import React from "react";
import { FormattedMessage } from "react-intl";
import HelpDrawerWrapper from "../HelpDrawerWrapper";
import { Alert, InfoCircleOutlineIcon } from "@cmsgov/ds-medicare-gov";
import CollapsibleBox from "../CollapsibleBox";
import { useCurrentPlanYear } from "../../helpers/yearFlagHelpers";
import { useFormatMessage, useTypedTranslate } from "../../helpers/intlHooks";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { linkToPartDLateEnrollmentPenalty } from "../../helpers/urlHelpers";

export const ServiceAreaReductionNotice = ({
  className,
  stackContent = false,
  textOnly = false,
}: {
  className?: string;
  stackContent?: boolean;
  textOnly?: boolean;
}) => {
  const t = useTypedTranslate();
  const currentPlanYear = useCurrentPlanYear();
  const fm = useFormatMessage();
  const nextPlanYear = currentPlanYear + 1;
  const {
    state: { language },
  } = useAppContext();

  return (
    <div className={`ds-u-font-size--base ${className}`}>
      <p className="ds-u-margin--0">
        <span className="ds-u-font-weight--bold">
          <FormattedMessage
            id="service_area_reduction.not_available_in_some_areas"
            values={{ year: nextPlanYear }}
          />
        </span>
        {stackContent ? <br /> : " "}
        <span>
          {t("service_area_reduction.contact_plan_to_find_out_impact")}
        </span>
      </p>

      {!textOnly && (
        <HelpDrawerWrapper
          toggleText={t("service_area_reduction.how_does_this_affect_me")}
          drawerTitle={t(
            "service_area_reduction.how_does_plan_availability_affect_me"
          )}
          toggleIcon={
            <span style={{ position: "relative", zIndex: 1 }}>
              <InfoCircleOutlineIcon />
            </span>
          }
          enableDefaultAnalytics={true}
          disableClickOutsideHandler={true}
        >
          <p className="ds-u-margin--0">
            <FormattedMessage
              id="service_area_reduction.may_lose_coverage"
              values={{ year: nextPlanYear, b: chunks => <b>{chunks}</b> }}
            />
          </p>

          <h4 className="ds-u-margin-bottom--0 ds-u-margin-top--2">
            {t("service_area_reduction.what_if_location_not_affected")}
          </h4>
          <p className="ds-u-margin--0">
            <FormattedMessage
              id="service_area_reduction.keep_current_plan"
              values={{ year: nextPlanYear }}
            />
          </p>

          <div className="ds-u-margin-top--2">
            <CollapsibleBox
              label={fm(
                {
                  id: "service_area_reduction.what_if_plan_not_available_in_area",
                },
                { year: nextPlanYear }
              )}
              labelClassName="ds-h4 ds-u-margin-bottom--1"
            >
              <p className="ds-u-font-weight--bold">
                {t("service_area_reduction.you_will_have_two_options")}
              </p>
              <ol>
                <li>
                  <FormattedMessage
                    id="service_area_reduction.join_another_plan"
                    values={{ year: nextPlanYear }}
                  />
                </li>
                <li>
                  {t("service_area_reduction.return_to_original_medicare")}
                </li>
              </ol>
              <p className="ds-u-font-weight--bold">
                <FormattedMessage
                  id="service_area_reduction.if_you_do_not_pick"
                  values={{ year: nextPlanYear }}
                />
              </p>

              <Alert
                hideIcon={true}
                variation="warn"
                heading={t("service_area_reduction.important_dates")}
              >
                <ul className="ServiceAreaReductionNotice__list">
                  <li>
                    <FormattedMessage
                      id="service_area_reduction.medigap"
                      values={{ b: chunks => <b>{chunks}</b> }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="service_area_reduction.drug_coverage"
                      values={{
                        b: chunks => <b>{chunks}</b>,
                        link: chunks => (
                          <a
                            href={linkToPartDLateEnrollmentPenalty(language)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="service_area_reduction.sep"
                      values={{
                        b: chunks => <b>{chunks}</b>,
                        year: nextPlanYear,
                      }}
                    />
                  </li>
                </ul>
              </Alert>
              <p className="ds-u-font-weight--bold ds-u-margin-top--2">
                {t("service_area_reduction.need_help")}
              </p>
              <ul className="ServiceAreaReductionNotice__list">
                <li>{t("service_area_reduction.call_medicare")}</li>
                <li>
                  <FormattedMessage
                    id="service_area_reduction.contact_ship"
                    values={{
                      link: chunks => (
                        <a
                          href="https://www.shiphelp.org/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </li>
              </ul>
            </CollapsibleBox>
          </div>
        </HelpDrawerWrapper>
      )}
    </div>
  );
};
