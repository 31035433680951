import React, { FC, ReactNode } from "react";

export const FeatureSubtitle: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ children, className }) => {
  return (
    <div
      className={`ds-u-md-margin-top--1 ds-u-font-size--sm ds-u-font-weight--normal${
        className ? ` ${className}` : ""
      }`}
    >
      {children}
    </div>
  );
};
