import React, { FC, useState, useEffect } from "react";
import { Alert, DateField, HelpDrawerToggle } from "@cmsgov/ds-medicare-gov";
import { MCTDate } from "../@types";
import { useTypedTranslate } from "../helpers/intlHooks";
import {
  isValidBirthdate,
  dateFromMCTDate,
  dateValidator,
} from "../helpers/dateHelpers";
import { limitDateInputs } from "../helpers/objectUtilities";
import {
  addYears,
  subMonths,
  addMonths,
  isWithinInterval,
  isBefore,
  isAfter,
} from "date-fns";
import { SEPHelpDrawer } from "./SEPHelpDrawer";
import { sendHelpDrawerOpenAnalyticsEvent } from "../app/contexts/Analytics/events";

export interface DOBValidatorProps {
  onComplete?: (dob: MCTDate | boolean) => unknown;
  defaultDate?: MCTDate;
  label?: React.ReactNode;
  onChange?: (e: Event | null, dob: MCTDate) => void;
  hideAlert?: boolean;
  errorMessage?: string;
  onComponentBlur?: () => void;
}

export const DOBValidator: FC<DOBValidatorProps> = ({
  onComplete,
  defaultDate,
  hideAlert = false,
  errorMessage = "",
  onComponentBlur,
  ...props
}) => {
  // * Translation
  const t = useTypedTranslate();

  // * State
  const [withinEnrollment, setWithinEnrollment] = useState(false);
  const [outsideOpenEnrollment, setOutsideOpenEnrollment] = useState(false);
  const [needSpecialEnrollment, setNeedSpecialEnrollment] = useState(false);
  const [helpDrawerOpen, setHelpDrawerOpen] = useState(false);
  const [dateInvalidStatus, setDateInvalidStatus] = useState({
    isDayInvalid: false,
    isMonthInvalid: false,
    isYearInvalid: false,
  });

  /* Function is needed for the date to be sent to the onChange function */
  const dateFormatter = (dob: MCTDate): MCTDate => {
    return {
      month: dob.month.substring(0, 2),
      day: dob.day.substring(0, 2),
      year: dob.year.substring(0, 4),
    };
  };

  const onChange = (_: Event | null, dob: MCTDate): void => {
    limitDateInputs();

    if (
      dob.day.length == 0 ||
      dob.month.length === 0 ||
      dob.year.length !== 4
    ) {
      return;
    }

    // verify individual date component first
    const { validDay, validMonth, validYear } = dateValidator(dob);
    setDateInvalidStatus({
      isDayInvalid: !validDay,
      isMonthInvalid: !validMonth,
      isYearInvalid: !validYear,
    });
    if (!validDay || !validMonth || !validYear) {
      return;
    }

    // verify entire date only if all date components are valid
    const isValidDate = isValidBirthdate(dob);

    if (isValidDate) {
      const dateOfBirth = dateFromMCTDate(dob);
      const sixtyFifthBirthday = addYears(dateOfBirth, 65);
      const todayMinus3Months = subMonths(new Date(), 3);
      const todayPlus3Months = addMonths(new Date(), 3);

      setWithinEnrollment(false);
      setNeedSpecialEnrollment(false);
      setOutsideOpenEnrollment(false);

      if (
        isWithinInterval(sixtyFifthBirthday, {
          start: todayMinus3Months,
          end: todayPlus3Months,
        })
      ) {
        setWithinEnrollment(true);
      } else if (isBefore(sixtyFifthBirthday, todayMinus3Months)) {
        setOutsideOpenEnrollment(true);
      } else if (isAfter(sixtyFifthBirthday, todayPlus3Months)) {
        setNeedSpecialEnrollment(true);
      }
    } else {
      setDateInvalidStatus({
        isDayInvalid: true,
        isMonthInvalid: true,
        isYearInvalid: true,
      });
    }
    onComplete && onComplete(dob);
  };

  const makeFieldVars = (period: keyof MCTDate): Record<string, unknown> => {
    return {
      [`${period}DefaultValue`]: defaultDate && defaultDate[period],
      [`${period}Label`]: t(`dob.${period}_label`),
    };
  };

  const dayVars = makeFieldVars("day");
  const monthVars = makeFieldVars("month");
  const yearVars = makeFieldVars("year");

  const onShowDrawer = (): void => {
    sendHelpDrawerOpenAnalyticsEvent({
      text: t("dob.see_if_you_qualify"),
      heading: t("dob.sep.help_me"),
    });
    setHelpDrawerOpen(!helpDrawerOpen);
  };

  useEffect(() => {
    if (defaultDate) {
      onChange(null, defaultDate);
    }
  }, []);

  return (
    <div>
      <DateField
        dateFormatter={dateFormatter}
        onChange={onChange}
        onComponentBlur={onComponentBlur}
        label={props.label || <span>{t("dob.whats_your_dob")}</span>}
        errorMessage={
          errorMessage
            ? errorMessage
            : dateInvalidStatus.isMonthInvalid ||
              dateInvalidStatus.isDayInvalid ||
              dateInvalidStatus.isYearInvalid
            ? t("enroll_form.enter_dob")
            : undefined
        }
        hint={t("dob.hint")}
        className="ds-u-margin-bottom--2"
        monthInvalid={dateInvalidStatus.isMonthInvalid}
        dayInvalid={dateInvalidStatus.isDayInvalid}
        yearInvalid={dateInvalidStatus.isYearInvalid}
        {...dayVars}
        {...monthVars}
        {...yearVars}
        {...props}
      />

      {!hideAlert && outsideOpenEnrollment && (
        <Alert
          hideIcon={true}
          className="ds-u-margin-top--2"
          heading={t("dob.outside_open_enrollment")}
        >
          <p>{t("dob.still_see_plans")}</p>
          <HelpDrawerToggle
            helpDrawerOpen={helpDrawerOpen}
            inline={true}
            showDrawer={onShowDrawer}
          >
            {t("dob.see_if_you_qualify")}
          </HelpDrawerToggle>
          {helpDrawerOpen && (
            <SEPHelpDrawer
              onCloseClick={() => {
                setHelpDrawerOpen(!helpDrawerOpen);
              }}
            />
          )}
        </Alert>
      )}

      {!hideAlert && withinEnrollment && (
        <Alert hideIcon={true} className="ds-u-margin-top--2">
          {t("dob.within_initial_enrollment")}
        </Alert>
      )}

      {!hideAlert && needSpecialEnrollment && (
        <>
          <Alert hideIcon={true} className="ds-u-margin-top--2">
            {t("dob.need_special_enrollment")}{" "}
            <HelpDrawerToggle
              helpDrawerOpen={helpDrawerOpen}
              inline={true}
              showDrawer={onShowDrawer}
            >
              {t("dob.see_if_you_qualify")}
            </HelpDrawerToggle>
          </Alert>
          {helpDrawerOpen && (
            <SEPHelpDrawer
              onCloseClick={() => {
                setHelpDrawerOpen(!helpDrawerOpen);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
