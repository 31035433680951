import React, { ChangeEvent, FunctionComponent } from "react";
import { Choice } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../../helpers/intlHooks";
import { Plan, SearchResultPlan } from "../../@types";
import { isCurrentPlan } from "../../helpers/planHelpers";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { trackLdEvent } from "../../helpers/launchDarklyHelpers";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";

interface AddToCompareProps {
  hide?: boolean;
  plan: SearchResultPlan;
  handleAddToCompare?: (plan: SearchResultPlan) => void;
  handleRemoveFromCompare?: (removeId: number | string) => void;
}

const AddToCompare: FunctionComponent<AddToCompareProps> = ({
  hide,
  plan,
  handleAddToCompare,
  handleRemoveFromCompare,
}) => {
  const {
    state: { compareList },
  } = useAppContext();
  const t = useTranslate();
  const ldClient = useLDClient();

  const isSelected = !!compareList.find((item: Plan | SearchResultPlan) =>
    isCurrentPlan(item, plan)
  );

  return (
    <>
      {!hide && (
        <Choice
          type="checkbox"
          value="add"
          checked={isSelected}
          name={`add_to_compare_${plan.id}`}
          className="e2e-compare-plan"
          labelClassName="ds-u-truncate"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            if (e.target.checked) {
              handleAddToCompare && handleAddToCompare(plan);
              trackLdEvent({
                ldClient,
                eventName:
                  "search_results_page.button_click.add_plan_to_compare",
              });
            } else {
              handleRemoveFromCompare && handleRemoveFromCompare(plan.id);
              trackLdEvent({
                ldClient,
                eventName:
                  "search_results_page.button_click.remove_plan_from_compare",
              });
            }
          }}
          label={
            <>
              {isSelected ? t("added") : t("add")}{" "}
              {t("plan_compare_footer.to_compare")}
            </>
          }
        />
      )}
    </>
  );
};

export default AddToCompare;
