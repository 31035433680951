export const msToMinsAndSeconds = (ms: number) => {
  if (ms <= 0) {
    return "0.00";
  }
  const minutes = Math.floor(ms / 60000);
  const seconds = ((ms % 60000) / 1000).toFixed(0);
  return seconds === "60"
    ? minutes + 1 + ":00"
    : minutes + ":" + (Number(seconds) < 10 ? "0" : "") + seconds;
};

export const msToMinutes = (num: number) => num / 1000 / 60;
