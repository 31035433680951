import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import { RemoveIcon, AddIcon } from "@cmsgov/ds-medicare-gov";
import React from "react";

export const ExpandIcon = (
  props: IconCommonProps & { expanded: boolean }
): React.ReactElement => {
  const { expanded, className, ...rest } = props;
  const classNames = `${className} mct-c-icon--plus-minus`;
  return (
    <span
      className="mct-u-display--inline-flex ds-u-align-items--center"
      role="img"
    >
      {expanded ? (
        <RemoveIcon className={classNames} {...rest} />
      ) : (
        <AddIcon className={classNames} {...rest} />
      )}
    </span>
  );
};
