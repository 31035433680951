import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import React from "react";

export default (
  <SvgIcon
    title="Icons/Create account"
    viewBox="0 0 32 32"
    id="Icons/Create-account"
  >
    <g transform="translate(0.000000, 3.000000)">
      <path d="M31.2,10.4 L28,10.4 L28,7.2 C28,6.76 27.64,6.4 27.2,6.4 L25.6,6.4 C25.16,6.4 24.8,6.76 24.8,7.2 L24.8,10.4 L21.6,10.4 C21.16,10.4 20.8,10.76 20.8,11.2 L20.8,12.8 C20.8,13.24 21.16,13.6 21.6,13.6 L24.8,13.6 L24.8,16.8 C24.8,17.24 25.16,17.6 25.6,17.6 L27.2,17.6 C27.64,17.6 28,17.24 28,16.8 L28,13.6 L31.2,13.6 C31.64,13.6 32,13.24 32,12.8 L32,11.2 C32,10.76 31.64,10.4 31.2,10.4 Z M11.2,12.8 C14.735,12.8 17.6,9.935 17.6,6.4 C17.6,2.865 14.735,0 11.2,0 C7.665,0 4.8,2.865 4.8,6.4 C4.8,9.935 7.665,12.8 11.2,12.8 Z M15.68,14.4 L14.845,14.4 C13.735,14.91 12.5,15.2 11.2,15.2 C9.9,15.2 8.67,14.91 7.555,14.4 L6.72,14.4 C3.01,14.4 0,17.41 0,21.12 L0,23.2 C0,24.525 1.075,25.6 2.4,25.6 L20,25.6 C21.325,25.6 22.4,24.525 22.4,23.2 L22.4,21.12 C22.4,17.41 19.39,14.4 15.68,14.4 Z"></path>
    </g>
  </SvgIcon>
);
