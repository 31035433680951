import React from "react";
import exclamationIcon from "../assets/exclamation.svg";
import { FCWithChildren } from "../@types";

const ExclamationNote: FCWithChildren = ({ children }) => {
  return (
    <span className="ds-u-display--flex">
      <img
        src={exclamationIcon}
        height="16"
        className="ds-u-margin-right--1 mct-u-margin-top--half"
        alt=""
      />
      <span>{children}</span>
    </span>
  );
};

export default ExclamationNote;
