import { config } from "../../config";

export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_MS = ONE_SECOND_MS * 60;
// const ACTIVITY_THROTTLE_MS = ONE_MINUTE_MS;
export const EVENT_LISTENER_THROTTLE_MS = ONE_MINUTE_MS / 2;
export const FIVE_MINUTE_MS = ONE_MINUTE_MS * 5;
export const CSR_IDLE_TIMEOUT_MS = config.CSR_IDLE_TIMEOUT_MINUTES
  ? ONE_MINUTE_MS * config.CSR_IDLE_TIMEOUT_MINUTES
  : ONE_MINUTE_MS * 15;
export const CSR_FORCE_LOGOUT_TIMEOUT_MS =
  config.CSR_FORCE_LOGOUT_TIMEOUT_MINUTES
    ? ONE_MINUTE_MS * config.CSR_FORCE_LOGOUT_TIMEOUT_MINUTES
    : FIVE_MINUTE_MS;
/**
 * This is the amount of time before a bene's session is due to expire (according
 * to SLS) that they should be prompted to continue or eventually be logged out.
 * It can be modified (for benes, not CSRs) with an environment variable passed
 * through `config`.
 */
export const FORCE_LOGOUT_TIMEOUT_MS = config.FORCE_LOGOUT_TIMEOUT_MINUTES
  ? ONE_MINUTE_MS * config.FORCE_LOGOUT_TIMEOUT_MINUTES
  : FIVE_MINUTE_MS;
