import React, { FC } from "react";
import Routes from "../../../routes";
import { AlertMessageConditions, AlertMessageContext } from "../types";
import { AlertMessageLink } from "../../../../components";
import { hasGlobalSession } from "../../../../helpers/loginHelpers";
import { AlertMessageLinkWrapperProps } from "./types";
import { useAppContext } from "../../../../helpers/context-hooks/useAppContext";

export const DefaultNoSavedDrugsLink: FC<
  AlertMessageLinkWrapperProps & {
    alertMessageContext: AlertMessageContext | undefined;
  }
> = ({ children, alertMessageContext, ...props }) => {
  return (
    <AlertMessageLink
      {...props}
      to={{
        pathname: Routes.managePrescriptions,
        state: alertMessageContext?.routerState,
      }}
      condition={AlertMessageConditions.NO_SAVED_DRUGS}
    >
      {children}
    </AlertMessageLink>
  );
};

export const NoSavedDrugsLink: FC<
  AlertMessageLinkWrapperProps & {
    alertMessageContext: AlertMessageContext;
  }
> = ({ children, alertMessageContext, ...props }) => {
  const { state } = useAppContext();
  const isLoggedIn = hasGlobalSession(state);
  const prescriptionsLink = isLoggedIn
    ? Routes.summary.managePrescriptions
    : Routes.managePrescriptions;
  return (
    <AlertMessageLink
      {...props}
      condition={AlertMessageConditions.NO_SAVED_DRUGS}
      to={{
        pathname: prescriptionsLink,
        search: alertMessageContext?.search,
        state: alertMessageContext?.routerState,
      }}
    >
      {children}
    </AlertMessageLink>
  );
};
