import React from "react";
import { Button, Dialog } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import { AutocompleteDrug } from "../@types";
import { FormattedMessage } from "react-intl";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export const userGenericDialogGenericAvailableTestId =
  "user-generic-dlg-generic-available";
interface UseGenericDialogProps
  extends Omit<React.ComponentProps<typeof Dialog>, "children"> {
  // brand name drug that has a generic alternative
  brandDrug: AutocompleteDrug;
  onSelectDrug: (selectedDrug: AutocompleteDrug) => void;
}

/**
 * UseGenericDialog is used to prompt the user if they like to use the generic drug.
 */
const UseGenericDialog: React.FunctionComponent<UseGenericDialogProps> = ({
  brandDrug,
  onSelectDrug,
  ...otherProps
}) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();

  return (
    <Dialog
      headerClassName="UseGenericDialog__promptHeader"
      heading={t("prescription.generic_prompt_header2")}
      actions={[
        <Button
          variation="solid"
          key="add-generic-button"
          className="e2e-add-generic-drug-btn"
          onClick={(): void => {
            dispatch({
              type: AnalyticsActionType.SEND_TEALIUM_EVENT,
              settings: {
                event_action: "Find Plans - Add Drugs",
                event_label: "Add Generic",
              },
            });
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.PRIMARY,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: t("prescription.add_generic"),
                },
              },
            });
            const genericDrug = brandDrug.generic as AutocompleteDrug;
            genericDrug.is_generic = true;
            onSelectDrug(genericDrug);
          }}
        >
          {t("prescription.add_generic")}
        </Button>,
        <Button
          key="add-brand-button"
          className="e2e-add-brand-name-drug-btn"
          onClick={(): void => {
            dispatch({
              type: AnalyticsActionType.SEND_TEALIUM_EVENT,
              settings: {
                event_action: "Find Plans - Add Drugs",
                event_label: "Add Brand",
              },
            });
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.DEFAULT,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: t("prescription.add_brand_instead"),
                },
              },
            });
            onSelectDrug(brandDrug);
          }}
        >
          {t("prescription.add_brand_instead2")}
        </Button>,
      ]}
      {...otherProps}
    >
      <p
        className="ds-u-margin-top--0 addGeneric__text"
        data-testid={userGenericDialogGenericAvailableTestId}
      >
        <strong>{brandDrug.name}</strong>{" "}
        {t("prescription.generic_available2.sanitized.1")}{" "}
        <strong>{(brandDrug as AutocompleteDrug).generic?.name}</strong>{" "}
        <FormattedMessage
          id="prescription.generic_available2.sanitized.2"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </p>
      <p className="addGeneric__text">
        <FormattedMessage
          id="prescription.add_generic_instead2"
          values={{
            generic: (brandDrug as AutocompleteDrug).generic?.name,
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </p>
    </Dialog>
  );
};

export default UseGenericDialog;
