/** Currency formatter */
export const $$ = new Intl.NumberFormat("en-US", {
  currency: "USD",
  minimumFractionDigits: 2,
  style: "currency",
}).format;

export const $$Whole = new Intl.NumberFormat("en-US", {
  currency: "USD",
  minimumFractionDigits: 0,
  style: "currency",
}).format;

export const $$OptionalCents = (value: number) => {
  return value % 1 === 0 ? $$Whole(value) : $$(value);
};

/** Shortcut for "If the property exists and is a number return it as currency, otherwise return as-is" */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const ifNumber = (prop: any): string => {
  return isNaN(prop) ? prop : $$(prop);
};
