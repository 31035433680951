import React, { FunctionComponent } from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Plan } from "../@types";
import { useTranslate } from "../helpers/intlHooks";

const EnrollmentDownAlert: FunctionComponent<{
  plan?: Plan;
  className?: string;
}> = ({ className }) => {
  const { feDisableHpmsEnrollment } = useFlags();
  const t = useTranslate();

  return feDisableHpmsEnrollment ? (
    <Alert
      variation="error"
      className={className}
      heading={t("enrollment_down_alert.currently_down")}
    >
      {t("enrollment_down_alert.cannot_process")}
    </Alert>
  ) : null;
};

export default EnrollmentDownAlert;
