import { getCounties } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey, StaleTime } from "./types";
import { isValidZipcode } from "../zipcodeHelpers";
import { useMemo } from "react";

/**
 * a hook to access counties based off of zipcode
 */
export const useCounties = (zipcode?: string) => {
  // * Constants
  const validZipcode = isValidZipcode(zipcode) ? zipcode : undefined;

  // * Queries
  const { isFetching, isError, data } = useQuery({
    queryKey: [QueryKey.Counties, validZipcode],
    queryFn: () => {
      return getCounties(validZipcode as string);
    },
    enabled: !!validZipcode,
    staleTime: StaleTime.Infinite,
    // because an error is a "valid" response if a zip code does not exist, we do not retry on failures
    retry: 0,
  });

  const counties = useMemo(() => data || [], [data]);

  return {
    /** an array of counties, based off of a zipcode */
    counties,
    /** if we are fetching data from the query */
    isFetching,
    /** if we hit an error from the query (likely a 404 for non-existent zipcode) */
    isError,
  };
};
