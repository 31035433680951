import { Button } from "@cmsgov/ds-medicare-gov";
import React from "react";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../../app/contexts/Analytics/types";
import { useTranslate } from "../../../helpers/intlHooks";
import { useAppContext } from "../../../helpers/context-hooks/useAppContext";

interface PlanTypePageActionsProps {
  findPlansButtonText: string;
  handleGoBack?: () => void;
}

export const PlanTypeStepActions: React.FunctionComponent<
  PlanTypePageActionsProps
> = ({ findPlansButtonText, handleGoBack }) => {
  const { dispatch } = useAppContext();
  const t = useTranslate();
  const goBackText = t("coverage_selector.go_back");
  return (
    <>
      <Button
        variation="solid"
        className="mct-c-coverage-selector-v2__start-button"
        data-testid="start-button"
        type="submit"
      >
        {findPlansButtonText}
      </Button>
      {handleGoBack && (
        <Button
          variation="ghost"
          className="mct-c-coverage-selector-v2__go-back-button"
          type="button"
          onClick={() => {
            handleGoBack();
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: goBackText,
                },
              },
            });
          }}
        >
          {goBackText}
        </Button>
      )}
    </>
  );
};
