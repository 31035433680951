import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React from "react";

const defaultProps = {
  className: "",
  title: "out-of-network icon",
  viewBox: "0 0 24 24",
};

function OutOfNetworkIcon(props: IconCommonProps): React.ReactElement {
  const iconCssClasses = `mct-c-icon--out-of-network ${props.className || ""}`;

  return (
    <SvgIcon {...defaultProps} {...props} className={iconCssClasses}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M19,10 L5,10 C4.44771525,10 4,10.4477153 4,11 L4,11 L4,13 C4,13.5522847 4.44771525,14 5,14 L5,14 L19,14 C19.5522847,14 20,13.5522847 20,13 L20,13 L20,11 C20,10.4477153 19.5522847,10 19,10 L19,10 Z"
      />
    </SvgIcon>
  );
}

export default OutOfNetworkIcon;
