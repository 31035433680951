import React, {
  ChangeEvent,
  FunctionComponent,
  useMemo,
  useState,
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslate } from "../helpers/intlHooks";
import { Alert, Dropdown } from "@cmsgov/ds-medicare-gov";
import states from "../helpers/states";
import { HashLink } from "react-router-hash-link";
import routes from "../app/routes";
import {
  Ga4Event,
  Ga4EventType,
  MctPlanTypeStrings,
} from "../app/contexts/Analytics/types";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import { usePlanStates } from "../helpers/query-hooks/usePlanStates";

interface DropdownOption {
  label: string;
  value: string;
}

interface StateSelectorProps extends RouteComponentProps {
  buttonText: string;
  defaultLabel: string;
  defaultValue?: string;
  label?: string;
  programType: "PACE" | "SPAP";
}

/**
 * StateSelector is used to display states for PACE and SPAP.
 */
const StateSelector: FunctionComponent<StateSelectorProps> = ({
  location,
  buttonText,
  defaultLabel,
  defaultValue = "",
  label,
  programType,
}) => {
  // * Translate
  const t = useTranslate();

  // * Context
  const { state, dispatch } = useAppContext();

  // * State
  const [selectedState, setSelectedState] = useState(defaultValue);

  // * Queries
  const { paceStates, spapStates, isError } = usePlanStates(programType);

  // * Constants
  const route = programType == "PACE" ? routes.pace.plans : routes.spap.plans;
  const stateAbbreviations = programType === "PACE" ? paceStates : spapStates;

  // * Memos
  const stateOptions: DropdownOption[] = useMemo(() => {
    return [
      { label: defaultLabel, value: "" },
      ...states
        .filter(state => {
          return stateAbbreviations.includes(state.abbreviation);
        })
        .map(state => {
          return {
            label: state.name,
            value: state.abbreviation,
          };
        }),
    ];
  }, [defaultLabel, stateAbbreviations]);

  return (
    <div className="StateSelector ds-u-display--flex ds-u-align-items--end ds-u-flex-wrap--wrap">
      {isError ? (
        <Alert variation="error" role="alert">
          {t("state_selector.error")}
        </Alert>
      ) : (
        <>
          <Dropdown
            className="ds-u-margin-right--0 ds-u-md-margin-right--2"
            label={label || t("state")}
            labelClassName="ds-u-margin-top--0"
            name="state"
            options={stateOptions}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setSelectedState(e.currentTarget.value);
            }}
            value={selectedState}
            data-testid="e2e-state-selector"
          />
          <div>
            <HashLink
              className={`ds-c-button ds-c-button--solid ds-c-button--big ds-u-margin-top--2${
                selectedState ? "" : " ds-c-button--disabled"
              }`}
              to={`${route.replace(":state", selectedState)}${location.search}`}
              onClick={() => {
                dispatch({
                  type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                  settings: {
                    event_action:
                      programType === "PACE" ? "Find Plans" : "See Programs",
                    event_label: selectedState,
                    other_props: { event_category: programType },
                  },
                });
                if (programType === "PACE") {
                  dispatch({
                    type: AnalyticsActionType.SEND_GA4_EVENT,
                    settings: {
                      event_name: Ga4Event.PACE_PLAN_SEARCH,
                      event_type: Ga4EventType.UI_INTERACTION,
                      mct_coverage_wizard: !!state.cwCoverage,
                      mct_medigap: false,
                      mct_plan_type: MctPlanTypeStrings.PACE_PLAN,
                      text: "find plans",
                    },
                  });
                }
              }}
              data-testid="e2e-view-program"
            >
              {buttonText}
            </HashLink>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(StateSelector);
