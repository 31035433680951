import React, { FunctionComponent } from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

const DegradedIntegrationAlert: FunctionComponent = (): JSX.Element => {
  const t = useTranslate();
  const {
    state: { degradedIntegrations },
  } = useAppContext();

  return degradedIntegrations.length ? (
    <Alert
      className="DegradedIntegrationAlert ds-u-margin-bottom--2"
      hideIcon={true}
      heading={t("degraded_int.heading")}
      variation="warn"
    >
      {t("degraded_int.body")}
    </Alert>
  ) : (
    <></>
  );
};

export default DegradedIntegrationAlert;
