import React from "react";
import { Action, ActionType } from "../@types";

export const setMbpReferrerUri = (
  dispatch: React.Dispatch<Action>,
  uri?: string
): void => {
  dispatch({
    type: ActionType.UPDATE_MBP_REFERRER_URI,
    payload: uri,
  });
};

export const clearMbpReferrerUri = (dispatch: React.Dispatch<Action>) =>
  setMbpReferrerUri(dispatch, "");

export const clearMbpReferrerUriAndRedirect = ({
  dispatch,
  mbpReferrerUri,
  e,
}: {
  dispatch: React.Dispatch<Action>;
  mbpReferrerUri: string;
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
}): void => {
  e.preventDefault();
  clearMbpReferrerUri(dispatch);
  window?.location?.assign(mbpReferrerUri);
};
