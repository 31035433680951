import { logError } from "./errors";

/**
 * Gets an array of asset manifest entries for building the global header or
 * logs and returns an empty array if the request fails
 */
export const getGlobalHeaderAssetEntries = async (
  manifestURL: string
): Promise<string[]> => {
  try {
    const res = await fetch(manifestURL);
    const json = await res.json();
    return json.entrypoints || [];
  } catch (err) {
    const e = err as Error;
    logError(e.message, e);
    return [];
  }
};
