/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import { Button, CloseIconThin } from "@cmsgov/ds-medicare-gov";
import stickybits from "stickybits";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { v1 as uuidv1 } from "uuid";
import { useTranslate } from "../helpers/intlHooks";
import { isWindowMobileOuterWidth } from "../helpers/objectUtilities";
import classNames from "classnames";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

interface StickyTrayProps {
  buttonContainerClassName?: string;
  className?: string;
  closeWhenOneItemLeft?: boolean;
  errorMessage?: React.ReactNode;
  heading: string;
  /**
   * If true, it would hide the sticky tray, but still display any warning toast messages.
   */
  hideStickyTray?: boolean;
  id?: string;
  items: {
    name: string;
    id: number | string;
    label?: React.ReactNode;
  }[];
  maxItems?: number;
  mobileOpenButtonClassName?: string;
  mobileOpenButtonText?: string;
  onMobileToggleVisibility?: (visibility: boolean) => void;
  noItemsText?: string;
  /**
   * If true, the tray will not stick to the bottom of the viewport. It will be visible only when scrolling to the end of the page.
   */
  notStickyOnMobile?: boolean;
  onRemoveItem: (id: number | string) => void;
  onSubmit: () => void;
  pageLoading?: boolean;
  screenReaderText?: string;
  subheading?: string;
  submitClass?: string;
  submitDisabled?: boolean;
  submitText: string;
  warningMessage?: React.ReactNode;
}

const StickyTrayComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  StickyTrayProps
> = (
  {
    buttonContainerClassName = "StickyTray__buttonContainer",
    closeWhenOneItemLeft,
    errorMessage,
    heading,
    hideStickyTray,
    id,
    items,
    maxItems = 3,
    mobileOpenButtonClassName = "StickyTray__mobileOpenButton",
    mobileOpenButtonText,
    noItemsText,
    notStickyOnMobile,
    onMobileToggleVisibility = (): void => {},
    onRemoveItem,
    onSubmit,
    pageLoading,
    screenReaderText,
    subheading,
    submitClass,
    submitDisabled,
    submitText,
    warningMessage,
    ...props
  },
  stickTrayRef
) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();
  /** duration to display toast, in millisecond */
  const TOAST_DURATION = 5000;
  const [toastId, setToastId] = useState("");
  /** applies only to mobile view */
  const [isOpen, _setIsOpen] = useState(false);
  const isOpenRef = useRef(isOpen);
  const setIsOpen = (value: boolean): void => {
    isOpenRef.current = value;
    _setIsOpen(value);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [stickyBit, setStickyBit] = useState<
    { update: () => void } | undefined
  >(undefined);

  const toggleVisibility = (): void => {
    const visibility = !isOpen;
    setIsOpen(visibility);
    onMobileToggleVisibility(visibility);
  };

  let lastWindowArea = window.outerHeight * window.outerWidth;

  const onResize = (): void => {
    const area = window.outerHeight * window.outerWidth;
    if (lastWindowArea !== area) {
      lastWindowArea = area;

      setTabIndex(
        !isWindowMobileOuterWidth(window) || isOpenRef.current ? 0 : -1
      );

      if (stickyBit) {
        stickyBit.update();
        window.scrollBy(0, 1);
      }
    }
  };

  useEffect(() => {
    setToastId(uuidv1());
    const identifier = id ? `#${id}` : ".js-stickybits";
    setStickyBit(stickybits(identifier, { verticalPosition: "bottom" }));
    window.scrollBy(0, 1);
    window.addEventListener("resize", onResize);
    return (): void => {
      window.removeEventListener("resize", onResize);
      document.body.classList.remove("no-scroll-on-mobile");
    };
  }, []);

  useEffect(() => {
    if (stickyBit) {
      stickyBit.update();
      window.scrollBy(0, 1);
    }
  }, [pageLoading, stickyBit]);

  useEffect(() => {
    if (closeWhenOneItemLeft) {
      if (items.length > 1) {
        toast.dismiss();
      }
    } else {
      toast.dismiss();
    }
  }, [items.length, closeWhenOneItemLeft]);

  useEffect(() => {
    if (isOpen) {
      if (!notStickyOnMobile) {
        document.body.classList.add("no-scroll-on-mobile");
      }
      setTabIndex(0);
    } else {
      document.body.classList.remove("no-scroll-on-mobile");
      setTabIndex(isWindowMobileOuterWidth(window) ? -1 : 0);
    }
  }, [isOpen, notStickyOnMobile]);

  errorMessage &&
    toast(
      <div className="ds-u-fill--error-lightest ds-u-padding--2">
        {errorMessage}
      </div>,
      {
        autoClose: TOAST_DURATION,
        className: "ds-u-padding--0",
        containerId: "stickyTrayToastContainer",
        icon: false,
        toastId,
        type: toast.TYPE.ERROR,
      }
    );

  warningMessage &&
    toast(
      <div className="ds-u-fill--primary-alt-lightest ds-u-padding--2">
        {warningMessage}
      </div>,
      {
        autoClose: TOAST_DURATION,
        className: "ds-u-padding--0",
        containerId: "stickyTrayToastContainer",
        icon: false,
        toastId,
      }
    );

  const numEmptyItems = maxItems - Math.min(items.length, maxItems);
  const emptyItems: string[] = Array(numEmptyItems).fill("");

  return (
    <>
      {!notStickyOnMobile && !hideStickyTray && (
        <div
          className={`StickyTray__background${isOpen ? "" : " closed"}`}
          onClick={(): void => setIsOpen(false)}
        />
      )}
      <div
        {...props}
        aria-live="polite"
        className={`StickyTray js-stickybits ${
          props.className ? props.className : ""
        }${isOpen ? "" : " closed"}${
          notStickyOnMobile ? " not-sticky-on-mobile" : ""
        }`}
        data-testid="StickyTray"
        id={id}
        ref={stickTrayRef}
        role="region"
        style={{ border: "none" }}
      >
        <ToastContainer
          autoClose={TOAST_DURATION}
          className="StickyTray__toast"
          closeOnClick={false}
          containerId="stickyTrayToastContainer"
          draggable={false}
          enableMultiContainer
          hideProgressBar
          icon={false}
          newestOnTop={false}
          position={toast.POSITION.BOTTOM_CENTER}
          rtl={false}
          transition={Zoom}
        />
        {!hideStickyTray && (
          <div className="StickyTray__body">
            <div className="StickyTray__content">
              <div
                className={`${buttonContainerClassName} ${
                  isOpen ? "open" : "closed"
                }`}
              >
                {/**
                 * The header.  When it's opened, the heading becomes a text heading and the close icon is displayed. When it's closed, the heading becomes a button and the close icon is not displayed.
                 */}
                <div
                  className="StickyTray__header"
                  id={id ? `${id}-sticky-tray-heading` : undefined}
                  onClick={(): void => {
                    // click anywhere in the header would also close the modal.
                    if (isOpen) {
                      setIsOpen(false);
                    }
                  }}
                  tabIndex={-1}
                >
                  {/* Open mode - Text heading with close button with close icon */}
                  <div className="StickyTray__heading">
                    <span>{heading}</span>
                    <span className="StickyTray__mobileCloseButtonContainer">
                      <Button
                        className="ds-u-padding--2"
                        variation="ghost"
                        onDark
                        onClick={() => {
                          dispatch({
                            type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                            settings: {
                              button: {
                                buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                                buttonType: AnalyticsButtonType.BUTTON,
                                text: t("close"),
                              },
                            },
                          });
                          toggleVisibility();
                        }}
                        tabIndex={tabIndex}
                        aria-label={t("close")}
                      >
                        <CloseIconThin />
                      </Button>
                    </span>
                  </div>
                  {subheading && isOpen && (
                    <div>
                      <span>{subheading}</span>
                    </div>
                  )}
                  {/* Close mode - heading is now a button */}
                  {!!items.length && (
                    <Button
                      onDark
                      className={mobileOpenButtonClassName}
                      onClick={() => {
                        dispatch({
                          type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                          settings: {
                            button: {
                              buttonStyle: AnalyticsButtonStyle.SECONDARY,
                              buttonType: AnalyticsButtonType.BUTTON,
                              text: mobileOpenButtonText || "Open",
                            },
                          },
                        });
                        toggleVisibility();
                      }}
                    >
                      {mobileOpenButtonText || "Open"}
                    </Button>
                  )}
                  {screenReaderText && (
                    <span className="ds-u-visibility--screen-reader">
                      {screenReaderText}
                    </span>
                  )}
                </div>
                {/* The list of items */}
                {items.length ? (
                  <ul
                    className={`StickyTray__list${isOpen ? "" : " closed"}`}
                    role="list"
                  >
                    {items.map(item => (
                      <li
                        className="StickyTray__list_item StickyTrayListItem"
                        key={item.id}
                        role="listitem"
                      >
                        <span
                          className={classNames("StickyTrayListItem__name", {
                            "ds-u-padding-bottom--0": item.label,
                          })}
                        >
                          {item.name}
                        </span>
                        {item.label && (
                          <div className="StickyTrayListItem__label">
                            {item.label}
                          </div>
                        )}

                        <Button
                          variation="ghost"
                          onDark
                          className="StickyTray__remove StickyTrayListItem__remove"
                          onClick={(): void => {
                            if (items.length === 1) {
                              setIsOpen(false);
                            }
                            if (closeWhenOneItemLeft && items.length === 2) {
                              setIsOpen(false);
                            }
                            onRemoveItem(item.id);
                            dispatch({
                              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                              settings: {
                                button: {
                                  buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                                  buttonType: AnalyticsButtonType.BUTTON,
                                  text: `${t("remove")} ${item.name}`,
                                },
                              },
                            });
                          }}
                          aria-label={`${t("remove")} ${item.name}`}
                          tabIndex={tabIndex}
                        >
                          <CloseIconThin />
                        </Button>
                      </li>
                    ))}
                    {emptyItems.map((_item, i) => (
                      <li
                        className="StickyTray__list_item StickyTray__list_item_empty"
                        key={`open-spot-${i}`}
                        aria-hidden
                      >
                        &nbsp;
                      </li>
                    ))}
                  </ul>
                ) : null}
                {!items.length ? (
                  <span className="ds-u-margin-right--2">{noItemsText}</span>
                ) : null}

                <Button
                  variation="solid"
                  onDark
                  isAlternate
                  data-testid="sticky-tray-submit-button"
                  className={`StickyTray__action ${submitClass}`}
                  disabled={submitDisabled}
                  onClick={() => {
                    dispatch({
                      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                      settings: {
                        button: {
                          buttonStyle: AnalyticsButtonStyle.DEFAULT,
                          buttonType: AnalyticsButtonType.SUBMIT,
                          text: submitText,
                        },
                      },
                    });
                    onSubmit();
                  }}
                >
                  {submitText}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const StickyTray = forwardRef(StickyTrayComponent);
