/**
 * Converts `document.cookie` to an object
 * @param cookies - String value of `document.cookie`
 * @returns - Object representation of cookies in key/value format
 */
const cookiesToObject = (cookies: string): { [key: string]: string } =>
  cookies
    .split("; ")
    .map(x => x.split(/=(.*)$/, 2).map(decodeURIComponent))
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1];
      return acc;
    }, {});

/**
 * Detect whether a cookie key/value has been added or removed
 * @param key - The name (key) of a cookie
 * @param _previous - `document.cookie` string to compare with cookies from a later time
 * @returns - A function that compares current `document.cookie` against the value
 *           passed into the original function call and returns `true` if the value
 *           referenced by the key was added or removed, otherwise `false`
 */
export const cookiesAddedOrRemoved =
  (key: string, _previous: string): (() => boolean) =>
  () => {
    const previous = cookiesToObject(_previous);
    const current = cookiesToObject(document.cookie);
    const removed = Object.keys(previous).filter(name => !current[name]);
    const added = Object.keys(current).filter(name => !previous[name]);
    return removed.concat(added).filter(name => name === key).length > 0;
  };
