import React, { Button, ButtonProps } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers/intlHooks";
import { useLogout } from "../helpers/sessionHelpers";
import { MctToastType } from "../@types";
import {
  hasGlobalSession,
  initiateLogin,
  getSlsxSignoutUrl,
} from "../helpers/loginHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

interface LoginLogoutButtonProps {
  buttonProps?: Partial<ButtonProps>;
  className?: string;
  loginKey?: string;
  size?: "small" | "big";
  hideIfDisabled?: boolean;
  onLogin?: () => void;
  onClick?: () => void;
  id?: string;
}

export const LoginLogoutButton = ({
  buttonProps = {},
  className,
  loginKey,
  size,
  hideIfDisabled,
  onLogin,
  onClick,
  id,
}: LoginLogoutButtonProps) => {
  const t = useTranslate();
  const { feEnableAuthentication } = useFlags();
  const { state, dispatch } = useAppContext();
  const logout = useLogout();

  const loggedIn = hasGlobalSession(state);
  const csrLogoutUrl = `/?mctt=${MctToastType.LOGGED_OUT}`;
  const ssoSignoutUrl = getSlsxSignoutUrl();

  const login = (): void => {
    if (onClick) {
      onClick();
    }
    initiateLogin(state.language, "", undefined);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.DEFAULT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: buttonText,
        },
      },
    });
  };

  const buttonText = loggedIn
    ? t("app.menu.logout")
    : t(loginKey || "app.menu.login");

  const loginDisplay =
    !feEnableAuthentication && !loggedIn ? (
      <p className={className}>{t("app.login_unavailable")}</p>
    ) : (
      <Button
        id={id}
        size={size}
        className={className}
        onClick={
          loggedIn
            ? () => {
                logout({
                  onLogout: () =>
                    dispatch({
                      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                      settings: {
                        button: {
                          buttonStyle: AnalyticsButtonStyle.DEFAULT,
                          buttonType: AnalyticsButtonType.BUTTON,
                          text: buttonText,
                        },
                        // @TODO - Refactor as separate component, use `useIsCsrSession`
                        // hook or `getIsCsrSession` helper to get `isCsr` boolean value
                        linkUrl: state.csr ? csrLogoutUrl : ssoSignoutUrl,
                      },
                    }),
                });
              }
            : onLogin || login
        }
        {...buttonProps}
      >
        {buttonText}
      </Button>
    );

  return !feEnableAuthentication && !loggedIn && hideIfDisabled ? (
    <></>
  ) : (
    loginDisplay
  );
};
