const routes = {
  anonLanding: "/",
  newToMedicare: "/new-to-medicare",
  findPlansNow: "/find-plans-now",
  comparePlans: "/compare-plans",
  yearOverYear: "/year-over-year",
  coverageWizard: {
    landingPage: "/coverage-options",
    options: "/coverage-options/options",
    info: "/coverage-options/info",
  },
  inNetworkPharmacy: "/in-network-pharmacy/:id",
  logout: "/logout",
  csrLanding: "/csr",
  csrGuestAccess: "/csr-guest-access",
  drugSearchPreferences: "/drug-search-preferences",
  enroll: "/enroll",
  enrollmentStatus: "/enrollment-status",
  lisQuestions: "/extra-help",
  managePrescriptions: "/manage-prescriptions",
  managePharmacies: "/manage-pharmacies",
  mbpLandingPage: "/mbp-callback",
  pace: {
    landingPage: "/pace",
    plans: "/pace/states/:state",
  },
  pap: {
    landingPage: "/pharmaceutical-assistance-program",
    plans: "/pharmaceutical-assistance-program/drugs/:drug",
  },
  pdePage: "/add-prescriptions",
  pharmacy: "/pharmacy",
  planDetails: "/plan-details/:id",
  prescriptionsList: "/view-prescriptions",
  questionRouting: "/questions",
  sanctionedPlans: "/sanctioned-plans",
  searchResults: "/search-results",
  slsCallback: "/sls-callback",
  spap: {
    landingPage: "/pharmaceutical-assistance-program/states",
    plans: "/pharmaceutical-assistance-program/states/:state",
  },
  summary: {
    drugSearchPreferences: "/summary/drug-search-preferences",
    landingPage: "/summary",
    lisQuestions: "/summary/extra-help",
    managePharmacies: "/summary/manage-pharmacies",
    managePrescriptions: "/summary/manage-prescriptions",
    pdePage: "/summary/add-prescriptions",
    searchResults: "/summary/search-results",
    selectPharmacies: "/summary/pharmacy",
  },
  version: "/version",
  planPreview: "/plan-preview/:id",
  medigap: {
    landingPage: "/m",
    plans: "/m/plans",
    planDetails: "/m/plan-details/:medigapPlanType",
    policies: "/m/plan-policies/:medigapPlanType",
  },
} as const;

/**
 * Add to this array any routes where the Global/Consistent Header should not
 * be displayed
 */
export const hideGlobalHeaderPaths = [routes.planPreview];

export const beneLandingAndCallbackRoutes: string[] = [
  routes.summary.landingPage,
  routes.slsCallback,
  routes.mbpLandingPage,
];

export default routes;
