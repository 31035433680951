import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { hasGlobalSession } from "../helpers/loginHelpers";
import { useUrlWithSearchParams } from "../helpers/routeHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

const PrivateRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const { state } = useAppContext();
  const redirect = useUrlWithSearchParams("/");

  if (hasGlobalSession(state)) {
    return <Route {...rest}>{children}</Route>;
  }
  // Not authenticated, no bene, go to landing
  return <Redirect to={redirect} />;
};

export default PrivateRoute;
