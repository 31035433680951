import React, { FunctionComponent, useState, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Dialog, LockIcon } from "@cmsgov/ds-medicare-gov";
import {
  ActionType,
  Plan,
  PlanEnrollmentStatus,
  PlanType,
  SearchResultPlan,
} from "../../@types";
import { isCurrentPlan } from "../../helpers/planHelpers";
import { useHistory } from "react-router-dom";
import routes from "../../app/routes";
import {
  getCantEnrollPlan,
  getPlanHasDsnpSuppression,
  getPlanIsFrozen,
  getPlanName,
} from "../../helpers/planHelpers";
import { useCurrentPlanYear, useYearPart } from "../../helpers/yearFlagHelpers";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
} from "../../app/contexts/Analytics/types";
import { usePendingEnrollments } from "../../helpers/query-hooks/usePendingEnrollments";
import { EnrollButton } from "./EnrollButton";
import { enrollmentButtonReplacementContentKeys } from "./constants";
import {
  EnrollButtonContentReplacementMap,
  EnrollButtonReplacmentHierarchy,
} from "./types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { useIsCsrSession } from "../../helpers/csrHelpers";
import { makePlanLongId } from "../../helpers/objectUtilities";

export interface EnrollOptions {
  plan: Plan | SearchResultPlan;
  aria_string?: string;
  onClick?: () => void;
  tealiumEventAction?: string;
  buttonClassName?: string;
  dialogClassName?: string;
}

export const EnrollButtonForm: FunctionComponent<EnrollOptions> = ({
  plan,
  plan: {
    contract_id,
    contract_year,
    enrollment_opt_in_status,
    enrollment_status,
    plan_id,
    low_performing,
    segment_id,
  },
  onClick,
  aria_string,
  tealiumEventAction,
  buttonClassName = "",
  dialogClassName = "",
}) => {
  // * Hooks
  const t = useTypedTranslate();
  const history = useHistory();
  const isCsr = useIsCsrSession();

  // * Flags
  const currentPlanYear = useCurrentPlanYear();
  const { isPublicPreview, isOpenEnrollmentNextYearOnly } = useYearPart();
  const { feDisableHpmsEnrollment: enrollmentDisabled } = useFlags() as {
    feDisableHpmsEnrollment: boolean;
  };

  // * Context
  const {
    state,
    state: { currentCoverage, nextYearCoverage },
    dispatch,
  } = useAppContext();

  // * Queries
  const {
    oecStatuses: { currentYear, nextYear },
  } = usePendingEnrollments();

  // * State
  const [showTransitionModal, setTransitionModalVisibility] = useState(false);

  // * Constants
  const hideEnrollForCurrentYearPlans =
    isOpenEnrollmentNextYearOnly &&
    plan.contract_year === currentPlanYear.toString();

  const pendingEnrollmentPlansLongIds: string[] = [];
  if (currentYear) {
    pendingEnrollmentPlansLongIds.push(makePlanLongId(currentYear));
  }
  if (nextYear) {
    pendingEnrollmentPlansLongIds.push(makePlanLongId(nextYear));
  }
  const currentPlanLongId = makePlanLongId(plan);
  const planIsPendingEnrollment =
    pendingEnrollmentPlansLongIds.includes(currentPlanLongId);
  const tealiumPlanType = plan.plan_type === PlanType.PDP ? "PDP" : "MA/MAPD";

  // * Effects

  useEffect(() => {
    if (showTransitionModal) {
      const dialogWrapper: HTMLElement | null =
        document.querySelector(".ds-c-dialog-wrap");

      if (dialogWrapper) {
        dialogWrapper.scrollTop = 0;
      }
    }
  }, [showTransitionModal]);

  const openEnrollModal = (): void => {
    setTransitionModalVisibility(true);
  };

  const isCurrPlan = isCurrentPlan(state.currentCoverage, {
    plan_id,
    contract_id,
    contract_year,
    segment_id,
  });

  const onCancel = (): void => {
    setTransitionModalVisibility(false);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.PRIMARY,
          buttonType: AnalyticsButtonType.BUTTON,
          text: t("plan_enroll.cancel"),
        },
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: tealiumEventAction || "Modal",
        event_label: "Go Back",
      },
    });
  };

  /**
   * Map possible button replacement text, **__in order of precedence__**
   * This is not an array of content to be rendered, so an eslint rule is
   * disabled around the returned array
   */
  const buttonTextReplacementMap: EnrollButtonContentReplacementMap = useMemo(
    /* eslint-disable react/jsx-key */
    () => [
      // 1 - Can't enroll current coverage
      [
        <p className="mct-c-enroll-button-form__text ds-u-text-align--center">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.CANT_ENROLL_CURRENT_YEAR
            ]
          )}
        </p>,
        getCantEnrollPlan({
          coverage: currentCoverage,
          isCsr,
          plan,
        }),
      ],
      // 2 - Can't enroll future coverage
      [
        <p className="mct-c-enroll-button-form__text ds-u-text-align--center">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.CANT_ENROLL_CURRENT_YEAR_NEXT_YEAR
            ]
          )}
        </p>,
        getCantEnrollPlan({
          coverage: nextYearCoverage,
          isCsr,
          plan,
        }),
      ],
      // 3 - Enrollment disabled by flag
      [
        <p className="mct-c-enroll-button-form__text ds-u-font-style--italic">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.ENROLLMENT_DISABLED
            ]
          )}
        </p>,
        enrollmentDisabled,
      ],
      // 4 - Plan has DSNP suppression
      [
        <p className="mct-c-enroll-button-form__text">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.DSNP_SUPPRESSION
            ]
          )}
        </p>,
        getPlanHasDsnpSuppression(plan),
      ],
      // 5 - Plan has "enrollment opt-in" status
      [
        <p className="mct-c-enroll-button-form__text">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.NO_OPT_IN_STATUS
            ]
          )}
        </p>,
        !enrollment_opt_in_status,
      ],
      // 6 - Plan is frozen
      [
        <p className="mct-c-enroll-button-form__text">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.PLAN_FROZEN
            ]
          )}
        </p>,
        getPlanIsFrozen(plan),
      ],
      // 7 - Plan has "no enrollment at this time" status
      [
        <p className="mct-c-enroll-button-form__text">
          {t(
            enrollmentButtonReplacementContentKeys[
              EnrollButtonReplacmentHierarchy.NO_ENROLLMENT_AT_THIS_TIME
            ]
          )}
        </p>,
        enrollment_status === PlanEnrollmentStatus.NOT_NOW,
      ],
    ],
    /* eslint-enable react/jsx-key */
    [
      currentCoverage,
      enrollmentDisabled,
      enrollment_opt_in_status,
      enrollment_status,
      isCsr,
      nextYearCoverage,
      plan,
      t,
    ]
  );

  // * Render
  return planIsPendingEnrollment ? null : low_performing ? (
    <p className="ds-u-text-align--center">
      {t("plan_card.contact_plan_to_enroll")}
    </p>
  ) : (
    <div
      className={`EnrollTransitionDialog PlanCard__mobile_order_2 ${dialogClassName}`}
    >
      {showTransitionModal && (
        <Dialog
          size="wide"
          className="EnrollTransitionDialog__dialog"
          headerClassName="EnrollTransitionDialog__header"
          onExit={onCancel}
        >
          <div className="EnrollTransitionDialog__content">
            <h1
              className="ds-text-heading--lg ds-u-margin-bottom--0"
              data-cy="enrollment-form-dlg-heading"
            >
              {+plan.contract_year === currentPlanYear + 1 ? (
                <FormattedMessage
                  id="plan_enroll.you_are_joining_with_year"
                  values={{ year: plan.contract_year }}
                />
              ) : (
                t("plan_enroll.you_are_joining")
              )}
            </h1>
            <div className="EnrollTransitionDialog__planDetails">
              <h2
                id="dialog-title"
                className="ds-text-heading--2xl ds-u-margin--0"
              >
                {getPlanName(plan, state.language)}
              </h2>
              <h3 className="ds-text-heading--xl ds-u-margin--0 ds-u-color--gray">
                {`${t(
                  "plan_terms.plan_id"
                )} ${contract_id}-${plan_id}-${segment_id}`}
              </h3>
            </div>
          </div>
          <div className="EnrollTransitionDialog__content">
            <b>{t("enroll_transition.checklist_heading")}:</b>
            <ul className="EnrollTransitionDialog__checklist">
              <li>{t("enroll_transition.checklist.1")}</li>
              <li>{t("enroll_transition.checklist.2")}</li>
              <li>{t("enroll_transition.checklist.3")}</li>
            </ul>
          </div>
          <div className="EnrollTransitionDialog__content ds-u-display--flex ds-u-justify--start ds-u-align-items--center">
            <LockIcon className="ds-u-margin-right--1" />
            <span className="ds-u-font-size--sm ds-u-color--gray">
              {t("enroll_transition.security_advisory.update")}
            </span>
          </div>

          <div className="EnrollTransitionDialog__actions">
            <Button
              className="EnrollTransitionDialog__cancelButton"
              key="cancel"
              onClick={onCancel}
              data-cy="enrollment-form-dlg-cancel-button"
            >
              {t(
                plan.contract_year === "2021"
                  ? "enroll_transition.2021.go_back"
                  : "plan_enroll.cancel"
              )}
            </Button>

            <Button
              variation="solid"
              className="e2e-submit-enrollment-form"
              key="primary"
              onClick={(): void => {
                dispatch({
                  type: ActionType.UPDATE_SELECTED_PLAN,
                  payload: plan,
                });
                history.push(routes.enroll);
                setTransitionModalVisibility(false);
                dispatch({
                  type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                  settings: {
                    event_action: tealiumEventAction || "Modal",
                    event_label: `Join Plan - ${plan.name} - ${tealiumPlanType}`,
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.PRIMARY,
                      buttonType: AnalyticsButtonType.BUTTON,
                      text: t("plan_enroll.join_plan"),
                    },
                    linkUrl: routes.enroll,
                  },
                });
                dispatch({
                  type: AnalyticsActionType.SEND_GA4_EVENT,
                  settings: {
                    event_name: Ga4Event.ENROLLMENT_STARTED,
                  },
                });
              }}
            >
              {t("plan_enroll.join_plan")}
            </Button>
          </div>
        </Dialog>
      )}

      {isPublicPreview && (
        <>
          {plan.contract_year === (currentPlanYear + 1).toString() && (
            <p className="e2e-enroll-begins">
              {t("public_preview.enroll_begins")}
            </p>
          )}

          {plan.contract_year === currentPlanYear.toString() && (
            <EnrollButton
              aria_string={aria_string}
              buttonClassName={buttonClassName}
              onClick={onClick}
              openEnrollModal={openEnrollModal}
              plan={plan}
              tealiumEventAction={tealiumEventAction}
              contentReplacementMap={buttonTextReplacementMap}
            />
          )}
        </>
      )}

      {!isPublicPreview && !hideEnrollForCurrentYearPlans && (
        <EnrollButton
          aria_string={aria_string}
          buttonClassName={buttonClassName}
          onClick={onClick}
          openEnrollModal={openEnrollModal}
          plan={plan}
          tealiumEventAction={tealiumEventAction}
          contentReplacementMap={buttonTextReplacementMap}
        />
      )}

      {isCurrPlan && (
        <p>
          <FormattedMessage id="current_plan.current_coverage_disclaimer" />
        </p>
      )}
    </div>
  );
};
