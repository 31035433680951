/* eslint-disable max-lines */
import { Point } from "geojson";
import * as H from "history";
import { SSN } from "../components/SSNInput";
import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { RouteComponentProps } from "react-router-dom";
import { TranslationKey } from "../helpers/intlHooks";
import { Ga4Event } from "../app/contexts/Analytics/types";
import { AnalyticsActions } from "../app/contexts/Analytics/types";
import { Dispatch } from "react";
import { ApiError } from "../helpers/errors";
import { HouseholdDiscount } from "../pages/Medigap/types";
import { AddressTextField } from "./components";
import { SepReasonCode } from "../pages/EnrollmentFormPage/pages/sep/types";

declare global {
  interface Window {
    utag?: {
      link: (params: Record<string, unknown>) => void;
      view: (params: Record<string, unknown>) => void;
      gdpr: { getConsentState: () => number | { ct: string; name: string }[] };
    };
    playwright?: Record<string, unknown>;
    store?: { state: AppState; dispatch: Dispatch<Action> };
    router?: { history: RouteComponentProps["history"] };
  }
}

export enum UserRole {
  ANON_BENEFICIARY = "anon",
  AUTH_BENEFICIARY = "beneficiary",
  CSR = "csr",
}

export enum UserType {
  ANON_BENEFICIARY = "guest",
  AUTH_BENEFICIARY = "beneficiary",
  CSR = "csr",
}

export interface LdFlagsResponse {
  flags: LDFlagSet;
  "secure-mode-hash": string;
}

/**
 * @see {@link https://imp.medicare.gov/plan-compare/guide/external_dependencies/launchdarkly/use.html?highlight=year%20parts#launchdarkly-mct-current-plan-year-and-mct-year-part-flags|LaunchDarkly Year Part flags}
 */
export enum MctYearPartFlag {
  OUTSIDE_OPEN_ENROLLMENT = "OOE",
  PUBLIC_PREVIEW = "PP",
  OPEN_ENROLLMENT = "OE",
  OPEN_ENROLLMENT_NEXT_YEAR_ONLY = "OE_NYO",
}

export interface PharmaciesRequest {
  zipcode: string;
  address?: string;
  latitude?: string;
  longitude?: string;
  name?: string;
  radius_miles?: number;
  count: number;
  page: number;
  preferred_only?: boolean;
}

export interface AbstractBenefitFrequencyCost {
  days_30: string | null;
  days_90: string | null;
  days_any: string | null;
}

export interface AbstractBenefitPhase {
  footnote: string;
  tiers: AbstractBenefitTier[];
}

export enum AbstractBenefitPhaseEnum {
  initial_coverage = "initial_coverage",
  coverage_gap = "coverage_gap",
  catastrophic = "catastrophic",
  lis = "lis",
  platino = "platino",
}

export interface AbstractBenefits {
  catastrophic: AbstractBenefitPhase | null;
  coverage_gap: AbstractBenefitPhase | null;
  initial_coverage: AbstractBenefitPhase | null;
  lis: AbstractBenefitPhase | null;
  platino: AbstractBenefitPhase | null;
}

export interface AbstractBenefitTier {
  general: string | null;
  label: string;
  preferred_mail_order: AbstractBenefitFrequencyCost | null;
  preferred_retail: AbstractBenefitFrequencyCost | null;
  standard_mail_order: AbstractBenefitFrequencyCost | null;
  standard_retail: AbstractBenefitFrequencyCost | null;
  tier_row_order: number;
}

export enum AccessibilityFormat {
  DEFAULT = "",
  BRAILLE = "Braille",
  LARGE_PRINT = "LargePrint",
  AUDIO_CD = "AudioCD",
}

export interface Action {
  payload?: unknown;
  type: ActionType;
}
export interface EventTrackingPayload {
  event: Ga4Event;
  value: unknown;
}

export enum ActionType {
  ADD_BENEFICIARY = "ADD_BENEFICIARY",
  ADD_CSR = "ADD_CSR",
  ADD_CURRENT_COVERAGE = "ADD_CURRENT_COVERAGE",
  ADD_NEXT_YEAR_COVERAGE = "ADD_NEXT_YEAR_COVERAGE",
  ADD_PHARMACY = "ADD_PHARMACY",
  ADD_PRESCRIPTION = "ADD_PRESCRIPTION",
  DELETE_PHARMACY = "DELETE_PHARMACY",
  DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION",
  DISMISS_ALERT = "DISMISS_ALERT",
  EDIT_PRESCRIPTION = "EDIT_PRESCRIPTION",
  EVENT_TRACKED = "EVENT_TRACKED",
  LOWEST_COST_PHARMACY_PARAMETERS_UPDATED = "LOWEST_COST_PHARMACY_PARAMETERS_UPDATED",
  RESET_PHARMACIES = "RESET_PHARMACIES",
  /**
   * This action deletes `cms-mct-store` (the store), including the `beneficiary`
   * object. It will be recreated with default values (and no `beneficiary`)
   *
   * Optional `action.payload` value, `logout: true` indicates only `UserLanguage`
   * should be preserved
   */
  RESET_STATE = "RESET_STATE",
  SAVE_PHARMACIES = "SAVE_PHARMACIES",
  SET_MAIL_ORDER_PHARMACY_NETWORK_STATUS = "SET_MAIL_ORDER_PHARMACY_NETWORK_STATUS",
  SET_PHARMACIES = "SET_PHARMACIES",
  SET_PRESCRIPTIONS = "SET_PRESCRIPTIONS",
  SET_TEALIUM_LOADED = "SET_TEALIUM_LOADED",
  UPDATE_COMPARE_LIST = "UPDATE_COMPARE_LIST",
  UPDATE_COUNTY = "UPDATE_COUNTY",
  UPDATE_CSR_GUEST_ACCESS = "UPDATE_CSR_GUEST_ACCESS",
  UPDATE_CW_COVERAGE = "UPDATE_CW_COVERAGE",
  UPDATE_DEGRADED_INTEGRATIONS = "UPDATE_DEGRADED_INTEGRATIONS",
  UPDATE_DEMOGRAPHICS = "UPDATE_DEMOGRAPHICS",
  UPDATE_ENROLL_DATA = "UPDATE_ENROLL_DATA",
  UPDATE_FIPS = "UPDATE_FIPS",
  UPDATE_FUTURE_LIS = "UPDATE_FUTURE_LIS",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  UPDATE_LAST_ACTIVITY = "UPDATE_LAST_ACTIVITY",
  UPDATE_LD_FLAGS = "UPDATE_LD_FLAGS",
  UPDATE_LIS = "UPDATE_LIS",
  UPDATE_MBP_HANDOFF_FAILED = "UPDATE_MBP_HANDOFF_FAILED",
  UPDATE_MBP_REFERRER_URI = "UPDATE_MBP_REFERRER_URI",
  UPDATE_MULTIPLE_ROLLOVER_PLANS = "UPDATE_MULTIPLE_ROLLOVER_PLANS",
  UPDATE_NEW_TO_MEDICARE_ALERT_INFO = "UPDATE_NEW_TO_MEDICARE_ALERT_INFO",
  UPDATE_PHARMACY_SEARCH_ADDRESS = "UPDATE_PHARMACY_SEARCH_ADDRESS",
  UPDATE_PHARMACY_NETWORK_STATUS = "UPDATE_PHARMACY_NETWORK_STATUS",
  UPDATE_PHARMACY_TYPE = "UPDATE_PHARMACY_TYPE",
  UPDATE_PLAN_TYPE = "UPDATE_PLAN_TYPE",
  UPDATE_ROUTE_PARAMS = "UPDATE_ROUTE_PARAMS",
  UPDATE_SEARCH_RESULTS_FILTERS = "UPDATE_SEARCH_RESULTS_FILTERS",
  UPDATE_SEARCH_RESULTS_FILTERS_CARRIERS = "UPDATE_SEARCH_RESULTS_FILTERS_CARRIERS",
  UPDATE_SELECTED_PLAN = "UPDATE_SELECTED_PLAN",
  UPDATE_SNP_FILTERS = "UPDATE_SNP_FILTERS",
  UPDATE_WIZARD_ANSWER = "UPDATE_WIZARD_ANSWER",
  UPDATE_YEAR = "UPDATE_YEAR",
  UPDATE_ZIPCODE = "UPDATE_ZIPCODE",
  UPDATE_ROLLOVER_PLAN_STATUS = "UPDATE_ROLLOVER_STATUS",
}

export interface AdditionalSupplementalBenefits {
  mmp_benefits: OtherBenefitCategory[];
  other_benefits: OtherBenefitCategory[];
  special_benefits: OtherBenefitCategory[];
}

export enum AdditionalSupplementalBenefitCoverage {
  SB_COVERAGE_NOT_COVERED = "SB_COVERAGE_NOT_COVERED",
  SB_COVERAGE_SOME_COVERAGE = "SB_COVERAGE_SOME_COVERAGE",
}

export interface ApiErrorMessage {
  errorResponse: ApiErrorResponse;
  status: number;
  statusText: string;
  type: string;
  url: string;
}

export interface ApiErrorResponse {
  code?: number;
  details?: ApiErrorResponseDetail[];
  error: string;
  message?: string;
}

export interface ApiErrorResponseDetail {
  "@type": string;
  request_id: string;
  serving_data: ApiResponseServingData | string;
}

export interface CwPremiumRanges {
  request_id: string;
  pdp_premium_min: number | null;
  pdp_premium_max: number | null;
  ma_premium_min: number | null;
  ma_premium_max: number | null;
  medigap_premium_min: number | null;
  medigap_premium_max: number | null;
}

interface ApiResponseQueryObject {
  contract_id: string;
  plan_id: string;
  segment_id: string;
  year: string;
}

export interface ApiResponseServingData {
  query?: ApiResponseQueryObject;
}

export interface AppContextType {
  dispatch: Dispatch<Action | AnalyticsActions>;
  state: AppState;
}
export interface AppState {
  beneficiary?: Beneficiary;
  cachedFlags?: LDFlagSet;
  compareList: SearchResultPlan[];
  county?: County;
  csr?: CSR;
  csrGuestAccess?: boolean;
  currentCoverage?: SearchResultPlan;
  /** coverage wizard */
  cwCoverage?: CoverageType;
  degradedIntegrations: string[];
  /** medigap demographic information (filter) */
  demographicInfo?: DemographicInfo;
  /** enroll data, used when enrolling in a new plan */
  enrollData: EnrollFormData;
  /** array of dismissible alerts */
  dismissedAlerts: DismissibleAlert[];
  fips?: string;
  futureLis?: LowIncomeSubsidyStatus;
  language: UserLanguage;
  lastActivity?: Date;
  lis?: LowIncomeSubsidyStatus;
  mailOrderNetworkStatus?: MailOrderNetworkStatus;
  mbpHandoffFailed: boolean;
  mbpReferrerUri: string;
  multipleRolloverPlans: boolean;
  /** helper object for new to medicare logic for anon users */
  anonNewToMedicareAlertInfo?: NewToMedicareAlertInfo;
  nextYearCoverage?: SearchResultPlan;
  routeParams?: Record<string, string>;
  pharmacies: Pharmacy[];
  pharmacySearchAddress?: AddressTextField;
  pharmacyType: PharmacyType;
  /** search results plan type */
  planType?: PlanType;
  prescriptions: PrescriptionDrug[];
  searchResultsFilters: SearchResultsFilters;
  /**
   * a specific plan, selected during enrollment
   * TODO: Refine type
   * https://jira.cms.gov/browse/MCT-9128
   */
  selectedPlan?: Plan | SearchResultPlan;
  /** Special Needs Plan search filters */
  snpFilters?: PlanSNPType[];
  tealiumLoaded?: boolean;
  trackedEvents: Partial<Record<Ga4Event, Record<string, unknown>>>;
  wizardAnswer?: WizardAnswer;
  year?: string;
  /** zipcode in app state */
  zipcode?: string;
  rolloverPlanStatus?: RolloverPlanStatus;
}

export interface AutocompleteDrug {
  generic: { name: string; rxcui: string } | null;
  insulin: boolean;
  is_generic: boolean;
  name: string;
  rxcui: string;
  pap_drug_id: string;
}

export interface AutocompletePapDrug {
  name: string;
  id: string;
}

export interface AutocompleteDrugResults {
  drugs: AutocompleteDrug[];
}

export interface BasePlan {
  readonly annual_deductible: string | number;
  readonly calculated_monthly_premium: number;
  readonly category: PlanCategoryType;
  readonly contract_id: string;
  readonly contract_year: string;
  readonly drug_plan_deductible: number;
  readonly enrollment_opt_in_status: boolean;
  readonly enrollment_status: PlanEnrollmentStatus;
  readonly high_performing: boolean;
  readonly id: number;
  readonly lis: LIS;
  readonly low_performing: boolean;
  readonly msa_annual_deposit: number;
  readonly name_esp: string;
  /** Name of plan, access with getPlanName() helper */
  readonly name: string;
  readonly organization_name: string;
  // @TODO: Figure out the typing for star ratings: null, undefined, and StarRating?
  readonly overall_star_rating: StarRating;
  readonly package_services: PackageServices | null;
  readonly partb_premium_reduction: number;
  readonly partc_premium: number;
  readonly partd_premium: number;
  readonly plan_id: string;
  readonly plan_type: PlanType;
  readonly redactions: Redaction[];
  readonly segment_id: string;
  readonly silver_sneakers: boolean;
  readonly snp_type: PlanSNPType;
  readonly total_remaining_premium: number;
  readonly url: string;
  readonly worldwide_emergency: boolean;
  readonly aip: boolean | null;
  readonly dsnp_integration_level: "UNSPECIFIED" | "CO" | "HIDE" | "FIDE";
}

export enum NewToMedicareStatus {
  UNDEFINED = "NEW_TO_MEDICARE_UNDEFINED",
  YES = "NEW_TO_MEDICARE_YES",
  NO = "NEW_TO_MEDICARE_NO",
}

export interface BeneMetaData {
  beneficiary_key: string;
  is_csr: boolean;
  is_logged_in: boolean;
  is_synthetic: boolean;
  last_login_date?: string | null;
}

export interface Beneficiary {
  activity_id: string;
  birthdate: string; // YYYY-MM-DD
  csr_id: string;
  coverage_current: ExtendedCoverageInfo[];
  coverage_future: ExtendedCoverageInfo[];
  email: string;
  employer_coverage_current: ExtendedCoverageInfo[];
  employer_coverage_future: ExtendedCoverageInfo[];
  firstname: string[];
  future_lis_level: LowIncomeSubsidyStatus;
  future_lis_status_start_date: string; // YYYY-MM-DD
  gender: Gender;
  hicn: string;
  lastname: string;
  lis_level: LowIncomeSubsidyStatus;
  mailing_address: MailingAddress;
  mbi: string;
  medicare_info: MedicareInfo;
  meta_data: BeneMetaData;
  pdes: PDE[];
  plans: string[];
  request_id: string;
  ssn: string;
  // Deprecated
  /**
   * @deprecated  new_to_medicare attribute has nothing to do with the current NtM logic.
   * It predates it and has a completely different set of criteria for being true
   * */
  new_to_medicare: NewToMedicareStatus;
  /** @deprecated use medicare_info */
  part_a_start: string;
  /** @deprecated use medicare_info */
  part_b_start: string;
}

export interface MedicarePartInfo {
  start_date: string; // YYYY-MM-DD or ""
  stop_date: string; // YYYY-MM-DD or ""
}

// beneinfo/userinfo.go#getMedicareInfo
export interface MedicareInfo {
  new_to_medicare: NewToMedicareStatus;
  // iep = Initial Enrollment Period
  iep_start_date: string; // YYYY-MM-DD or ""
  iep_end_date: string; // YYYY-MM-DD or ""
  early_beneficiary: boolean;
  part_a: MedicarePartInfo;
  part_b: MedicarePartInfo;
}

export interface MailingAddress {
  address_1: string;
  address_2: string;
  address_3: string;
  city: string;
  fips_state_county: string;
  state: string;
  zipcode: string;
}

export interface BeneficiaryAndApiHeaders {
  beneficiary: Beneficiary | undefined;
  headers: Headers;
}

export interface BenefitItemProps {
  benefit: string;
  label: string | JSX.Element;
  mobileLabelOnly?: boolean;
  plan: Plan;
  service?: string;
  suppressed?: boolean;
}

export interface CallbackOutput {
  code: string;
  state: number;
}

export interface ChatInit {
  ActivityId: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  Language: string;
  CurrentView: string; // mct-<current-page-name>
}

export interface ChatLaunchData extends ChatInit {
  ChatAuth: "Yes";
  TextSize: "smallfont";
}

export interface ClientErrorMessage {
  apiError?: string;
  cause: string;
}

export interface CSR {
  activityId: string;
  id: string;
}

export interface County {
  fips: string;
  name: string;
  state: string;
}

export type CoverageInfo = IdParts;

export interface DemographicInfo {
  age?: string;
  gender?: Gender;
  tobacco?: boolean;
  householdDiscount?: HouseholdDiscount;
}

export interface DrugDosage {
  form: string;
  has_duplicate_name: boolean;
  name: string;
  packages: DrugPackage[];
  rxcui: string;
  strength_units: string;
  strength: string;
}

export interface DrugDosageInfo {
  commonDosage: DrugDosage | null;
  dosages: DrugDosage[];
}

export interface DrugCost {
  catastrophic_cost: number | string;
  covered: boolean;
  deductible_cost: number | string;
  frequency: PrescriptionFrequency;
  full_cost: number | string;
  gap_cost: number | string;
  initial_cost: number | string;
  ndc: string;
  quantity: number | string;
  default_price_used: boolean;
  coverage_reason: DrugCostCoverageReason;
  estimated_yearly_total: number;
}

export interface DrugCostInfo {
  costs: DrugCosts[];
  /**
   * @TODO - this should not be flagged as optional after the PR for
   * https://jira.cms.gov/browse/MCT-9791 is merged and in PROD
   * @see https://github.cms.gov/CMS-MCT/coverage-tools-api/pull/2638
   */
  cumulative_meets_annual_cap?: boolean;
  excluded_drugs: string[];
  lowest_mail_total: number | string;
  lowest_retail_total: number | string;
  plan: FullPlanId;
  restrictions: {
    acip: boolean;
    conditions: string[];
    free_first_fill: boolean;
    home_infusion: boolean;
    insulin_price_cap: boolean;
    limited_distribution: boolean;
    ndc: string;
    opioid: boolean;
    partial_gap: boolean;
    vaccine: boolean;
    is_paxlovid?: boolean;
  }[];
}

export enum DrugCostCoverageReason {
  COVERED = "COVERED",
  OUT_OF_NETWORK = "OUT_OF_NETWORK",
  NOT_IN_FORMULARY = "NOT_IN_FORMULARY",
  FREQUENCY_NOT_COVERED = "FREQUENCY_NOT_COVERED",
}

export interface M3PMonthlyCosts {
  /**
   * Date of the first day of each month, returned from MCTAPI in the form of `YYYY-MM-DD`
   * (`DD` is always `01`, but leaving the typing open.)
   *
   * Month needs to be extracted and turned into a translated month name string
   */
  date: `${number}${number}${number}${number}-${number}${number}-${number}${number}`;
  mppp_total: number;
  part_d_only_total: number;
}

export interface MedicarePrescriptionPaymentPlan {
  estimated_monthly_costs: M3PMonthlyCosts[];
  estimated_yearly_mppp_cost: number;
  estimated_yearly_part_d_only_cost: number;
  meets_annual_cap: boolean;
}

/**
 * M3pData is a Record mapping `npi`s to M3P data from MCTAPI
 * For MO, the `npi` is "normalized" to "mail-order"
 */
export type M3pData = Record<string, MedicarePrescriptionPaymentPlan>;

export interface DrugCosts {
  drug_costs: DrugCost[];
  estimated_monthly_costs: EstimatedMonthlyDrugCost[];
  estimated_yearly_total: number;
  in_network: boolean;
  mail_order: boolean;
  medicare_prescription_payment_plan: MedicarePrescriptionPaymentPlan | null;
  npi: string;
  phase_information: DrugPhaseInformation;
  preferred: boolean;
  ltc: boolean;
}

// response from /drugs/cost API
export interface DrugCostsResponse {
  full_year: boolean;
  plans: DrugCostInfo[];
  request_id: string;
}

export enum DrugCoverageFilterOption {
  INCLUDES = "includes",
  EXCLUDES = "excludes",
}

export enum DrugFootnote {
  CONDITION = "condition",
  EXCLUDED_DRUG = "excluded_drug",
  FREE_FIRST_FILL = "free_first_fill",
  HOME_INFUSION = "home_infusion",
  LIMITED_DISTRIBUTION = "limited_distribution",
  OPIOID = "opioid",
  PARTIAL_GAP = "partial_gap",
  VACCINE = "vaccine",
  NOT_IN_FORMULARY = "not_in_formulary",
  DEFAULT_PRICE = "default_price_used",
  FREQUENCY_NOT_COVERED = "frequency_not_covered",
  PAXLOVID = "paxlovid",
}

export interface Biosimilar {
  displayName?: string;
  is_interchangeable: boolean;
  name: string;
  on_formulary: boolean;
  rxcui: string;
}

export interface DrugInfo {
  ndc: string;
  on_formulary?: boolean;
  prior_auth: boolean;
  quantity_limit_amount: number;
  quantity_limit_days: number;
  quantity_limit: boolean;
  step_therapy: boolean;
  tier: number;
  biosimilars: Biosimilar[];
}

export interface DrugPackage {
  default_quantity_30: number;
  default_quantity_90: number;
  ndc: string;
  quantity: number;
  size: number;
  type: string;
  unit: DrugPackageUnit;
  use_quantity: boolean;
  volume: number;
}

export enum DrugPackageUnit {
  "N/A" = "N/A",
  EA = "EA",
  GM = "GM",
  ML = "ML",
}

export interface DrugPhaseInformation {
  catastrophic_start: string;
  deductible_amount: number;
  gap_start: string;
  initial_coverage_start: string;
}

export enum EffectiveSubsidyLevel {
  EFFECTIVE_SUBSIDY_LEVEL_FULL_DUAL = "EFFECTIVE_SUBSIDY_LEVEL_FULL_DUAL",
  EFFECTIVE_SUBSIDY_LEVEL_FULL_SUBSIDY = "EFFECTIVE_SUBSIDY_LEVEL_FULL_SUBSIDY",
  EFFECTIVE_SUBSIDY_LEVEL_NO_SUBSIDY = "EFFECTIVE_SUBSIDY_LEVEL_NO_SUBSIDY",
  EFFECTIVE_SUBSIDY_LEVEL_PARTIAL_DUAL = "EFFECTIVE_SUBSIDY_LEVEL_PARTIAL_DUAL",
  EFFECTIVE_SUBSIDY_LEVEL_PARTIAL_SUBSIDY = "EFFECTIVE_SUBSIDY_LEVEL_PARTIAL_SUBSIDY",
}

export enum EffectiveSubsidyPercent {
  EFFECTIVE_SUBSIDY_PERCENT_100 = "EFFECTIVE_SUBSIDY_PERCENT_100",
  EFFECTIVE_SUBSIDY_PERCENT_25 = "EFFECTIVE_SUBSIDY_PERCENT_25",
  EFFECTIVE_SUBSIDY_PERCENT_50 = "EFFECTIVE_SUBSIDY_PERCENT_50",
  EFFECTIVE_SUBSIDY_PERCENT_75 = "EFFECTIVE_SUBSIDY_PERCENT_75",
}

export enum Race {
  RACE_I_CHOOSE_NOT_TO_ANSWER = "RACE_I_CHOOSE_NOT_TO_ANSWER",
  RACE_BLACK_OR_AFRICAN_AMERICAN = "RACE_BLACK_OR_AFRICAN_AMERICAN",
  RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE = "RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  RACE_ASIAN_INDIAN = "RACE_ASIAN_INDIAN",
  RACE_CHINESE = "RACE_CHINESE",
  RACE_FILIPINO = "RACE_FILIPINO",
  RACE_JAPANESE = "RACE_JAPANESE",
  RACE_KOREAN = "RACE_KOREAN",
  RACE_VIETNAMESE = "RACE_VIETNAMESE",
  RACE_OTHER_ASIAN = "RACE_OTHER_ASIAN",
  RACE_NATIVE_HAWAIIAN = "RACE_NATIVE_HAWAIIAN",
  RACE_SAMOAN = "RACE_SAMOAN",
  RACE_GUAMANIAN_OR_CHAMORRO = "RACE_GUAMANIAN_OR_CHAMORRO",
  RACE_OTHER_PACIFIC_ISLANDER = "RACE_OTHER_PACIFIC_ISLANDER",
  RACE_WHITE = "RACE_WHITE",
}

export enum Ethnicity {
  ETHNICITY_I_CHOOSE_NOT_TO_ANSWER = "ETHNICITY_I_CHOOSE_NOT_TO_ANSWER",
  ETHNICITY_PUERTO_RICAN = "ETHNICITY_PUERTO_RICAN",
  ETHNICITY_ANOTHER_HISPANIC_LATINO_OR_SPANISH_ORIGIN = "ETHNICITY_ANOTHER_HISPANIC_LATINO_OR_SPANISH_ORIGIN",
  ETHNICITY_MEXICAN_OR_MEXICAN_AMERICAN_OR_CHICANO_CHICANA = "ETHNICITY_MEXICAN_OR_MEXICAN_AMERICAN_OR_CHICANO_CHICANA",
  ETHNICITY_CUBAN = "ETHNICITY_CUBAN",
  ETHNICITY_NOT_HISPANIC_OR_LATINO_LATINA_OR_SPANISH_ORIGIN = "ETHNICITY_NOT_HISPANIC_OR_LATINO_LATINA_OR_SPANISH_ORIGIN",
}

export interface EnrollFormData {
  accessibilityFormat?: AccessibilityFormat;
  additionalDrugCoverage: {
    group: string;
    id: string;
    name: string;
  };
  address1: string;
  address2: string;
  agreeToContract?: boolean;
  agreeToInformationRelease?: boolean;
  authorizedRepresentative: {
    name: string;
    relationship: string;
    addressLine1: string;
    city: string;
    state: string;
    zip: string;
    phone: EnrollPhoneNumber;
  };
  beneWorks?: boolean;
  city: string;
  confirmationNumber?: string;
  county: County | undefined;
  differentMailingAddress?: boolean;
  dob: MCTDate;
  email: string;
  emailOptIn?: boolean;
  ethnicities: Ethnicity[];
  firstName: string;
  hasAdditionalDrugCoverage?: boolean;
  hasSepOtherReason: boolean;
  isEnrollee?: boolean;
  language?: PlanMaterialLanguage;
  lastName: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  medicareNumber: string;
  middleInitial: string;
  premiumDeducted?: boolean;
  premiumDirectPay?: boolean;
  premiumWithhold: string;
  primaryCareName: string;
  primaryPhone: EnrollPhoneNumber;
  races: Race[];
  readAndUnderstoodContents?: boolean;
  sepOtherReason: string;
  sepReasons: SepReason[];
  sex: Sex;
  spouseWorks?: boolean;
  ssn: SSN;
  state: string;
  understoodCoverageLossWarning?: boolean;
  zip: string;
}

export interface EnrollDataForAPI {
  accessibility_format: AccessibilityFormat | null;
  agent_id: string | null;
  applicant_info: {
    birthdate: string;
    email_address: string;
    ethnicities: Ethnicity[];
    first_name: string;
    gender: string;
    mbi: string;
    home_address: {
      address_1: string;
      address_2: string;
      city: string;
      state: string;
      zipcode: string;
      county: string;
    };
    language: PlanMaterialLanguage | null;
    last_name: string;
    mail_address: {
      address_1: string;
      address_2: string;
      city: string;
      state: string;
      zipcode: string;
    };
    middle_initial: string;
    phone: string;
    races: Race[];
    ssn: string;
  };
  authorized_rep: {
    name: string;
    address: {
      address_1: string;
      address_2: string;
      city: string;
      state: string;
      zipcode: string;
    };
    phone: string;
    relation: string;
  } | null;
  contract_id: string;
  email_opt_in: boolean;
  is_m3p_eligible: boolean;
  lis_level: string | undefined;
  other_coverage: {
    group: string;
    id: string;
    name: string;
  } | null;
  plan_id: string;
  plan_year: string;
  premium_direct_pay: boolean;
  premium_withhold: string;
  primary_care_physician: string;
  segment_id: string;
  sep_cms_reason_code: string | null;
  sep_reason_codes: SepReasonForApi[] | null;
  spouse_work_status: boolean | null;
  work_status: boolean | null;
}

export enum EnrollFormPlanTypeLabels {
  PLAN_CATEGORY_1876_COST_MA = "enroll_form.cp_ma_type.label",
  PLAN_CATEGORY_1876_COST_PD = "enroll_form.cp_pd_type.label",
  PLAN_CATEGORY_PFFS_MA = "enroll_form.pffs_ma_type.label",
  PLAN_CATEGORY_PFFS_PD = "enroll_form.pffs_pd_type.label",
  PLAN_TYPE_MA = "enroll_form.ma_type.label",
  PLAN_TYPE_MAPD = "enroll_form.mapd_type.label",
  PLAN_TYPE_PDP = "enroll_form.pdp_type.label",
  SNP_TYPE_DUAL_ELIGIBLE = "enroll_form.snp_de_type.label",
}

export enum EnrollFormSNPTypeLabelKeys {
  SNP_TYPE_CHRONIC_OR_DISABLING = "enroll_form.snp_cc_type.label",
  SNP_TYPE_DUAL_ELIGIBLE = "enroll_form.snp_de_type.label",
  SNP_TYPE_INSTITUTIONAL = "enroll_form.snp_i_type.label",
}

export interface EnrollPhoneNumber {
  areaCode: string;
  exchangeCode: string;
  lineNumber: string;
}

export interface EnrollResponse {
  confirmation_number: string;
  status: EnrollResponseCode;
}

export enum EnrollResponseCode {
  OEC_BAD_CONTRACT_ID = "OEC_BAD_CONTRACT_ID",
  OEC_BAD_PLAN_ID = "OEC_BAD_PLAN_ID",
  OEC_BAD_SEGMENT_ID = "OEC_BAD_SEGMENT_ID",
  OEC_DUPLICATE_ENROLLMENT = "OEC_DUPLICATE_ENROLLMENT",
  OEC_FIELD_WRONG_DATA_TYPE = "OEC_FIELD_WRONG_DATA_TYPE",
  OEC_INCORRECT_NUMBER_OF_FIELDS = "OEC_INCORRECT_NUMBER_OF_FIELDS",
  OEC_UNKNOWN = "OEC_UNKNOWN",
  OEC_UNKNOWN_ERROR = "OEC_UNKNOWN_ERROR",
  OEC_VALID = "OEC_VALID",
}

export enum Envs {
  imp = "imp",
  local = "local",
  prod = "prod",
  test = "test",
  dev = "dev",
}

export enum TealiumEnvironment {
  DEV = "dev",
  QA = "qa",
  PROD = "prod",
}

export interface EstimatedMonthlyDrugCost {
  catastrophic: number;
  date: string;
  deductible: number;
  gap: number;
  initial: number;
  total: number;
}

export interface GlobalSessionInfo {
  exp: number;
  maximum_exp: number;
}

export interface ExtendedCoverageInfo extends IdParts {
  coverage_year: string;
  name: string;
  name_esp: string;
  enrollment_effective_date: string;
  contract_effective_date: string;
  disenrollment_date: string;
  plan_type: PlanType;
}

export type FeaturedPlanDetails = {
  plan: SearchResultPlan | OecStatus | ExtendedCoverageInfo | undefined;
  statusMessages: {
    primary: string | JSX.Element | undefined;
    secondary?: string | JSX.Element | undefined;
  };
  coverageInfo?: string | JSX.Element | undefined;
  isCurrentCoverage?: boolean;
};

export type FeaturedPlanAndCoverages = {
  featuredPlanDetails: FeaturedPlanDetails;
  planCardCurrentCoverage: SearchResultPlan | undefined;
  planCardOecStatuses: OecStatuses;
  planCardFuturePlan: ExtendedCoverageInfo | undefined;
};

export enum ThirdPartyIntegrations {
  BEDAP = "BEDAP",
  BLUE_BUTTON = "Blue Button",
}

export interface FullPlanId extends Omit<CoverageInfo, "coverage_year"> {
  contract_year: string;
}

export interface RolloverPlanInfo extends Omit<CoverageInfo, "coverage_year"> {
  contract_year: string;
  status: RolloverPlanStatus;
}

export enum RolloverPlanStatus {
  RENEWAL = "RENEWAL",
  RENEWAL_SAR = "RENEWAL_SAR",
  RENEWAL_SAE = "RENEWAL_SAE",
  NEW = "NEW",
  CONSOLIDATED = "CONSOLIDATED",
  TERMINATED = "TERMINATED",
}

export enum Gender {
  FEMALE = "GENDER_FEMALE",
  MALE = "GENDER_MALE",
  UNKNOWN = "GENDER_NA",
}

export interface IdParts {
  contract_id: string;
  contract_year?: string;
  coverage_year?: string;
  plan_id: string;
  segment_id: string;
}

export interface PlanIdParts extends IdParts {
  contract_year: string;
}

export type IdPartsWithYear = IdParts & {
  year?: string | undefined;
};

export interface Indexable {
  index: number;
}

export interface LIS {
  readonly level_100: number;
  readonly level_25: number;
  readonly level_50: number;
  readonly level_75: number;
}

export enum LowIncomeSubsidyAmount {
  Help0 = "LEVEL_0",
  Help25 = "LEVEL_25",
  Help50 = "LEVEL_50",
  Help75 = "LEVEL_75",
  HelpNA = "LEVEL_NA",
  HelpUnsure = "LEVEL_100",
}

export enum LowIncomeSubsidyOption {
  Medicaid = "LIS_MEDICAID",
  MSP = "LIS_MSP",
  None = "LIS_NONE",
  SS = "LIS_SS",
  SSI = "LIS_SSI",
  Unsure = "LIS_UNSURE",
}

export enum LowIncomeSubsidyStatus {
  LIS_LEVEL_1A = "LIS_LEVEL_1A",
  LIS_LEVEL_1A_DN = "LIS_LEVEL_1A_DN",
  LIS_LEVEL_1B = "LIS_LEVEL_1B",
  LIS_LEVEL_1C = "LIS_LEVEL_1C",
  LIS_LEVEL_2 = "LIS_LEVEL_2",
  LIS_LEVEL_3 = "LIS_LEVEL_3",
  LIS_LEVEL_4_100 = "LIS_LEVEL_4_100",
  LIS_LEVEL_4_25 = "LIS_LEVEL_4_25",
  LIS_LEVEL_4_50 = "LIS_LEVEL_4_50",
  LIS_LEVEL_4_75 = "LIS_LEVEL_4_75",
  LIS_NO_HELP = "LIS_NO_HELP",
}

export interface MailOrderNetworkStatus {
  inNetwork: boolean;
  preferred: boolean;
}

export interface MCTDate {
  day: string;
  month: string;
  year: string;
}

/**
 * A value of `null` is set by a parent component, and indicates to the `MCTDateField`
 * component that the date has been "reset," and this should not be treated as
 * an error (empty date). When `undefined`, depending on the circumstances, the
 * emtpy date may be treated as an error.
 */
export type MCTDateProp = MCTDate | null | undefined;

export type MCTDateInputHandler = ({
  date,
  isValid,
}: {
  date: MCTDate;
  isValid: boolean;
}) => void;

export enum MctEvent {
  costsLoaded = "costs_loaded",
}

export enum MctToastType {
  BENE_FAILED_LOGIN = "bfl",
  CSR_BENE_FAIL = "cbf",
  LOGGED_OUT = "lo",
  SESSION_EXPIRED = "se",
}

export interface MenuItem {
  id?: string;
  onClick?: () => void;
  title: string;
  url?: string;
}

export interface NetworkCostDescriptor {
  readonly authorization_required: boolean;
  readonly cost_share: string;
  readonly plan_limits: boolean;
  readonly referral_required: boolean;
}

export interface NetworkCostDescriptors {
  /* "NETWORK_TYPE_IN_NETWORK" */
  readonly [x: string]: NetworkCostDescriptor;
}

/** Pending Enrollment Status */
export interface OecStatus {
  confirmation_number: string;
  contract_id: string;
  download_date: string;
  downloaded: boolean;
  plan_id: string;
  plan_name_esp: string;
  plan_name: string;
  plan_type: PlanType;
  segment_id: string;
  year: string;
}

/**
 * Pending Enrollment Statuses, created by `usePendingEnrollments`
 * - `undefined` means we have not called the endpoint.
 * - `null` means that we called the endpoint and there was an error or no result.
 * */
export interface OecStatuses {
  currentYear?: OecStatus | null;
  nextYear?: OecStatus | null;
}

export interface OecStatusResponse {
  statuses: OecStatus[];
}

export interface OptionalBenefitPackage {
  benefits: string[];
  readonly deductible: string;
  readonly monthly_premium: string;
  readonly package_number: number;
}

export interface OtherBenefit {
  benefit: string;
  coverage: string;
  info?: {
    authorization_required: boolean;
    coins_max_pct: number | null;
    coins_min_pct: number | null;
    copay_max: number | null;
    copay_min: number | null;
    oon_coins_max_pct: number | null;
    oon_coins_min_pct: number | null;
    oon_copay_max: number | null;
    oon_copay_min: number | null;
    referral_required: boolean;
  };
}

export interface OtherBenefitCategory {
  benefits: OtherBenefit[];
  category: string;
}

export enum PaceDay {
  DAY_SUNDAY = "DAY_SUNDAY",
  DAY_MONDAY = "DAY_MONDAY",
  DAY_TUESDAY = "DAY_TUESDAY",
  DAY_WEDNESDAY = "DAY_WEDNESDAY",
  DAY_THURSDAY = "DAY_THURSDAY",
  DAY_FRIDAY = "DAY_FRIDAY",
  DAY_SATURDAY = "DAY_SATURDAY",
}

export interface PaceOfficeHours {
  close_time: string;
  day: PaceDay;
  open: boolean;
  open_all_day: boolean;
  open_time: string;
  time_zone: PaceTimeZone;
}

export interface PacePhone {
  number: string;
  extension: string;
}

export interface PacePhones {
  local_phone: PacePhone | null;
  local_tty: PacePhone | null;
}

export interface PacePlan {
  contract_id: string;
  contract_year: string;
  office_hours: PaceOfficeHours[];
  organization_name: string;
  plan_id: string;
  plan_name_esp: string;
  plan_name: string;
  prospect_phones: PacePhones;
  segment_id: string;
  state: string;
  website: string;
}

export enum PaceTimeZone {
  TIME_ZONE_ALASKA = "TIME_ZONE_ALASKA",
  TIME_ZONE_CENTRAL = "TIME_ZONE_CENTRAL",
  TIME_ZONE_EASTERN = "TIME_ZONE_EASTERN",
  TIME_ZONE_HAWAII = "TIME_ZONE_HAWAII",
  TIME_ZONE_MOUNTAIN = "TIME_ZONE_MOUNTAIN",
  TIME_ZONE_PACIFIC = "TIME_ZONE_PACIFIC",
  TIME_ZONE_SAMOA = "TIME_ZONE_SAMOA",
}

export interface PapPlan {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone1: string;
  phone2: string;
  url: string;
  program_name: string;
  org_name: string;
  eligibility: string[];
  eligibility_spanish: string[];
}

export interface SpapPlan {
  phone1: string;
  program_name: string;
  state: string;
  url: string;
  restrictions: {
    income: boolean;
    age: boolean;
    asset: boolean;
    lis: boolean;
    part_d_enrollment: boolean;
    limited_pharmacies: boolean;
    other_factors: boolean;
    hiv_aids: boolean;
  };
}

export enum SupplementalBenefitCategoryTypeNames {
  SB_CAT_WORLDWIDE_EMERGENCY_URGENT_COVERAGE = "SB_CAT_WORLDWIDE_EMERGENCY_URGENT_COVERAGE",
  SB_CAT_CHIROPRACTIC_SERVICES = "SB_CAT_CHIROPRACTIC_SERVICES",
  SB_CAT_ADDITIONAL_TELEHEALTH_SERVICES = "SB_CAT_ADDITIONAL_TELEHEALTH_SERVICES",
  SB_CAT_OPIOID_TREATMENT_SERVICES = "SB_CAT_OPIOID_TREATMENT_SERVICES",
  SB_CAT_TRANSPORTATION_SERVICES = "SB_CAT_TRANSPORTATION_SERVICES",
  SB_CAT_OTC_ITEMS = "SB_CAT_OTC_ITEMS",
  SB_CAT_MEAL_BENEFIT = "SB_CAT_MEAL_BENEFIT",
  SB_CAT_ANNUAL_PHYSICAL_EXAM = "SB_CAT_ANNUAL_PHYSICAL_EXAM",
  SB_CAT_DEFINED_SUPPLEMENTAL_BENEFITS = "SB_CAT_DEFINED_SUPPLEMENTAL_BENEFITS",
  SB_CAT_REDUCED_COST_SHARING_FOR_VBID_UF_SSBCI = "SB_CAT_REDUCED_COST_SHARING_FOR_VBID_UF_SSBCI",
  SB_CAT_NON_PRIMARILY_HEALTH_RELATED_BENEFITS = "SB_CAT_NON_PRIMARILY_HEALTH_RELATED_BENEFITS",
  SB_CAT_HOME_HEALTH_SERVICES = "SB_CAT_HOME_HEALTH_SERVICES",
  SB_CAT_PROSTHETICS_MEDICAL_SUPPLIES = "SB_CAT_PROSTHETICS_MEDICAL_SUPPLIES",
  SB_CAT_ADDITIONAL_SERVICES = "SB_CAT_ADDITIONAL_SERVICES",
  SB_CAT_NON_OPIOID_PAIN_MANAGEMENT = "SB_CAT_NON_OPIOID_PAIN_MANAGEMENT",
  SB_CAT_DIALYSIS_SERVICES = "SB_CAT_DIALYSIS_SERVICES",
}

/**
 * BenefitData represents a benefit in plan_card.package_benefits
 */
export interface BenefitData {
  label: string;
  service: string;
}

/**
 * MaBenefitData represents a benefit in plan_card.ma_benefits
 */
export interface MaBenefitData extends BenefitData {
  category: string;
}

export interface PackageBenefit {
  readonly benefit_services: {
    readonly [x: string]: /* "SERVICE_PRIMARY_OR_SUCH" */ {
      readonly network_costs: NetworkCostDescriptors;
    };
  };
  readonly network_costs: NetworkCostDescriptors;
}

export interface PackageBenefits {
  readonly [x: string]: PackageBenefit;
}

export enum PackageServiceAvailability {
  ANY_DOCTOR = "AVAILABILITY_ANY_DOCTOR",
  ANY_WILLING_DOCTOR = "AVAILABILITY_ANY_WILLING_DOCTOR",
  INFORMATION_BEING_UPDATED = "AVAILABILITY_INFORMATION_BEING_UPDATED",
  INFORMATION_NOT_AVAILABLE = "AVAILABILITY_INFORMATION_NOT_AVAILABLE",
  NOT_PROVIDED = "AVAILABILITY_NOT_PROVIDED",
  NOT_SPECIFIED = "AVAILABILITY_NOT_SPECIFIED",
  PLAN_DOCTORS = "AVAILABILITY_PLAN_DOCTORS",
  PLAN_DOCTORS_MOST_SERVICES = "AVAILABILITY_PLAN_DOCTORS_MOST_SERVICES",
  PLAN_DOCTORS_SOME_SERVICES = "AVAILABILITY_PLAN_DOCTORS_SOME_SERVICES",
  PLAN_DOCTORS_WITH_EXCEPTIONS = "AVAILABILITY_PLAN_DOCTORS_WITH_EXCEPTIONS",
  PROVIDED = "AVAILABILITY_PROVIDED",
  PROVIDED_EXTRA_COST = "AVAILABILITY_PROVIDED_EXTRA_COST",
}

export interface PackageServices {
  readonly additional_physical_exams: PackageServiceAvailability;
  readonly dental_services: PackageServiceAvailability;
  readonly doctor_choice: PackageServiceAvailability;
  readonly hearing_services: PackageServiceAvailability;
  readonly monthly_plan_premium?: PackageServiceAvailability;
  readonly outpatient_prescription: PackageServiceAvailability;
  readonly vision_services: PackageServiceAvailability;
  readonly ms_hearing_services: boolean;
  readonly ms_dental_services: boolean;
}

export interface PDE {
  drug: PrescriptionDrug;
  pde_status: string;
}

export type DrivingDistanceUnit = "miles" | "feet";

export interface DrivingDistanceInfo {
  driving_distance: number | null;
  driving_distance_units: DrivingDistanceUnit | null;
}
export interface Pharmacy extends DrivingDistanceInfo {
  city: string;
  distance_miles?: number;
  in_network?: boolean;
  name: string;
  npi: string;
  phone: string;
  preferred?: boolean;
  state: string;
  street: string;
  unit: string;
  zipcode: string;
}
export interface PharmacyResultsType {
  sourceAddress: PharmacyGeoPoint;
  pharmacies: PharmacyGeoPoints;
  geoError: string;
  totalResults: number;
}
export interface PharmacyInfo extends DrivingDistanceInfo {
  npi: string;
  name: string;
  in_network: boolean;
  mail_order: boolean;
  preferred: boolean;
  ltc: boolean;
  formatted_yearly_drug_costs?: string;
}

export enum PharmacyFrequencies {
  days_30 = "days_30",
  days_90 = "days_90",
  days_any = "days_any",
}

export enum PharmacyPreferences {
  general = "general",
  preferred_mail_order = "preferred_mail_order",
  preferred_retail = "preferred_retail",
  standard_mail_order = "standard_mail_order",
  standard_retail = "standard_retail",
}

export enum PharmacyPreferencesAndFrequencies {
  PHARM_PRFD_FREQ_30_DAYS = "PHARM_PRFD_FREQ_30_DAYS",
  PHARM_PRFD_FREQ_90_DAYS = "PHARM_PRFD_FREQ_90_DAYS",
  PHARM_STD_FREQ_30_DAYS = "PHARM_STD_FREQ_30_DAYS",
  PHARM_STD_FREQ_90_DAYS = "PHARM_STD_FREQ_90_DAYS",
  PHARM_MAIL_PRFD_FREQ_30_DAYS = "PHARM_MAIL_PRFD_FREQ_30_DAYS",
  PHARM_MAIL_PRFD_FREQ_90_DAYS = "PHARM_MAIL_PRFD_FREQ_90_DAYS",
  PHARM_MAIL_STD_FREQ_30_DAYS = "PHARM_MAIL_STD_FREQ_30_DAYS",
  PHARM_MAIL_STD_FREQ_90_DAYS = "PHARM_MAIL_STD_FREQ_90_DAYS",
}

export interface PharmacyGeoPoint {
  type: "Feature";
  /**
   * @deprecated
   * deprecated tag has been added to call out an inconsistency in our typing.
   * In the file FormattedResultsMessage, this should actually be typed as
   * `Pick<Pharmacy, "city" | "state" | "street" | "zipcode">`
   * Until this inconsistency has been addressed, use this with caution.
   * https://jira.cms.gov/browse/MCT-9957
   * */
  properties: Pharmacy;
  geometry: Point;
}

export interface PharmacyGeoPoints {
  type: "FeatureCollection";
  features: PharmacyGeoPoint[];
}

export interface PharmaciesResponse {
  geocode_error: string;
  pharmacies: PharmacyGeoPoints;
  request_id: string;
  source_address: PharmacyGeoPoint;
  source_location_type: string;
  total_results: number;
}

export interface InNetworkPharmaciesResponse extends PharmaciesResponse {
  mail_order_network: boolean;
  mail_order_preferred: boolean;
}

export enum PharmacyType {
  MAIL = "mail",
  MAIL_AND_RETAIL = "both",
  NONE = "",
  RETAIL = "retail",
}

export interface PhoneNumber {
  readonly extension: string;
  readonly number: string;
}

export enum PlanInfoCostShareNetworkStatus {
  IN_NETWORK = "IN_NETWORK",
  OUT_OF_NETWORK = "OUT_OF_NETWORK",
}

export interface PlanInfoCostShare {
  // values are from here:
  // https://github.cms.gov/CMS-MCT/coverage-tools-api/blob/e2665f2b5ed812972316b0b43023aa6fe93acfff/planinfo/db/schema/101_schema.sql#L212-L215
  readonly network_status: string;
  readonly min_copay: number | null;
  readonly max_copay: number | null;
  readonly min_coinsurance: number | null;
  readonly max_coinsurance: number | null;
}

/**
 * plan benefit categories
 * This enum is incomplete, and is currently only defining
 * the hearing and dental categories that we are using on the frontend.
 * */
export enum PlanBenefitCategory {
  HearingAids = "HEARING_AIDS",
  HearingExams = "HEARING_EXAMS",
  DentalPreventive = "BENEFIT_PREVENTIVE_DENTAL",
  DentalComprehensive = "BENEFIT_COMPREHENSIVE_DENTAL",
}

/** `service` options for category "HEARING_AIDS", from `plan_card.ma_benefits` */
export enum HearingAidsService {
  All = "ALL_HEARING_AIDS",
  RX = "RX_HEARING_AIDS",
  OTC = "OTC_HEARING_AIDS",
}
/** `service` options for category "HEARING_EXAM" from `plan_card.ma_benefits` */
export enum HearingExamService {
  FittingEvaluation = "FITTING_EVALUATION_HEARING_AIDS",
  Routine = "ROUTINE_HEARING_EXAMS",
}

/** `service` options for category "BENEFIT_PREVENTIVE_DENTAL" from `plan_card.ma_benefits` */

export enum DentalPreventiveService {
  OralExam = "SERVICE_ORAL_EXAM",
  Cleaning = "PROPHYLAXIS",
  FluorideTreatment = "SERVICE_FLUORIDE_TREATMENT",
  XRays = "SERVICE_DENTAL_XRAYS",
}

/** `service` options for category "BENEFIT_COMPREHENSIVE_DENTAL" from `plan_card.ma_benefits` */

export enum DentalComprehensiveService {
  RestorativeServices = "SERVICE_RESTORATIVE_SERVICES",
  Endodontics = "SERVICE_ENDODONTICS",
  Periodontics = "SERVICE_PERIODONTICS",
  ProsthodonticsRemovable = "PROSTHODONTICS_REMOVABLE",
  ProsthodonticsFixed = "PROSTHODONTICS_FIXED",
  MaxillofacialProsthetics = "MAXILLOFACIAL_PROSTHETICS",
  ImplantServices = "IMPLANT_SERVICES",
  OralMaxillofacialSurgery = "ORAL_AND_MAXILLOFACIAL_SURGERY",
  Orthodontics = "ORTHODONTICS",
  AdjunctiveGeneralServices = "ADJUNCTIVE_GENERAL_SERVICES",
}

export enum MABenefitType {
  MedicareCovered = "MEDICARE_COVERED",
  OptionalSupplemental = "OPTIONAL_SUPPLEMENTAL",
  MandatorySupplemental = "MANDATORY_SUPPLEMENTAL",
}

/** `ma_benefits` on the Plan object
 * see definition here https://test.medicare.gov/plan-compare/docs/#/Planinfo/Planinfo_Plan
 * under `planinfoBenefit`
 */
export interface MAPlanInfoBenefit {
  readonly category: string;
  readonly type: MABenefitType;
  readonly authorization_required: boolean;
  readonly referral_required: boolean;
  readonly plan_limits: boolean;
  readonly service: string;
  readonly cost_sharing: PlanInfoCostShare[];
}

export interface Plan extends BasePlan {
  readonly abstract_benefits?: AbstractBenefits;
  readonly additional_supplemental_benefits: AdditionalSupplementalBenefits | null;
  readonly address_1: string;
  readonly address_2: string;
  readonly city: string;
  readonly member_phone: PhoneNumber | null;
  readonly optional_benefits: OptionalBenefitPackage[];
  readonly package_benefits: PackageBenefits;
  readonly prospect_phone: PhoneNumber | null;
  readonly star_ratings: StarRating[];
  readonly state: string;
  readonly zipcode: string;
  readonly provider_network_web_address: string;
  readonly non_zero_dollar_dsnp: boolean;
  // Medicare Advantage Benefits
  readonly ma_benefits: MAPlanInfoBenefit[];
}

export enum PlanCategoryType {
  CHRONIC_CARE = "PLAN_CATEGORY_CHRONIC_CARE",
  COST_1876 = "PLAN_CATEGORY_1876_COST",
  DEMONSTRATION_SNP = "PLAN_CATEGORY_DEMONSTRATION_SNP",
  EMPLOYER_PDP = "PLAN_CATEGORY_EMPLOYER_PDP",
  EMPLOYER_PFFS = "PLAN_CATEGORY_EMPLOYER_PFFS",
  EMPLOYER_PPO = "PLAN_CATEGORY_EMPLOYER_PPO",
  FALLBACK = "PLAN_CATEGORY_FALLBACK",
  HCPP_1833_COST = "PLAN_CATEGORY_HCPP_1833_COST",
  HMO = "PLAN_CATEGORY_HMO",
  HMOPOS = "PLAN_CATEGORY_HMOPOS",
  LOCAL_PPO = "PLAN_CATEGORY_LOCAL_PPO",
  MEDICARE_MEDICAID_PLAN = "PLAN_CATEGORY_MEDICARE_MEDICAID_PLAN",
  MEDICARE_PDP = "PLAN_CATEGORY_MEDICARE_PDP",
  MSA = "PLAN_CATEGORY_MSA",
  NA = "PLAN_CATEGORY_NA",
  NATIONAL_PACE = "PLAN_CATEGORY_NATIONAL_PACE",
  ORIGINAL_MEDICARE = "PLAN_CATEGORY_ORIGINAL_MEDICARE",
  PFFS = "PLAN_CATEGORY_PFFS",
  POS_CONTRACTOR = "PLAN_CATEGORY_POS_CONTRACTOR",
  PSO = "PLAN_CATEGORY_PSO",
  REGIONAL_PPO = "PLAN_CATEGORY_REGIONAL_PPO",
  RFB_HMO = "PLAN_CATEGORY_RFB_HMO",
  RFB_HMOPOS = "PLAN_CATEGORY_RFB_HMOPOS",
  RFB_LOCAL_PPO = "PLAN_CATEGORY_RFB_LOCAL_PPO",
  RFB_PFFS = "PLAN_CATEGORY_RFB_PFFS",
  RFB_PSO = "PLAN_CATEGORY_RFB_PSO",
  UNKNOWN = "PLAN_CATEGORY_UNKNOWN",
}

export interface PlanDataVersion {
  file: string;
  sha1_hash: string;
  file_date: string;
  metadata: string;
}

export enum PlanDetailsPageSection {
  OVERVIEW = "overview",
  BENEFITS_AND_COSTS = "benefits",
  DRUG_COVERAGE = "drug-coverage",
  EXTRA_BENEFITS = "extra-benefits",
  OPTIONAL_PACKAGES = "optional-packages",
  STAR_RATINGS = "star-ratings",
}

export enum PlanEnrollmentStatus {
  AVAILABLE = "ENROLLMENT_AVAILABLE",
  CONTACT = "ENROLLMENT_CONTACT_PLAN",
  NOT_NOW = "ENROLLMENT_NOT_AT_THIS_TIME",
}

export enum PlanMaterialLanguage {
  DEFAULT = "",
  SPANISH = "Spanish",
  OTHER = "Other",
}

export type PlanOrSRP = Plan | SearchResultPlan;

export interface PlanResults {
  readonly plans: SearchResultPlan[];
  readonly request_id: string;
  readonly search_summary: PlanResultsSearchSummary;
  readonly total_results: number;
}

export interface PlanResultsSearchSummary {
  categories: PlanCategoryFilters;
  dental: number;
  drugs: number;
  excluded: number;
  hearing: number;
  national: number;
  no_drugs: number;
  organization_names: string[];
  sanctioned: number;
  senior_savings_model: number;
  vision: number;
}

export interface PlanCategoryFilters {
  hmo: number;
  ppo: number;
  pffs: number;
  msa: number;
  cost: number;
}

export interface PlanSearch {
  [s: string]: unknown;
  readonly fips?: string;
  readonly title?: string;
  readonly year: string;
}

export enum PlanSNPType {
  SNP_TYPE_CHRONIC_OR_DISABLING = "SNP_TYPE_CHRONIC_OR_DISABLING",
  SNP_TYPE_DUAL_ELIGIBLE = "SNP_TYPE_DUAL_ELIGIBLE",
  SNP_TYPE_INSTITUTIONAL = "SNP_TYPE_INSTITUTIONAL",
  SNP_TYPE_NOT_SNP = "SNP_TYPE_NOT_SNP",
}

export enum PlanCardSNPTypeLabelKeys {
  SNP_TYPE_CHRONIC_OR_DISABLING = "plan_card.snp_type_chronic_condition",
  SNP_TYPE_DUAL_ELIGIBLE = "plan_card.snp_type_dual_eligible",
  SNP_TYPE_INSTITUTIONAL = "plan_card.snp_type_institutional",
}

export enum PlanType {
  /** Medicare Advantage */
  MA = "PLAN_TYPE_MA",
  /** Medicare Advantage (MA) Plan that offers Medicare Part D (Prescription Drug Coverage) */
  MAPD = "PLAN_TYPE_MAPD",
  /** Medigap */
  MEDIGAP = "PLAN_TYPE_MEDIGAP",
  /** Prescription Drug Coverage */
  PDP = "PLAN_TYPE_PDP",
}

export enum PlanTypeLabels {
  "PLAN_TYPE_MA" = "medicare_advantage.label",
  "PLAN_TYPE_MAPD" = "medicare_advantage.label",
  "PLAN_TYPE_PDP" = "prescription_drug_plan.label",
}

export enum PlanDataStatus {
  None = "none",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export type PlanDataState = {
  status: PlanDataStatus;
  plan: Plan | undefined;
  error?: ApiError;
};

export interface PrescriptionDrug {
  drug_pap_id: string;
  form: string;
  frequency: PrescriptionFrequency;
  insulin: boolean;
  is_generic: boolean;
  name: string;
  ndc: string;
  package_quantity: string | number;
  package_size: string | number;
  package_type: string;
  package_units: string;
  quantity: number | string;
  rxcui: string;
  strength_units: string;
  strength: string;
  use_package_quantity: boolean;
}

export enum PrescriptionFrequency {
  DAYS_30 = "FREQUENCY_30_DAYS",
  DAYS_60 = "FREQUENCY_60_DAYS",
  DAYS_90 = "FREQUENCY_90_DAYS",
  DAYS_180 = "FREQUENCY_180_DAYS",
  DAYS_360 = "FREQUENCY_360_DAYS",
}

export interface DrugCostPrescriptionInfo {
  ndc: string;
  quantity: string;
  frequency: PrescriptionFrequency;
}

export enum DrugPricingRedactionIssue {
  formulary = "formulary",
  formulary_review = "frmlry_revw",
  partd = "partd",
  pharmacy = "pharmacy",
}

export enum PlanBenefitRedactionIssue {
  bid = "bid",
  contract = "contract",
  "ma-network" = "ma-network",
  pln_corrctn = "pln_corrctn",
  other = "other",
}

export enum OtherRedactionIssue {
  enr_sanction = "enr_sanction",
  frzn_enrlmnt = "frzn_enrlmnt",
  cntrct_trmtn = "cntrct_trmtn",
  d_snp = "d_snp",
}

export enum RedactionType {
  suppression = "suppression",
  exclusion = "exclusion",
  frozen = "frozen",
  sanction = "sanction",
}

export interface Redaction {
  readonly issue:
    | keyof typeof OtherRedactionIssue
    | keyof typeof PlanBenefitRedactionIssue
    | `${DrugPricingRedactionIssue}`;
  readonly type: keyof typeof RedactionType;
  readonly url: string;
}

export interface SearchResultPlan extends BasePlan {
  readonly annual_drugs_total: number;
  readonly covered_drugs: number;
  readonly does_not_support_mail_order: boolean;
  readonly emergency_care_cost: string;
  readonly emergency_response_device: boolean;
  readonly home_safety_devices: boolean;
  readonly in_home_support: boolean;
  readonly maximum_oopc: string;
  readonly otc_drugs: boolean;
  readonly pharmacies: SearchResultPlanPharmacy[];
  readonly primary_doctor_visit_cost: string;
  readonly remaining_premium_and_drugs: number;
  readonly specialist_doctor_visit_cost: string;
  readonly telehealth: boolean;
  readonly remaining_premium_and_drugs_mail_order: number;
  readonly remaining_premium_and_drugs_retail: number;
  readonly transportation: boolean;
  readonly worldwide_emergency: boolean;
}

export interface SearchResultPlanPharmacy {
  readonly in_network: boolean;
  readonly npi: string;
}

export interface SearchResultsFilters {
  drugCoverageFilter?: DrugCoverageFilterOption;
  insuranceCarrierFilter: string;
  insuranceCarriers: string[];
  planCategoriesFilter: string[];
  planCoveragesFilter: string[];
  sortBy?: SortOptions;
  starRatingFilter: string;
}

export interface SepReason {
  code: SepReasonCode;
  date?: MCTDate;
  dateError?: string;
  key: string;
  requiresDate: boolean;
}

export interface SepReasonForApi {
  code: string;
  date: string;
}

export enum Sex {
  FEMALE = "female",
  MALE = "male",
  NONE = "",
}

export enum SortOptions {
  ANNUAL_DEDUCTIBLE = "ANNUAL_DEDUCTIBLE",
  ANNUAL_DRUGS_TOTAL = "ANNUAL_DRUGS_TOTAL",
  ANNUAL_TOTAL = "ANNUAL_TOTAL",
  DRUG_PLAN_DEDUCTIBLE = "DRUG_PLAN_DEDUCTIBLE",
  MONTHLY_PREMIUM = "MONTHLY_PREMIUM",
  /**
   * NOT used for sorting (obviously). Passed to `PlanCard` from `PlanPreviewPage`,
   * which displays a single card above plan details, and therefore has no
   * sort order
   */
  NONE = "NONE",
}
export interface StarRating {
  category: string;
  error: StarRatingError;
  rating: number;
}

export enum StarRatingError {
  STAR_ERROR_NO_DATA = "STAR_ERROR_NO_DATA",
  STAR_ERROR_NO_ERROR = "STAR_ERROR_NO_ERROR",
  STAR_ERROR_NOT_ENOUGH_DATA = "STAR_ERROR_NOT_ENOUGH_DATA",
  STAR_ERROR_NOT_OFFERED = "STAR_ERROR_NOT_OFFERED",
  STAR_ERROR_NOT_REQUIRED = "STAR_ERROR_NOT_REQUIRED",
  STAR_ERROR_PLAN_TOO_NEW = "STAR_ERROR_PLAN_TOO_NEW",
  STAR_ERROR_PLAN_TOO_SMALL = "STAR_ERROR_PLAN_TOO_SMALL",
  STAR_ERROR_NOT_REQUIRED_TO_REPORT = "STAR_ERROR_NOT_REQUIRED_TO_REPORT",
}

export interface StringMap {
  [s: string]: string;
}

export enum Title {
  MISS = "Miss",
  MR = "Mr.",
  MRS = "Mrs.",
  MS = "Ms.",
  NONE = "",
}

export enum GetBenefitsFrom {
  WITHHOLD_NONE = "WITHHOLD_NONE",
  RRB = "WITHHOLD_RRB",
  SSI = "WITHHOLD_SSI",
}

export enum UserLanguage {
  ENGLISH = "en",
  SPANISH = "es",
}

export enum QueryStringLanguage {
  ENGLISH = "en-us",
  SPANISH = "es-mx",
}

export interface UtagModule {
  link: (obj: UtagEvent) => void;
}

export interface UtagEvent {
  event_action: string;
  event_category: "MCT";
  event_label: string;
}

export interface Window {
  PHRASEAPP_ENABLED: boolean;
}

export interface LocationWithNextRouteInState {
  nextRoute?: H.LocationDescriptorObject;
}

export type LocationWithOnlyPathnameRequired = Partial<
  RouteComponentProps["location"]
> &
  Pick<RouteComponentProps["location"], "pathname">;

export type LocationWithOptionalState = Omit<
  RouteComponentProps["location"],
  "state"
> &
  Partial<Pick<RouteComponentProps["location"], "state">>;

export enum MBPTargets {
  ENROLL_STATUS = "ENROLL_STATUS",
  GATEWAY = "GATEWAY",
  PLAN = "PLAN",
  PRESCRIPTIONS = "PRESCRIPTIONS",
  STAR_RATINGS = "STAR_RATINGS",
  PHARMACIES = "PHARMACIES",
}

export interface MBPLandingParams {
  code?: string;
  req_token?: string;
  target?: string;
  planYear?: string;
  contractId?: string;
  planId?: string;
  segmentId?: string;
  fips?: string;
  plan_type?: string;
  zipCode?: string | number;
  lang?: string;
  relay?: string;
  mbp_redirect?: string;
}

/**
 * @deprecated
 * Prefer `useTypedTranslate` and `UseTranslateTypeStrict` until uses of the
 * original hook and type are all replaced.
 * Then `useTypedTranslate` can be renamed `useTranslate` and
 * `UseTranslateTypeStrict` can be renamed `UseTranslateType`
 */
export type UseTranslateType = (id: TranslationKey | string) => string;
/** The signature of `useTypedTranslate` */
export type UseTranslateTypeStrict = (id: TranslationKey) => string;

export enum NewRelicPageActionNames {
  LOGOUT = "Logout",
  SEARCH_RESULTS_FILTER = "Search Results Filter",
}

export interface NewRelicPageActionLogoutValues
  extends Record<string, newrelic.SimpleType> {
  activityId: string;
  beneficiaryKey: string;
  csrId: string;
  currentTimeUtc: string;
  forceLogoutModalShown: string;
  forceLogoutTimeoutMs: string;
  hasSessionAndBene: string;
  slsSessionExpired: string;
  lastActivityUtc: string;
}

export interface NewRelicPageActionSearchResultsFilterValues
  extends Record<string, newrelic.SimpleType> {
  filter: string;
  value: string;
}

export interface NewRelicPageActionValuesMap
  extends Record<NewRelicPageActionNames, Record<string, newrelic.SimpleType>> {
  [NewRelicPageActionNames.LOGOUT]: NewRelicPageActionLogoutValues;
  [NewRelicPageActionNames.SEARCH_RESULTS_FILTER]: NewRelicPageActionSearchResultsFilterValues;
}

export interface NewRelicPageActionInterface<T> {
  addNewRelicPageAction<K extends keyof T>(key: K, value: T[K]): void;
}

export interface PlanID {
  contract_id: string;
  plan_id: string;
  segment_id: string;
  contract_year: string;
}
export interface PlanPreferredPharmacyInfo {
  plan: PlanID;
  retail_preferred: number;
  mail_preferred: number;
}

// Initial Enrollment Period Status
export enum IEPStatus {
  /**
   * 65 - 4 month
   */
  IEP_STATUS_BEFORE_IEP = "IEP_STATUS_BEFORE_IEP",
  /**
   * 65 +- 3 month
   */
  IEP_STATUS_WITHIN_IEP = "IEP_STATUS_WITHIN_IEP",
  /**
   * 65 + 4 month
   */
  IEP_STATUS_PAST_IEP = "IEP_STATUS_PAST_IEP",
}

export enum NewToMedicareResult {
  /**
   * A. Ready to join plans
   */
  NEW_TO_MEDICARE_RESULT_READY_TO_JOIN = "A_READY_TO_JOIN",
  /**
   * B. Join plan within 63 days of retiring
   */
  NEW_TO_MEDICARE_RESULT_JOIN_WITHIN_DAYS_OF_RETIRING = "B_JOIN_WITHIN_DAYS_OF_RETIRING",
  /**
   * C. You may have a penalty
   */
  NEW_TO_MEDICARE_RESULT_MAY_HAVE_PENALTY = "C_MAY_HAVE_PENALTY",
  /**
   * D. Join plan during disability IEP
   */
  NEW_TO_MEDICARE_RESULT_JOIN_DURING_DISABILITY_IEP = "D_JOIN_DURING_DISABILITY_IEP",
  /**
   * E. Wait until you are 65
   */
  NEW_TO_MEDICARE_RESULT_WAIT_UNTIL_65 = "E_WAIT_UNTIL_65",
  /**
   * F. Late. Get A/B and come back to join plan ASAP
   */
  NEW_TO_MEDICARE_RESULT_LATE = "F_LATE",
  /**
   * G. Go get A/B now and mind your IEP and SEP
   */
  NEW_TO_MEDICARE_RESULT_GET_AB = "G_GET_AB",
}

export interface NewToMedicareAlertInfo {
  /** from wizard or bene.birthdate */
  birthdate: Date | undefined;
  /** from wizard or bene.medicare_info */
  iepStatus: IEPStatus | undefined;
  /** from wizard
   * @warning this currently only applies to anonymous benes.
   * */
  newToMedicareResult: NewToMedicareResult | undefined;
  /** from bene.coverage_current or bene.coverage_future */
  hasMedigap: boolean;
  /** from bene.coverage_current or bene.coverage_future */
  hasPDPPlan: boolean;
  /** from bene.coverage_current or bene.coverage_future */
  hasMAPDPlan: boolean;
  /** from bene.medicare_info */
  hasPartAandB: boolean;
}

export interface WizardAnswer {
  hasMedicareNumber?: boolean;
  dob?: MCTDateProp;
  validDob?: boolean;
  iepStartDate?: Date;
  iepEndDate?: Date;
  iepStatus?: IEPStatus;
  olderThan65?: boolean;
  hasCreditableCoverage?: boolean;
  coverageEndingSoon?: boolean;
  hasDisability?: boolean;
}

/** ----- Other Insurances / Medigap ----- */

export enum BeneInfoOtherInsuranceCobaTypeFilter {
  COBA_TYPE_FILTER_ALL = "COBA_TYPE_FILTER_ALL",
  COBA_TYPE_FILTER_SUPPLEMENTAL = "COBA_TYPE_FILTER_SUPPLEMENTAL",
  COBA_TYPE_FILTER_MEDIGAP = "COBA_TYPE_FILTER_MEDIGAP",
  COBA_TYPE_FILTER_TRICARE = "COBA_TYPE_FILTER_TRICARE",
  COBA_TYPE_FILTER_MEDICAID = "COBA_TYPE_FILTER_MEDICAID",
  COBA_TYPE_FILTER_CHAMPVA = "COBA_TYPE_FILTER_CHAMPVA",
}

export enum BeneInfoOtherInsuranceStatusFilter {
  OTHER_INSURANCE_STATUS_ALL = "OTHER_INSURANCE_STATUS_ALL",
  OTHER_INSURANCE_STATUS_FUTURE = "OTHER_INSURANCE_STATUS_FUTURE",
  OTHER_INSURANCE_STATUS_CURRENT = "OTHER_INSURANCE_STATUS_CURRENT",
  OTHER_INSURANCE_STATUS_PAST = "OTHER_INSURANCE_STATUS_PAST",
}

export type BeneInfoCobaType =
  | "COBA_TYPE_OTHER"
  | "COBA_TYPE_SUPPLEMENTAL"
  | "COBA_TYPE_MEDIGAP"
  | "COBA_TYPE_TRICARE"
  | "COBA_TYPE_MEDICAID"
  | "COBA_TYPE_CHAMPVA";

export type BeneInfoOtherInsurance = {
  contractor_number: string;
  insurer_name: string;
  insurer_address_line_1: string;
  insurer_address_line_2: string;
  insurer_city: string;
  insurer_state: string;
  insurer_zip: string;
  policy_number: string;
  effective_date: string;
  termination_date: string;
  coba_number: number;
  coba_type: BeneInfoCobaType;
  plan_id: string;
  insurer_code: string;
};

/** Refine BeneInfoOtherInsurance for specific `coba_type` */
type MapMedicareCoverageType<
  T extends BeneInfoOtherInsurance,
  CobaType extends BeneInfoCobaType
> = {
  [P in keyof T]: T[P] extends BeneInfoCobaType
    ? Extract<BeneInfoCobaType, CobaType>
    : T[P];
};

export type MedigapCoverage = MapMedicareCoverageType<
  BeneInfoOtherInsurance,
  "COBA_TYPE_MEDIGAP"
>;

export type BeneInfoOtherInsuranceInfoParams = {
  coba_type?: BeneInfoOtherInsuranceCobaTypeFilter;
  status?: BeneInfoOtherInsuranceStatusFilter;
};

export type BeneInfoOtherInsuranceResponseType = {
  other_insurances: BeneInfoOtherInsurance[];
};

/** Refine BeneInfoOtherInsuranceResponseType for specific `coba_type` */
type MapOtherInsuranceResponseType<
  T extends BeneInfoOtherInsuranceResponseType,
  InsuranceType extends BeneInfoOtherInsurance
> = {
  [P in keyof T]: InsuranceType[];
};

export type MedigapCoveragesResponseType = MapOtherInsuranceResponseType<
  BeneInfoOtherInsuranceResponseType,
  MedigapCoverage
>;

export enum PlanCoverageStatus {
  Current,
  Future,
  Pending,
}

export enum CoverageType {
  MAPD = "MEDICARE_ADVANTAGE_PLAN",
  PDP = "PRESCRIPTION_DRUG_PLAN",
  MedigapPolicy = "MEDIGAP_POLICY",
  selectionNotMade = "SELECTION_NOT_MADE",
  OriginalMedicare = "ORIGINAL_MEDICARE",
  learnMore = "LEARN_MORE",
}

export enum WhatToDoType {
  learnMore = "LEARN_MORE",
  choosePlans = "CHOOSE_PLANS",
  selectionNotMade = "SELECTION_NOT_MADE",
}

/**
 * Medigap
 */
export interface MedigapPlans {
  waiver: { [key: string]: MedigapPlan[] };
  nonWaiver: MedigapPlan[];
}

export interface MedigapPlan {
  state?: string;
  plan_type: MedigapPlanType;
  plan_name: string;
  plan_details_name: string;
  plan_policy_name: string;
  part_a_deductible: string;
  part_a_deductible_note?: string;
  part_b_deductible: string;
  part_b_deductible_note?: string;
  part_b_coinsurance: string;
  part_b_coinsurance_note?: string;
  hospice: string;
  plan_benefits: string;
  hospital_stay_1_60: string;
  hospital_stay_61_90: string;
  hospital_stay_91_150: string;
  hospital_stay_after_150?: string;
  hospital_stay_additional_365?: string;
  hospital_stay_after_365?: string;
  snf_days_1_20: string;
  snf_days_21_100?: string;
  snf_days_21_120?: string;
  snf_after_120_days?: string;
  snf_after_100_days?: string;
  snf_days_101_365?: string;
  snf_days_after_365?: string;
  snf_not_covered_1_365?: string;
  snf_not_covered_after_365_days?: string;
  snf_not_days_1_30?: string;
  snf_not_after_30?: string;
  a_blood_first_3: string;
  a_blood_after_3: string;
  ip_mental_health_days_1_60?: string;
  ip_mental_health_days_61_90?: string;
  ip_mental_health_days_91_150?: string;
  ip_mental_health_days_add_175?: string;
  ip_mental_health_days_after_175?: string;
  ip_mental_bio_days_1_60?: string;
  ip_mental_bio_days_61_90?: string;
  ip_mental_bio_days_91_190?: string;
  ip_mental_bio_add_365_days?: string;
  ip_mental_bio_beyond_add_365_days?: string;
  ip_mental_other_first_60?: string;
  ip_mental_other_61?: string;
  ip_mental_other_after_60_cy?: string;
  home_health: string;
  home_health_add_40?: string;
  home_health_after_40?: string;
  home_health_add_365?: string;
  home_health_after_365?: string;
  dme_first: string;
  dme_remainder_then: string;
  covered_medical_services_first: string;
  covered_medical_services_then: string;
  preventive_services?: string;
  prev_serv_cover?: string;
  prev_serv_non?: string;
  phys_ther?: string;
  op_mental_health?: string;
  state_mand?: string;
  excess_charges: string;
  b_blood_first_3: string;
  b_blood_after_3: string;
  tests_diagnostics?: string;
  outpatient_biological_mental_covered_first?: string;
  outpatient_biological_mental_covered_then?: string;
  outpatient_biological_mental_not_covered?: string;
  outpatient_other_mental_covered_first?: string;
  outpatient_other_mental_covered_then?: string;
  outpatient_other_mental_not_covered_1_24_visits?: string;
  outpatient_other_mental_not_covered_25_plus_visits?: string;
  state_mandate_covered_first?: string;
  state_mandate_covered_then?: string;
  state_mandate_not_covered?: string;
  foreign_travel_emergency?: string;
  foreign_travel_emergency_first?: string;
  foreign_travel_emergency_remainder_then?: string;
  out_pocket_limit?: string;
  total_deductible?: string;
}

export interface MedigapPlanInfo {
  plan: MedigapPlan;
  premiumRange: MedigapPlanPremiumRange;
}

export enum MedigapRateType {
  MEDIGAP_RATE_TYPE_ATTAINED_AGE = "MEDIGAP_RATE_TYPE_ATTAINED_AGE",
  MEDIGAP_RATE_TYPE_ISSUE_AGE = "MEDIGAP_RATE_TYPE_ISSUE_AGE",
  MEDIGAP_RATE_TYPE_COMMUNITY_RATED = "MEDIGAP_RATE_TYPE_COMMUNITY_RATED",
}

export interface MedigapMonthlyRates {
  monthly_rate_min: number;
  monthly_rate_max: number;
  monthly_rate_hhd_roommate_max: number | null;
  monthly_rate_hhd_roommate_min: number | null;
  monthly_rate_hhd_standard_max: number | null;
  monthly_rate_hhd_standard_min: number | null;
}

export interface MedigapPlanPremiumRange extends MedigapMonthlyRates {
  plan_type: string;
  medigap_plan_type: MedigapPlanType;
}

export interface MedigapPlanPolicy extends MedigapMonthlyRates {
  address: string;
  company: string;
  phone_number: string;
  rate_type: MedigapRateType;
  website: string;
}

export enum MedigapPlanType {
  A = "MEDIGAP_PLAN_TYPE_A",
  B = "MEDIGAP_PLAN_TYPE_B",
  C = "MEDIGAP_PLAN_TYPE_C",
  D = "MEDIGAP_PLAN_TYPE_D",
  F = "MEDIGAP_PLAN_TYPE_F",
  HIGH_F = "MEDIGAP_PLAN_TYPE_HIGH_F",
  G = "MEDIGAP_PLAN_TYPE_G",
  HIGH_G = "MEDIGAP_PLAN_TYPE_HIGH_G",
  K = "MEDIGAP_PLAN_TYPE_K",
  L = "MEDIGAP_PLAN_TYPE_L",
  M = "MEDIGAP_PLAN_TYPE_M",
  N = "MEDIGAP_PLAN_TYPE_N",
  MA_CORE = "MEDIGAP_PLAN_TYPE_MA_CORE",
  MA_SUPP_1 = "MEDIGAP_PLAN_TYPE_MA_SUPP_1",
  MA_SUPP_1A = "MEDIGAP_PLAN_TYPE_MA_SUPP_1A",
  MN_BASIC = "MEDIGAP_PLAN_TYPE_MN_BASIC",
  MN_EXT_BASIC = "MEDIGAP_PLAN_TYPE_MN_EXT_BASIC",
  MN_EXT_BASIC_NEW = "MEDIGAP_PLAN_TYPE_MN_EXT_BASIC_NEW",
  MN_HIGH_F = "MEDIGAP_PLAN_TYPE_MN_HIGH_F",
  MN_HIGH_F_NEW = "MEDIGAP_PLAN_TYPE_MN_HIGH_F_NEW",
  MN_SUPP_50 = "MEDIGAP_PLAN_TYPE_MN_SUPP_50",
  MN_SUPP_75 = "MEDIGAP_PLAN_TYPE_MN_SUPP_75",
  MN_N = "MEDIGAP_PLAN_TYPE_MN_N",
  MN_M = "MEDIGAP_PLAN_TYPE_MN_M",
  WI_BASIC = "MEDIGAP_PLAN_TYPE_WI_BASIC",
  WI_HIGH_DEDUCTIBLE = "MEDIGAP_PLAN_TYPE_WI_HIGH_DEDUCTIBLE",
  WI_25_COST = "MEDIGAP_PLAN_TYPE_WI_25_COST",
  WI_50_COST = "MEDIGAP_PLAN_TYPE_WI_50_COST",
}

export enum MedigapPlanBenefit {
  SKILLED_NURSING = "medigap.benefit.skilled_nursing",
  PART_A_DEDUCTIBLE = "medigap.benefit.part_a_deductible",
  PART_B_DEDUCTIBLE = "medigap.benefit.part_b_deductible",
  PART_B_EXCESS = "medigap.benefit.part_b_excess_charges",
  FOREIGN_TRAVEL_EMERGENCY = "medigap.benefit.foreign_travel_emergency",
}

export enum MedigapWaiverType {
  NONE = "NONE",
  MA = "MA",
  MN = "MN",
  WI = "WI",
}

export interface GetMedigapPricingYearFlags extends LDFlagSet {
  feEnableNextYearMedigapPricing: boolean;
  mctCurrentPlanYear: number;
}

export enum ManagedQueryParameters {
  YEAR = "year",
  LANG = "lang",
}

export enum DismissibleAlert {
  M3PHighDrugCosts,
}

export interface LogoutParams {
  /**
   * controls logging out MCTAPI at `/beneinfo/logout` (defaults to `false`)
   * - If `true`, no MCTAPI logout call is made
   * - If `false`, an attempt is made to log out MCTAPI
   */
  apiHasExpired?: boolean;
  /**
   * controls the toast type on redirect from `/sso/logout` (defaults to `false`)
   * - If `true`, the user will see a "Session Expired" toast after logout
   * - If `false`, the user will see a "Logged Out" toast after logout
   */
  sessionHasExpired?: boolean;
  /**
   * Any callback to be called during the logout process.
   * Run before calling `RESET_STATE` and erasing the `sid` cookie
   */
  onLogout?: () => void;
}

/** Component-specific types that need to be used outside of components, e.g., in helpers */
export * from "./components";

/** Utility types / helpers */
export * from "./helpers";
