import { ReactNode, useContext } from "react";
import { IntlContext } from "./intlHooks";

export type CustomTag = {
  tag: string;
  isPII?: boolean;
  handler?: (chunks: ReactNode[] | string[]) => ReactNode | string;
};

const PII_PLACEHOLDER = "";

const createCustomTagHandlers = (customTags: CustomTag[]) => {
  const tagHandlers = {};
  for (const customTag of customTags) {
    if (customTag.isPII) {
      tagHandlers[customTag.tag] = PII_PLACEHOLDER;
    } else if (customTag.handler) {
      tagHandlers[customTag.tag] = customTag.handler;
    } else {
      tagHandlers[customTag.tag] = (chunks: ReactNode[]) => chunks;
    }
  }
  return tagHandlers;
};

/**
 * useFormattedMessageRemoveCustomTags is used for removing custom tags from phrase key for analytics purpose.
 *
 * @example
 * const alertContentText = useFormattedMessageRemoveCustomTags(phraseKey, [
 *   { tag: "strong" },
 *   { tag: "iep_end_date", isPII: true },
 *   { tag: "pdp", handler: chunks => (isPDPlan ? chunks : "") },
 * ]);
 * @param phraseKey {string} the phrase key
 * @param tags {CustomTag[]} list of CustomTags
 * @returns {string} phrase key with custom tags removed
 */
export const useFormattedMessageRemoveCustomTags = (
  phraseKey: string,
  tags: CustomTag[]
): string => {
  const intl = useContext(IntlContext);
  return intl
    .formatMessage(
      {
        id: phraseKey,
      },
      createCustomTagHandlers(tags)
    )
    .toString();
};
