import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React from "react";

const defaultProps = {
  className: "",
  title: "hand-receive-money",
  viewBox: "0 0 48 42",
};

export const HandReceiveMoneyIcon = (props: IconCommonProps) => {
  const iconCssClasses = `mct-c-icon--home ${props.className || ""}`;

  return (
    <SvgIcon {...defaultProps} {...props} className={iconCssClasses}>
      <g fillRule="nonzero">
        <path d="M8.25 18H.75c-.413 0-.75.36-.75.818v16.364c0 .45.337.818.75.818h6c.382 0 .705-.32.743-.736l.089-.957 1.336-14.574.075-.833c0-.033.007-.056.007-.09a.8.8 0 0 0-.195-.539A.719.719 0 0 0 8.25 18Zm-3.975 6.546c-.825 0-1.5-.737-1.5-1.637 0-.907.675-1.636 1.5-1.636.832 0 1.5.729 1.5 1.636 0 .9-.666 1.637-1.5 1.637ZM45.283 21.118c-1.367-1.517-2.74-.95-3.4-.512l-9.3 5.503a.734.734 0 0 0-.366.629v1.447s.01 2.54-3.319 2.54h-7.384a.809.809 0 0 1-.823-.798c0-.439.37-.798.823-.798h7.12c2.315 0 2.524-5.246-1.07-5.246l-2.28.055c-.757 0-1.68-.287-2.298-.719l-2.609-1.822A7.977 7.977 0 0 0 15.916 20l-5.45.025-.164 1.597-1.137 11.035L9 34.254h2.043c1.096 0 2.166.2 3.177.6l5.92 2.308c1.367.534 2.99.838 4.472.838l4.356-.032c1.688 0 3.466-.687 4.536-1.75l11.643-11.571c1.083-.956 1.142-2.411.136-3.53v.001Z" />
        <path d="M30 20c5.516 0 10-4.484 10-10S35.516 0 30 0 20 4.484 20 10s4.484 10 10 10Zm-.833-14.867v-.966c0-.459.375-.834.833-.834.458 0 .833.375.833.834v.966a2.89 2.89 0 0 1 1.5 1.034.834.834 0 0 1-1.333 1 1.229 1.229 0 0 0-1-.5c-.693 0-1.25.557-1.25 1.25 0 .692.557 1.25 1.25 1.25a2.919 2.919 0 0 1 2.917 2.916c0 1.318-.883 2.417-2.084 2.784v.966a.836.836 0 0 1-.833.834.836.836 0 0 1-.833-.834v-.966a2.89 2.89 0 0 1-1.5-1.034.834.834 0 0 1 1.333-1c.234.318.599.5 1 .5.693 0 1.25-.557 1.25-1.25s-.557-1.25-1.25-1.25a2.919 2.919 0 0 1-2.917-2.916c0-1.318.883-2.417 2.084-2.784Z" />
      </g>
    </SvgIcon>
  );
};
