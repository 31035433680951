import { EnrollButtonContentReplacementMap } from "./types";

/**
 * Takes in a mapping of possible content replacment options paired with a `boolean`
 * The first content replacment option that's flagged `true` should be rendered
 * instead of the button in the `EnrollButton` component
 */
export const getEnrollButtonReplacementContent = (
  enrollButtonTextReplacementMap: EnrollButtonContentReplacementMap
): JSX.Element | null => {
  let replacementText: JSX.Element | null = null;
  for (let i = 0; i < enrollButtonTextReplacementMap.length; i++) {
    const [buttonReplacementText, shouldRender] =
      enrollButtonTextReplacementMap[i];
    if (shouldRender) {
      replacementText = buttonReplacementText;
      break;
    }
  }
  return replacementText;
};
