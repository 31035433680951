import { Button, Dropdown } from "@cmsgov/ds-medicare-gov";
import uniqueId from "lodash.uniqueid";
import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../app/contexts/Analytics/types";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { useTranslate } from "../../helpers/intlHooks";
import { useBelowLargeBreakpoint } from "../../helpers/tableHelpers";
import { FilterConfig } from "./RenderedTable";

/**
 * A global (table-wide, not column-specific) select component for filtering a
 * table on row header values
 * @param - `react-table` Globl filter params
 * @returns - The select filter, based on CMSDS/MDS Dropdown
 */
export const GlobalSelectFilterUI: React.FunctionComponent<
  Record<string, unknown> & {
    options: { value: string; label: string }[];
    onUpdateFilter?: (filterValue: string) => void;
    filterConfig?: Omit<
      FilterConfig,
      "hideFilterRowHeading" | "showActiveFilter"
    >;
    globalFilterValue?: string;
    id?: string;
  }
> = ({
  id = uniqueId("globalTableFilter"),
  globalFilterValue = "",
  options,
  onUpdateFilter = () => {
    return;
  },
  filterConfig = {
    label: null,
    buttonLabel: null,
    ariaLabel: null,
    selectedItemInfo: null,
  },
}) => {
  const { dispatch } = useAppContext();
  const t = useTranslate();

  const firstOption = options.find(option => option?.value !== "");
  const [selectValue, setSelectValue] = useState(globalFilterValue);
  const [localFilterValue, setLocalFilterValue] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [inputRef, setInputRef] = useState<HTMLSelectElement | null>(null);

  const controlButtonState = (): void => {
    if (localFilterValue !== "" && selectValue !== "") {
      if (localFilterValue === selectValue) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  };

  useLayoutEffect(() => {
    controlButtonState();
  }, [globalFilterValue]);

  useEffect(() => {
    controlButtonState();
  }, [localFilterValue, selectValue]);

  useEffect(() => {
    onUpdateFilter(localFilterValue);
  }, [localFilterValue]);

  const updateSelect = (value: string | undefined): void => {
    setSelectValue(value || "");
    if (inputRef) {
      const index = options.map((kvPair, i) =>
        kvPair.value === value ? i : 1
      )[0];
      inputRef.selectedIndex = index;
    }
  };

  const setFilterAndValue = (value: string | undefined): void => {
    updateSelect(value);
    setLocalFilterValue(value || "");
  };

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    updateSelect(value);
  };

  const onClickHandler = (): void => {
    setLocalFilterValue(selectValue || "");
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
          buttonType: AnalyticsButtonType.BUTTON,
          text:
            filterConfig?.buttonLabel ||
            t("react_table.select_filter.default_button_label"),
        },
      },
    });
  };

  useBelowLargeBreakpoint(
    matches => {
      const aboveBreakpoint = !matches;
      if (aboveBreakpoint) {
        setFilterAndValue("");
      } else {
        setFilterAndValue(firstOption?.value);
      }
    },
    [options]
  );

  // Render a multi-select box
  // Use the `onChange` event, even though currently there's a jsx-a11y issue
  // @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/398
  // @TODO - Rather than adjust our jsx-ally configuration, we can await an
  // update to that plugin
  return (
    <div
      className="mct-u-lg-display--none ds-u-margin-y--2"
      role="group"
      {...(filterConfig.ariaLabel
        ? { ["aria-label"]: filterConfig.ariaLabel }
        : {})}
    >
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <Dropdown
        label={
          filterConfig?.label ||
          t("react_table.select_filter.default_filter_label")
        }
        labelClassName="ds-u-margin-top--0"
        fieldClassName="e2e-table-filter"
        name={`${id}_Filter`}
        value={selectValue}
        inputRef={ref => {
          setInputRef(ref);
        }}
        options={[]}
        onChange={onChangeHandler}
      >
        {options.map((opt, i) => {
          return (
            <option key={i} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </Dropdown>
      {filterConfig?.selectedItemInfo}
      <Button
        isAlternate
        variation="solid"
        className="ds-u-margin-top--2 e2e-table-filter-change ds-u-display--block"
        onClick={onClickHandler}
        disabled={disableButton}
      >
        {filterConfig?.buttonLabel ||
          t("react_table.select_filter.default_button_label")}
      </Button>
    </div>
  );
};
