import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { IdParts } from "../@types";
import routes from "../app/routes";
import { makePlanLongId } from "../helpers/objectUtilities";

interface PlanDetailsLinkProps {
  ariaDescribedBy?: string;
  ariaLabelledBy?: string;
  className?: string;
  htmlId?: string;
  label: React.ReactNode;
  onClick?: () => void;
  plan: IdParts;
  section?: string;
}

export const PlanDetailsLink: React.FunctionComponent<PlanDetailsLinkProps> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  className,
  htmlId,
  label,
  onClick,
  plan,
  section,
}) => {
  const location = useLocation();
  return (
    <Link
      {...(ariaDescribedBy ? { "aria-describedby": ariaDescribedBy } : {})}
      {...(ariaLabelledBy ? { "aria-labelledby": ariaLabelledBy } : {})}
      className={className || ""}
      id={htmlId || ""}
      onClick={onClick}
      to={`${routes.planDetails.replace(":id", makePlanLongId(plan))}${
        location.search
      }${section ? `#${section}` : ""}`}
    >
      {label}
    </Link>
  );
};
