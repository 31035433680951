import React from "react";
import { SearchResultPlan } from "../../@types";
import { SuppressionHandler } from "..";
import { useTranslate } from "../../helpers/intlHooks";
import { getDisplayAnnualDrugDeductible } from "../../helpers/planDataUtils";
import { TooltipWithClose } from "../TooltipWithClose";
import { useLIS } from "../../helpers/context-hooks/useLIS";

interface DeductibleProps {
  plan: SearchResultPlan;
}

const Deductible: React.FunctionComponent<DeductibleProps> = ({ plan }) => {
  const t = useTranslate();

  const { getLISForYear } = useLIS();
  const lisToUse = getLISForYear(+plan.contract_year);

  return (
    <div className="PlanCard__section" data-testid="deductible">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.deductible")}
      </h3>
      <div className="PlanCard__info_group">
        <div className="PlanCard__data ds-u-display--inline">
          <SuppressionHandler redactions={plan.redactions}>
            {getDisplayAnnualDrugDeductible({
              lisToUse,
              drugPlanDeductible: plan.drug_plan_deductible,
              contractYear: plan.contract_year,
            })}
          </SuppressionHandler>
        </div>
        <TooltipWithClose title={t("plan_card.tooltip.drug_deductible")}>
          {t("plan_terms.drug_deductible")}
        </TooltipWithClose>
      </div>
    </div>
  );
};

export default Deductible;
