import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import { Button, ChoiceList, TextField } from "@cmsgov/ds-medicare-gov";
import { County } from "../@types";
import * as api from "../api";
import { useInitialFormValues } from "../helpers/coverageSelectorHelpers";
import { useTranslate } from "../helpers/intlHooks";
import stateData from "../helpers/states";
import { isValidZipcode } from "../helpers/zipcodeHelpers";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  TealiumEventProps,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import { MandatoryDialog } from "../components";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
interface StatePickerProps {
  label: string;
  onStateSelect: (county: County, zipcode: string) => void;
  className?: string;
  onInputTealiumProps?: TealiumEventProps;
  onBtnClickAnalytics?: () => void;
}

/**
 * this is a zip code input which allows the user to pick a state
 * if they add a zipcode that belongs to multiple states.
 * ? NOTE: This component is currently only used in MedigapLandingPage.
 * ? If this is not useful outside of Medigap, consider re-locating to Medigap folder.
 */
export const StatePicker: FunctionComponent<StatePickerProps> = ({
  label,
  onStateSelect,
  className,
  onInputTealiumProps,
  onBtnClickAnalytics,
}) => {
  // * Hooks
  const t = useTranslate();
  const { dispatch } = useAppContext();
  const {
    initialFormValues: { zipcode: initialZip },
  } = useInitialFormValues();

  // * State
  const [fetching, setFetching] = useState(false);
  const [zipcodeInput, setZipcodeInput] = useState(initialZip);
  const [zipEntered, setZipEntered] = useState(!!initialZip);
  const [countyResults, setCountyResults] = useState<County[]>([]);
  const [selectedCounty, setSelectedCounty] = useState<County | null>(null);
  const [zipErrorMessage, setZipErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [selectStateError, setSelectStateError] = useState<undefined | string>(
    undefined
  );
  const [showModal, setShowModal] = useState(false);

  // * Constants

  /** a reliably valid `zipcode` const */
  const zipcode = isValidZipcode(zipcodeInput) ? zipcodeInput : undefined;

  /** an array of unique states, derived from countyResults. */
  const uniqueStates = Array.from(
    new Set(countyResults.map(county => county.state))
  );

  const searchForCounties = async (): Promise<void> => {
    if (zipcode) {
      setZipErrorMessage(undefined);
      setFetching(true);
      setCountyResults([]);

      // analytics - Not recording zip, and only sending once
      if (!zipEntered) {
        if (onInputTealiumProps) {
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: onInputTealiumProps,
          });
        }
        setZipEntered(true);
      }

      try {
        // TODO: Replace this call with `useCounties` hook. https://jira.cms.gov/browse/MCT-9682
        const counties = await api.getCounties(zipcode);
        setCountyResults(counties);
      } catch (error) {
        setZipErrorMessage(t("state_picker.error_fetching_message"));
      }
      setFetching(false);
    } else {
      setZipErrorMessage(t("state_picker.error_message"));
    }
  };

  const onStateSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Medigap - Find a Policy",
        event_label: "CTA: Location Selected",
      },
    });
    const selected: County = JSON.parse(e.target.value);

    setSelectedCounty(selected);
  };

  const onSubmit = (e: ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();
    searchForCounties();
  };

  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setZipcodeInput(e.target.value);
  };

  /**
   * Triggered when the "Start" button populates `countyResults`
   * calls `onStateSelect` if there's only one state to pick from
   */
  useEffect(() => {
    if (uniqueStates.length === 1 && zipcode) {
      onStateSelect(countyResults[0], zipcode);
    }
  }, [countyResults, onStateSelect, uniqueStates.length, zipcode]);

  /**
   * Triggered when the "Start" button populates `countyResults`
   * shows modal if we have multiple states to pick from.
   */
  useEffect(() => {
    if (uniqueStates.length > 1) {
      setShowModal(true);
    }
  }, [uniqueStates.length]);

  return (
    <div className={`StatePicker ${className ? className : ""}`}>
      <form
        className="StatePicker__input e2e-select-county"
        onSubmit={onSubmit}
      >
        <TextField
          label={label}
          labelClassName="e2e-enter-zip StatePicker__label"
          className="ds-c-field--statePicker"
          name="zip"
          errorMessage={zipErrorMessage}
          onChange={handleZipcodeChange}
          value={zipcodeInput}
          maxLength={5}
        />

        <Button
          className="e2eStatePickerSelectBtn"
          type="submit"
          variation="solid"
          onClick={() => {
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.PRIMARY,
                  buttonType: AnalyticsButtonType.SUBMIT,
                  text: t("state_picker.button_text"),
                },
              },
            });
            if (onBtnClickAnalytics) {
              onBtnClickAnalytics();
            }
          }}
        >
          {t("state_picker.button_text")}
        </Button>
      </form>
      {fetching && <p>{t("app.loading")}...</p>}
      {showModal && (
        <MandatoryDialog
          heading={t("county_picker.choose_your_state")}
          className="SelectStateModal"
          headerClassName="SelectStateModal__header"
          onExit={(): void => setShowModal(false)}
          actions={[
            <Button
              className="ds-u-margin-right--2"
              key="primary"
              variation="solid"
              onClick={(): void => {
                if (selectedCounty && zipcode) {
                  onStateSelect(selectedCounty, zipcode);
                  dispatch({
                    type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                    settings: {
                      event_action: "Medigap - Find a Policy",
                      event_label: "CTA: Continue",
                    },
                  });
                } else {
                  setSelectStateError(
                    t("state_picker.modal.error_message.state_not_selected")
                  );
                }
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.PRIMARY,
                      buttonType: AnalyticsButtonType.BUTTON,
                      text: t("state_picker.modal.button_text"),
                    },
                  },
                });
              }}
            >
              {t("state_picker.modal.button_text")}
            </Button>,
          ]}
        >
          <ChoiceList
            name="state"
            label={""}
            type="radio"
            errorMessage={selectStateError}
            choices={uniqueStates.map(state => ({
              label: stateData.find(s => s.abbreviation === state)?.name || "",
              value: JSON.stringify(
                countyResults.find(county => county.state === state)
              ),
            }))}
            onChange={onStateSelected}
          />
        </MandatoryDialog>
      )}
    </div>
  );
};

export default StatePicker;
