import { AlertMessageConditions } from "../../app/contexts/AlertMessaging/types";
import englishTranslations from "../../translations/en-US.json";

/**
 * @constant
 * Object map of AlertMessageConditions to Topics (`strings`) used for analytics
 */
export const alertMessageConditionTopicMap = {
  [AlertMessageConditions.NO_SAVED_PHARMACIES]:
    englishTranslations["lcp.messages.no_saved_pharmacies.topic"],
  [AlertMessageConditions.NO_SAVED_DRUGS]:
    englishTranslations["lcp.messages.no_saved_drugs.topic"],
  [AlertMessageConditions.ONE_SAVED_PHARMACY]:
    englishTranslations["lcp.messages.one_saved_pharmacy.topic"],
  [AlertMessageConditions.ONE_SAVED_PHARMACY_STALE_ACCOUNT]:
    englishTranslations["lcp.messages.one_saved_pharmacy.stale_account.topic"],
  [AlertMessageConditions.NO_IN_NETWORK_SAVED_PHARMACIES]:
    englishTranslations["lcp.messages.no_in_network_pharmacies.topic"],
  [AlertMessageConditions.NO_PREFERRED_SAVED_PHARMACIES]:
    englishTranslations["lcp.messages.no_preferred_pharmacies.topic"],
  // @TODO - determine what else needs to be done here, as there is no message that matches
  [AlertMessageConditions.STALE_ACCOUNT_PHARMACIES]:
    englishTranslations["lcp.messages.stale_account.pharmacies.topic"],
  // @TODO - determine what else needs to be done here, as there is no message that matches
  [AlertMessageConditions.STALE_ACCOUNT_DRUGS]:
    englishTranslations["lcp.messages.stale_account.drugs.topic"],
};

export const alertMessageTestId = "alert-message";
export const alertMessageBodyTestId = `${alertMessageTestId}__body`;
export const alertMessageTitleTestId = `${alertMessageTestId}__title`;
export const alertMessageActionTestId = `${alertMessageTestId}__action`;
