import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Plan } from "../@types";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

/**
 * Returns Yearly Cost messaging that's appropriate for the current calendar year.
 * Can optionally take a plan contract year to check against, or will evaluate
 * the currently selected year in the UI (and app state) to determine whether the
 * messaging treats the year being rendered as the current year or next year.
 */
export const YearlyCostContextMessage: FC<{
  contractYear?: Plan["contract_year"];
}> = ({ contractYear }) => {
  const {
    state: { year: planSearchYear },
  } = useAppContext();
  const yearToCheckAgainst = contractYear || planSearchYear;
  const calendarYear = new Date().getFullYear();
  const yearToCheckAgainstIsCalendarYear =
    Number(yearToCheckAgainst) === calendarYear;

  return yearToCheckAgainstIsCalendarYear ? (
    <FormattedMessage
      id="yearly_cost.year_context"
      values={{ year: yearToCheckAgainst }}
    />
  ) : (
    <FormattedMessage
      id="yearly_cost.year_context.next_year"
      values={{ year: yearToCheckAgainst }}
    />
  );
};
