import { IEPStatus } from "../@types";
import { isValidApiDate } from "./dateHelpers";

/**
 * Gets the IEPStatus from the API IEP dates
 * @param iepStart API IEP start date
 * @param iepEnd API IEP end end
 * @returns IEPStatus or undefined if the IEP dates are invalid
 */
export const getIEPStatus = (
  iepStartDate: Date,
  iepEndDate: Date
): IEPStatus => {
  const currentDate = new Date();
  if (currentDate < iepStartDate) {
    return IEPStatus.IEP_STATUS_BEFORE_IEP;
  }
  if (currentDate > iepEndDate) {
    return IEPStatus.IEP_STATUS_PAST_IEP;
  }
  return IEPStatus.IEP_STATUS_WITHIN_IEP;
};

/**
 * Gets the IEPStatus from the API IEP dates
 * @param iepStart API IEP start date
 * @param iepEnd API IEP end end
 * @returns IEPStatus or undefined if the IEP dates are invalid
 */
export const getIEPStatusFromAPI = (
  iepStart: string,
  iepEnd: string
): IEPStatus | undefined => {
  if (isValidApiDate(iepStart) && isValidApiDate(iepEnd)) {
    const iepStartDate = new Date(iepStart);
    const iepEndDate = new Date(iepEnd);
    return getIEPStatus(iepStartDate, iepEndDate);
  }
  return undefined;
};
