import React, { FC, ReactElement } from "react";
import { DrugCostInfo, PharmacyType, PlanType, Redaction } from "../../@types";
import { isMedicareAdvantageType, isPdPlanType } from "../../helpers/planTypes";
import { $$ } from "../../helpers/currencyUtilities";
import { useTranslate } from "../../helpers/intlHooks";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { FeatureSubtitle } from "./FeatureSubtitle";
import { SuppressionHandler } from "..";
import { HashLink } from "react-router-hash-link";
import { CUSTOM_INTERNAL_LINK_CLICKED_EVENT } from "../AnalyticsListeners";
import {
  AnalyticsActionType,
  Ga4Event,
  LinkType,
} from "../../app/contexts/Analytics/types";
import eng from "../../translations/en-US.json";

export type DrugCoverageProps = {
  drugCostInfo: DrugCostInfo;
  drugsCovered: ReactElement;
  pharmacyType: PharmacyType;
  plan_type: PlanType;
  redactions: Redaction[];
  pdpUseRetailMessaging: boolean;
  showViewDrugCoverageLink?: boolean;
};

/**
 * `pdpUseRetailMessaging` is used when `plan_type` is `PlanType.PDP`,
 * becuase it may be rendered 2x, once for MO and once for retail
 */
export const DrugCoverage: FC<DrugCoverageProps> = ({
  drugCostInfo,
  drugsCovered,
  pharmacyType,
  plan_type,
  redactions,
  pdpUseRetailMessaging,
  showViewDrugCoverageLink = false,
}) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();
  const { lowest_mail_total, lowest_retail_total } = drugCostInfo;
  const lowestMailNum = Number(lowest_mail_total);
  const lowestRetailNum = Number(lowest_retail_total);

  const formattedLowestMail = isNaN(lowestMailNum)
    ? lowest_mail_total
    : $$(lowestMailNum);
  const formattedLowestRetail = isNaN(lowestRetailNum)
    ? lowest_retail_total
    : $$(lowestRetailNum);

  const isMailOnly = pharmacyType === PharmacyType.MAIL;

  let cost: string | number | null = null;

  if (isMedicareAdvantageType(plan_type)) {
    cost = isMailOnly ? formattedLowestMail : formattedLowestRetail;
  }

  if (isPdPlanType(plan_type)) {
    cost = pdpUseRetailMessaging ? formattedLowestRetail : formattedLowestMail;
  }

  const handleViewDrugCoverageClick = () => {
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.JUMP_LINK_CLICKED,
        link_type: LinkType.LINK_OTHER,
        text: eng["what_youll_pay.view_drug_coverage"],
      },
    });
  };

  return cost ? (
    <SuppressionHandler redactions={redactions} suppressOnlyDrugPricing>
      <div className="mct-c-what-youll-pay__cost">{cost}</div>
      <FeatureSubtitle>
        {drugsCovered}
        {showViewDrugCoverageLink && (
          <div className="mct-c-what-youll-pay__view-drug-coverage">
            <HashLink
              {...{ [CUSTOM_INTERNAL_LINK_CLICKED_EVENT]: "true" }}
              to="#yearly_drug_costs_by_pharmacy"
              className="mct-c-what-youll-pay__view-drug-coverage-link"
              onClick={handleViewDrugCoverageClick}
            >
              {t("what_youll_pay.view_drug_coverage")}
            </HashLink>
          </div>
        )}
      </FeatureSubtitle>
    </SuppressionHandler>
  ) : null;
};
