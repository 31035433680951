import React, { FunctionComponent } from "react";
import { CheckCircleIcon } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../../helpers/intlHooks";
import classNames from "classnames";

interface InNetworkLabelProps {
  preferred?: boolean;
  darkBackground?: boolean;
}

interface InNetworkLabelWideProps extends InNetworkLabelProps {
  narrow?: never;
  wide?: boolean;
}

interface InNetworkLabelNarrowProps extends InNetworkLabelProps {
  wide?: never;
  narrow?: boolean;
}

const InNetworkLabel: FunctionComponent<
  InNetworkLabelWideProps | InNetworkLabelNarrowProps
> = ({
  preferred = false,
  narrow = false,
  wide = false,
  darkBackground = false,
}) => {
  const t = useTranslate();
  return (
    <div
      className={classNames("mct-c-in-network-pharmacy-label", {
        "mct-c-in-network-pharmacy-label--narrow": narrow,
        "mct-c-in-network-pharmacy-label--wide": wide,
        "mct-c-in-network-pharmacy-label--dark-background": darkBackground,
      })}
    >
      <div
        className={classNames("mct-c-in-network-pharmacy-label-body", {
          "mct-c-in-network-pharmacy-label-body--preferred": preferred,
          "mct-c-in-network-pharmacy-label-body--narrow": narrow,
          "mct-c-in-network-pharmacy-label-body--wide": wide,
          "mct-c-in-network-pharmacy-label-body--preferred-dark-background":
            preferred && darkBackground,
        })}
      >
        <CheckCircleIcon
          className={classNames("mct-c-in-network-pharmacy-label-body__icon", {
            "mct-c-in-network-pharmacy-label-body__icon--dark-background":
              darkBackground,
          })}
        />
        <span
          className={classNames("mct-c-in-network-pharmacy-label-body__text", {
            "mct-c-in-network-pharmacy-label-body__text--narrow": narrow,
            "mct-c-in-network-pharmacy-label-body__text--wide": wide,
            "mct-c-in-network-pharmacy-label-body__text--dark-background":
              darkBackground,
            "mct-c-in-network-pharmacy-label-body__text--preferred": preferred,
          })}
        >
          {preferred
            ? t("pharmacy.status.preferred")
            : t("pharmacy.terms.in_network")}
        </span>
      </div>
      {preferred && (
        <span
          className={classNames(
            "mct-c-in-network-pharmacy-label__extra-description",
            {
              "mct-c-in-network-pharmacy-label__extra-description--narrow":
                narrow,
              "mct-c-in-network-pharmacy-label__extra-description--wide": wide,
              "mct-c-in-network-pharmacy-label__extra-description--dark-background":
                darkBackground,
            }
          )}
        >
          {t("pharmacy.terms.in_network")}
        </span>
      )}
    </div>
  );
};

export default InNetworkLabel;
