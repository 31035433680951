import { useHistory } from "react-router-dom";
import { getGlobalSessionInfo } from "../api";
import { LogoutParams } from "../@types";
import { useCallback } from "react";
import routes from "../app/routes";

export const getIsSlsxAuthenticated = async () => {
  const [res, error] = await getGlobalSessionInfo()
    .then(res => [res, null])
    .catch(error => [null, error]);
  return error || res === undefined ? false : true;
};

export const useLogout = () => {
  const history = useHistory();
  const logout = useCallback(
    ({ apiHasExpired, onLogout, sessionHasExpired }: LogoutParams) =>
      history.replace({
        pathname: routes.logout,
        state: {
          apiHasExpired,
          onLogout,
          sessionHasExpired,
        },
      }),
    [history]
  );
  return logout;
};
