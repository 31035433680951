import { Reducer } from "react";
import {
  AppState,
  Action,
  ActionType,
  CoverageType,
  WizardAnswer,
  EnrollFormData,
  DismissibleAlert,
  AddressTextField,
  RolloverPlanStatus,
} from "../../@types";
import { analyticsReducer } from "../contexts/Analytics/reducer";
import { AnalyticsActions } from "../contexts/Analytics/types";
import * as TypeGuards from "../../@types/guards";
import {
  addPharmacy,
  addPrescription,
  deletePharmacy,
  deletePrescription,
  editPrescription,
  resetState,
  savePharmacies,
  setMailOrderPharmacyNetworkStatus,
  setPrescriptions,
  setPharmacies,
  updateTrackedEvents,
  updateLis,
  updatePlanType,
  updateSearchResultsFiltersCarriers,
  updatePharmacyNetworkStatus,
} from "./appStoreHelpers";

import { LDFlagSet } from "launchdarkly-js-client-sdk";

export const reducer: Reducer<AppState, AnalyticsActions | Action> = (
  state,
  action
) => {
  function newStateIf(
    method: keyof typeof TypeGuards,
    property: keyof AppState
  ): AppState {
    return TypeGuards[method](payload)
      ? { ...state, [property]: payload }
      : state;
  }

  if ("settings" in action) {
    return analyticsReducer(state, action);
  }
  const { payload } = action;

  switch (action.type) {
    case ActionType.ADD_BENEFICIARY:
      return newStateIf("isBeneficiary", "beneficiary");
    case ActionType.ADD_CURRENT_COVERAGE:
      return newStateIf("isSearchResultPlan", "currentCoverage");
    case ActionType.ADD_NEXT_YEAR_COVERAGE:
      return newStateIf("isSearchResultPlan", "nextYearCoverage");
    case ActionType.ADD_CSR:
      return newStateIf("isCSR", "csr");
    case ActionType.ADD_PHARMACY:
      return addPharmacy(state, action);
    case ActionType.ADD_PRESCRIPTION:
      return addPrescription(state, action);
    case ActionType.DELETE_PHARMACY:
      return deletePharmacy(state, action);
    case ActionType.DELETE_PRESCRIPTION:
      return deletePrescription(state, action);
    case ActionType.DISMISS_ALERT:
      return {
        ...state,
        dismissedAlerts: [
          ...state.dismissedAlerts,
          payload as DismissibleAlert,
        ],
      };
    case ActionType.EDIT_PRESCRIPTION:
      return editPrescription(state, action);
    case ActionType.RESET_PHARMACIES:
      return { ...state, pharmacies: [] };
    case ActionType.RESET_STATE:
      return resetState({ language: state.language });
    case ActionType.SAVE_PHARMACIES:
      return savePharmacies(state);
    case ActionType.SET_MAIL_ORDER_PHARMACY_NETWORK_STATUS:
      return setMailOrderPharmacyNetworkStatus(state, action);
    case ActionType.SET_PRESCRIPTIONS:
      return setPrescriptions(state, action);
    case ActionType.SET_PHARMACIES: {
      return setPharmacies(state, action);
    }
    case ActionType.SET_TEALIUM_LOADED:
      return { ...state, tealiumLoaded: payload ? true : false };
    case ActionType.EVENT_TRACKED:
      return updateTrackedEvents(state, action);
    case ActionType.UPDATE_COUNTY:
      return newStateIf("isCounty", "county");
    case ActionType.UPDATE_COMPARE_LIST:
      return newStateIf("hasSearchResultPlans", "compareList");
    case ActionType.UPDATE_CSR_GUEST_ACCESS:
      return { ...state, csrGuestAccess: payload ? true : false };
    case ActionType.UPDATE_FIPS:
      return newStateIf("hasFiveDigitString", "fips");
    case ActionType.UPDATE_LIS:
      return updateLis(state, action);
    case ActionType.UPDATE_FUTURE_LIS:
      return updateLis(state, action, true);
    case ActionType.UPDATE_PHARMACY_TYPE:
      return newStateIf("isPharmacyType", "pharmacyType");
    case ActionType.UPDATE_PLAN_TYPE:
      return updatePlanType(state, action);
    case ActionType.UPDATE_ENROLL_DATA:
      return { ...state, enrollData: payload as EnrollFormData };
    case ActionType.UPDATE_MBP_HANDOFF_FAILED:
      return newStateIf("isBoolean", "mbpHandoffFailed");
    case ActionType.UPDATE_MULTIPLE_ROLLOVER_PLANS:
      return newStateIf("isBoolean", "multipleRolloverPlans");
    case ActionType.UPDATE_NEW_TO_MEDICARE_ALERT_INFO:
      if (typeof payload === "undefined") {
        return { ...state, newToMedicareAlertInfo: payload };
      }
      return newStateIf(
        "isNewToMedicareAlertInfo",
        "anonNewToMedicareAlertInfo"
      );
    // Updating the zipcode should reset the persisted pharmacy address, since
    // the new zip indicates an intent to search in a new location
    case ActionType.UPDATE_ZIPCODE:
      return TypeGuards["hasFiveDigitString"](payload)
        ? {
            ...state,
            zipcode: payload,
            pharmacySearchAddress: { value: "", source: "none" },
          }
        : state;
    case ActionType.UPDATE_DEGRADED_INTEGRATIONS:
      return newStateIf("hasStrings", "degradedIntegrations");
    case ActionType.UPDATE_YEAR:
      return newStateIf("hasYear", "year");
    case ActionType.UPDATE_LANGUAGE:
      return newStateIf("isLanguage", "language");
    case ActionType.UPDATE_SELECTED_PLAN:
      return newStateIf("isPlanOrSRP", "selectedPlan");
    case ActionType.UPDATE_LAST_ACTIVITY:
      return newStateIf("isDate", "lastActivity");
    case ActionType.UPDATE_SNP_FILTERS:
      return newStateIf("hasPlanSNPTypes", "snpFilters");
    case ActionType.UPDATE_ROUTE_PARAMS:
      return {
        ...state,
        routeParams: (payload as Record<string, string>) || {},
      };
    case ActionType.UPDATE_SEARCH_RESULTS_FILTERS:
      return newStateIf("hasSearchResultsFilters", "searchResultsFilters");
    case ActionType.UPDATE_SEARCH_RESULTS_FILTERS_CARRIERS:
      return updateSearchResultsFiltersCarriers(state, action);
    case ActionType.UPDATE_PHARMACY_SEARCH_ADDRESS:
      return {
        ...state,
        pharmacySearchAddress: payload as AddressTextField,
      };
    case ActionType.UPDATE_PHARMACY_NETWORK_STATUS:
      return updatePharmacyNetworkStatus(state, action);
    case ActionType.UPDATE_DEMOGRAPHICS:
      return newStateIf("isDemographicInfo", "demographicInfo");
    case ActionType.UPDATE_CW_COVERAGE:
      return { ...state, cwCoverage: payload as CoverageType };
    case ActionType.UPDATE_MBP_REFERRER_URI:
      return newStateIf("isString", "mbpReferrerUri");
    case ActionType.UPDATE_LD_FLAGS:
      return { ...state, cachedFlags: payload as LDFlagSet };
    case ActionType.UPDATE_WIZARD_ANSWER:
      return { ...state, wizardAnswer: payload as WizardAnswer };
    case ActionType.UPDATE_ROLLOVER_PLAN_STATUS:
      return { ...state, rolloverPlanStatus: payload as RolloverPlanStatus };
    default:
      throw new Error(`Reducer missing handler for:
        ACTION: ${action.type}
        STATE: ${JSON.stringify(state, null, 2)}`);
  }
};
