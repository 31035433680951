import { useLDClient } from "launchdarkly-react-client-sdk";
import { PlanType, UseTranslateType } from "../../../@types";

export enum AlertMessageConditions {
  /** Add pharmacies */
  NO_SAVED_PHARMACIES = 0,
  /** Add drugs */
  NO_SAVED_DRUGS = 1,
  /** Add more pharmacies (stale) */
  ONE_SAVED_PHARMACY_STALE_ACCOUNT = 2,
  /** Add more pharmacies (standard) */
  ONE_SAVED_PHARMACY = 3,
  STALE_ACCOUNT_PHARMACIES = 4,
  STALE_ACCOUNT_DRUGS = 5,
  /** Add in-network pharmacies */
  NO_IN_NETWORK_SAVED_PHARMACIES = 6,
  /** Add preferred pharmacies */
  NO_PREFERRED_SAVED_PHARMACIES = 7,
}

export enum AlertMessageHierarchyKeys {
  SUMMARY_PAGE__PHARMACY_SECTION,
  SUMMARY_PAGE__DRUG_SECTION,
  PLAN_DETAILS__PHARMACY_SECTION,
  PLAN_COMPARISON_TABLE,
  PLAN_COMPARISON_NETWORK_STATUS,
  IN_NETWORK_PHARMACY_FINDER,
}

/**
 * @type
 * for adding specific page context to help correctly render links in alert messages
 * @property isFromSummaryPage - optional controls summary page specific rules around visibility and links
 * @property planId - optional planId to construct in-network plan finder links
 * @property planType - optional plan type to determine if alert should be active
 * @property planHasPreferredPharmacies -  indicates if the plan in this AlertMessageContext has any preferred pharmacies or not
 * @property routerState - optional router state to help set back links and 'done' buttons used with 'nextRoute' in router state
 */
export type AlertMessageContext = {
  isFromSummaryPage?: boolean;
  ldClient?: ReturnType<typeof useLDClient>;
  planHasPreferredPharmacies?: boolean;
  planId?: string;
  planType?: PlanType;
  routerState?: unknown;
  search?: string;
};

/**
 * @type
 * content and active status for displaying an alert message in the app
 * @property action - optional content for displaying a CTA button or link
 * @property active - is message currently valid and should display
 * @property body - content for displaying body of message
 * @property icon - optional status icon to display in alert
 * @property key - type of Alert Message
 * @property title - optional title for alert message
 */
export type AlertMessageType = {
  action:
    | ((
        t: UseTranslateType,
        alertMessageContext: AlertMessageContext
      ) => JSX.Element | null)
    | undefined;
  active: boolean;
  body: (
    t: UseTranslateType,
    alertMessageContext: AlertMessageContext
  ) => JSX.Element | string;
  icon?: JSX.Element;
  key: AlertMessageConditions;
  title:
    | ((
        t: UseTranslateType,
        alertMessageContext: AlertMessageContext
      ) => string)
    | undefined;
};

/**
 * @type
 * Formatted version of the AlertMessage type with all of the phrase translations and interpolations completed
 */
export type FormattedAlertMessage = Pick<
  AlertMessageType,
  "active" | "icon" | "key"
> & {
  action: JSX.Element | null | undefined;
  body: JSX.Element | string;
  title: string | undefined;
};

export type MessagesState = {
  messages: Record<AlertMessageConditions, AlertMessageType>;
};

export type FlattenedMessageState = {
  messages: AlertMessageType[];
};
