import React from "react";
import { FormattedMessage } from "react-intl";
import {
  SearchResultPlan,
  PlanType,
  PlanCategoryType,
  PlanSNPType,
  PlanCardSNPTypeLabelKeys,
} from "../../@types";
import { SuppressionHandler } from "..";
import { useTranslate } from "../../helpers/intlHooks";
import { $$, ifNumber } from "../../helpers/currencyUtilities";
import { getPartBPremium } from "../../helpers/planDataUtils";

interface MonthlyPremiumProps {
  plan: SearchResultPlan;
}

const MonthlyPremium: React.FunctionComponent<MonthlyPremiumProps> = ({
  plan,
}) => {
  const t = useTranslate();

  let premiumInfoKey = "";
  switch (plan.plan_type) {
    case PlanType.MA:
      premiumInfoKey = "plan_card.ma.premium_includes";
      break;
    case PlanType.MAPD:
      premiumInfoKey = "plan_card.mapd.premium_includes";
      break;
    default:
      premiumInfoKey = "plan_card.pdp.premium_includes";
  }

  const snpType =
    plan.snp_type !== PlanSNPType.SNP_TYPE_NOT_SNP
      ? t(PlanCardSNPTypeLabelKeys[plan.snp_type])
      : "";
  const dsnp = plan.snp_type === PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE;
  const mmp = plan.category === PlanCategoryType.MEDICARE_MEDICAID_PLAN;

  return (
    <div className="PlanCard__section" data-testid="monthlyPremium">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.monthly_premium")}
      </h3>
      <div className="PlanCard__info_group">
        <div className="PlanCard__data ds-u-display--inline">
          <SuppressionHandler redactions={plan.redactions}>
            {$$(plan.calculated_monthly_premium)}
          </SuppressionHandler>
        </div>
        <div className="PlanCard__line_items">
          <p>{t(premiumInfoKey)}</p>
          {mmp ? (
            <FormattedMessage
              tagName="p"
              id="plan_card.amount_partb_premium"
              values={{
                // eslint-disable-next-line no-useless-escape
                amount: `\$${getPartBPremium(plan)}`,
              }}
            />
          ) : plan.plan_type !== PlanType.PDP ? (
            <p>
              {t("plan_card.does_not_include")}:{" "}
              <FormattedMessage
                id="plan_card.amount_partb_premium"
                values={{
                  amount: ifNumber(getPartBPremium(plan)),
                }}
              />
            </p>
          ) : null}
          {mmp && !dsnp && (
            <p>{t("plan_card.also_covers_medicaid_benefits")}</p>
          )}
          {dsnp && !mmp && (
            <p>{t("plan_card.plan_designed_for_medicare_medicaid_benes")}</p>
          )}
          {snpType && <p>{snpType}</p>}
        </div>
      </div>
    </div>
  );
};

export default MonthlyPremium;
