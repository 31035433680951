import React from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { Plan, SearchResultPlan, PlanType, PharmacyType } from "../../@types";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
  Ga4EventDimensions,
} from "../../app/contexts/Analytics/types";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { EnrollButtonContentReplacementMap } from "./types";
import { getEnrollButtonReplacementContent } from "./enrollButtonHelpers";

export interface EnrollButtonProps {
  aria_string?: string;
  buttonClassName?: string;
  tealiumEventAction?: string;
  onClick?: () => void;
  openEnrollModal: () => void;
  plan: Plan | SearchResultPlan;
  contentReplacementMap?: EnrollButtonContentReplacementMap;
}

type MissingGa4EventDimensions = Pick<
  Partial<Ga4EventDimensions>,
  "mct_pharmacy_type" | "mct_insurance_provider" | "mct_insurance_type"
>;

/**
 * Renders an enrollment button unless any content replacement opition passed in the
 * `contentReplacementMap` prop is set to `true`
 */
export const EnrollButton = ({
  aria_string,
  buttonClassName,
  onClick,
  openEnrollModal,
  plan,
  plan: { name },
  tealiumEventAction,
  contentReplacementMap = [],
}: EnrollButtonProps): JSX.Element => {
  const {
    dispatch,
    state: { pharmacyType, selectedPlan },
  } = useAppContext();
  const t = useTypedTranslate();

  // * Derived
  const tealiumPlanType = plan.plan_type === PlanType.PDP ? "PDP" : "MA/MAPD";
  const tealiumEventLabel = `Enroll - ${name} - ${tealiumPlanType}`;
  const buttonReplacementContent = getEnrollButtonReplacementContent(
    contentReplacementMap
  );

  const enrollAnalytics = (): void => {
    const optionalSettings: MissingGa4EventDimensions = {};
    if (!selectedPlan) {
      optionalSettings.mct_insurance_provider = plan.name;
      optionalSettings.mct_insurance_type = plan.plan_type;
    }
    if (!pharmacyType) {
      optionalSettings.mct_pharmacy_type = PharmacyType.NONE;
    }
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.PLAN_ENROLL_CLICKED,
        ...optionalSettings,
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: tealiumEventAction || "Button",
        event_label: tealiumEventLabel,
      },
    });
  };

  return buttonReplacementContent !== null ? (
    buttonReplacementContent
  ) : (
    <Button
      type="button"
      variation="solid"
      aria-label={aria_string}
      className={`e2e-plan-enroll mct-hide-print mct-c-enroll-button-form__button ${buttonClassName}`}
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
        dispatch({
          type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
          settings: {
            button: {
              buttonStyle: AnalyticsButtonStyle.PRIMARY,
              buttonType: AnalyticsButtonType.BUTTON,
              text: t("app.terms.enroll"),
            },
          },
        });
        enrollAnalytics();
        openEnrollModal();
      }}
    >
      {t("app.terms.enroll")}
    </Button>
  );
};
