import React, { FunctionComponent, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { IntlContext } from "../helpers/intlHooks";
import { FormattedMessage } from "react-intl";

interface FocusableHeaderProps {
  className?: string;
  text: string | { id: string; values: { [key: string]: string } };
  ignoreFocus?: boolean;
  srOnly?: boolean;
  id?: string;
}

const FocusableHeader: FunctionComponent<FocusableHeaderProps> = ({
  className = "",
  text,
  ignoreFocus,
  srOnly,
  id,
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const fm = useContext(IntlContext).formatMessage;
  const documentTitle =
    typeof text === "string" ? text : fm({ id: text.id }, text.values);

  useEffect(() => {
    if (!ignoreFocus) {
      ref.current?.focus();
    }
  }, [ref, text, ignoreFocus]);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return (
    <h1
      ref={ref}
      className={classNames(className, {
        "ds-u-visibility--screen-reader": srOnly,
      })}
      tabIndex={-1}
      id={id}
    >
      {typeof text === "string" ? (
        `${text}`
      ) : (
        <>
          <FormattedMessage id={text.id} values={text.values} />
        </>
      )}
    </h1>
  );
};

export default FocusableHeader;
