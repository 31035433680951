import { getPacePlanStates, getSpapPlanStates } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey, StaleTime } from "./types";
import { useMemo } from "react";

/**
 * a hook to access state abbreviations for a certain plan type (SPAP or PACE)
 */
export const usePlanStates = (planType: "SPAP" | "PACE") => {
  // * Queries
  const spapQuery = useQuery({
    queryKey: [QueryKey.SPAPStates],
    queryFn: () => {
      return getSpapPlanStates();
    },
    enabled: planType === "SPAP",
    staleTime: StaleTime.Infinite,
  });

  const paceQuery = useQuery({
    queryKey: [QueryKey.PACEStates],
    queryFn: () => {
      return getPacePlanStates();
    },
    enabled: planType === "PACE",
    staleTime: StaleTime.Infinite,
  });

  const spapStates = useMemo(() => spapQuery.data || [], [spapQuery.data]);

  const paceStates = useMemo(() => paceQuery.data || [], [paceQuery.data]);

  return {
    spapStates,
    paceStates,
    isError: spapQuery.isError || paceQuery.isError,
  };
};
