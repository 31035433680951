import classNames from "classnames";
import React, { PropsWithChildren } from "react";

type PlanTypeSubHeaderProps = PropsWithChildren<{
  isSinglePage?: boolean;
  showDescription?: boolean;
}>;

/** subheader to use with PlanTypeStep */
export const PlanTypeSubHeader: React.FunctionComponent<
  PlanTypeSubHeaderProps
> = ({ isSinglePage, showDescription, children }) => {
  if (!showDescription) {
    return <span>{children}</span>;
  }

  return (
    <strong
      className={classNames({
        // makes it bold
        ".mct-c-coverage-selector-v2__plan-type-sub-heading-aria":
          showDescription,
        "ds-text-heading--lg": isSinglePage,
      })}
    >
      {children}
    </strong>
  );
};
