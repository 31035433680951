import { ApiResponseServingData } from "../@types";

export type ApiResponseErrorInfo = {
  requestId?: string | null;
  anonymousSessionId?: string | null;
  beneSessionId?: string | null;
  apiResponseStatus?: number;
  apiResponseStatusText?: string | null;
  apiRequestUrl?: string | null;
  apiRequestBody?: string | null;
  apiError?: string | null;
  apiErrorMessage?: string | null;
  apiErrorCode?: number;
  apiResponseDetails?: ApiResponseServingData | null | string;
};

export type ApiError = Error | ApiResponseError;
export class ApiResponseError extends Error {
  info: ApiResponseErrorInfo;

  constructor(name?: string, message?: string) {
    super(message);
    this.name = name ?? "ApiResponseError";
    this.info = {};
  }
}

function isApiResponseError(err: ApiError): err is ApiResponseError {
  return "info" in err;
}

export const apiResponseErrorInfoToNrSimpleType = (
  errInfo: ApiResponseErrorInfo
): Record<string, newrelic.SimpleType> =>
  Object.entries(errInfo).reduce((acc, [key, value]) => {
    if (value && value !== null) {
      let newVal = value;
      if (typeof value === "object") {
        newVal = JSON.stringify(value);
      }
      acc[key] = newVal;
    }
    return acc;
  }, {});

// Build an an error object and log to new relic
export function logError(message: string, err: ApiError): void {
  const { newrelic } = window;

  // Wrap the error message with the app message
  err.message = `${message}: ${err.message}`;

  if (newrelic && newrelic.noticeError) {
    if (isApiResponseError(err)) {
      const errorInfo = apiResponseErrorInfoToNrSimpleType(err.info);
      newrelic.noticeError(err, errorInfo);
    } else {
      newrelic.noticeError(err);
    }
  }
}

export const csrGuestAccessErrorCode = 7;
