import React from "react";

const ariaLetterMap = {
  c: "Cee",
  d: "Dee",
};

interface AriaLetterProps {
  children: string;
  className?: string;
}

/** C and D have an unfortunate tendency to be mispronounced by aria.
 * This is a quick wrapper which allows screen readers to correctly read those letters.
 * @warning this may not be needed. Confirm with accessibility if this is still required.
 * If not, please deprecate this component. */
export const AriaLetter: React.FunctionComponent<AriaLetterProps> = ({
  children,
  className,
}) => {
  const ariaLabel = ariaLetterMap[children.toLowerCase()];
  return (
    <span aria-label={ariaLabel} className={className}>
      {children}
    </span>
  );
};
