import * as T from ".";

export const AutocompleteDrugKeys: (keyof T.AutocompleteDrug)[] = [
  "generic",
  "insulin",
  "is_generic",
  "name",
  "rxcui",
];

export const AutocompletePapDrugKeys: (keyof T.AutocompletePapDrug)[] = [
  "id",
  "name",
];

export const GlobalSessionInfoKeys: (keyof T.GlobalSessionInfo)[] = [
  "exp",
  "maximum_exp",
];

export const BasePlanKeys: (keyof T.BasePlan)[] = [
  "annual_deductible",
  "calculated_monthly_premium",
  "category",
  "contract_id",
  "contract_year",
  "drug_plan_deductible",
  "enrollment_opt_in_status",
  "enrollment_status",
  "high_performing",
  "id",
  "lis",
  "low_performing",
  "name",
  "name_esp",
  "organization_name",
  "overall_star_rating",
  "package_services",
  "partb_premium_reduction",
  "partc_premium",
  "partd_premium",
  "plan_id",
  "plan_type",
  "redactions",
  "segment_id",
  "silver_sneakers",
  "snp_type",
  "total_remaining_premium",
  "url",
];

export const BeneficiaryKeys: (keyof T.Beneficiary)[] = [
  "activity_id",
  "birthdate",
];

export const ChatInitKeys: (keyof T.ChatInit)[] = [
  "ActivityId",
  "CurrentView",
  "EmailAddress",
  "FirstName",
  "Language",
  "LastName",
];

export const CountyKeys: (keyof T.County)[] = ["fips", "state", "name"];

export const CSRKeys: (keyof T.CSR)[] = ["activityId", "id"];

export const DrugCostInfoKeys: (keyof T.DrugCostInfo)[] = [
  "costs",
  "excluded_drugs",
  "lowest_mail_total",
  "lowest_retail_total",
  "plan",
  "restrictions",
];

export const DrugCostsResponseKeys: (keyof T.DrugCostsResponse)[] = [
  "full_year",
  "request_id",
  "plans",
];

export const DrugDosageKeys: (keyof T.DrugDosage)[] = [
  "form",
  "has_duplicate_name",
  "name",
  "packages",
  "rxcui",
  "strength",
  "strength_units",
];

export const DrugInfoKeys: (keyof T.DrugInfo)[] = [
  "ndc",
  "prior_auth",
  "quantity_limit",
  "quantity_limit_amount",
  "quantity_limit_days",
  "step_therapy",
  "tier",
];

export const EnrollResponseKeys: (keyof T.EnrollResponse)[] = [
  "confirmation_number",
  "status",
];

export const FullPlanIdKeys: (keyof T.FullPlanId)[] = [
  "contract_id",
  "contract_year",
  "plan_id",
  "segment_id",
];

export const MedigapPlanPolicyKeys: (keyof T.MedigapPlanPolicy)[] = [
  "address",
  "company",
  "monthly_rate_max",
  "monthly_rate_min",
  "phone_number",
  "rate_type",
  "website",
];

export const CwPremiumRangesKeys: (keyof T.CwPremiumRanges)[] = [
  "request_id",
  "pdp_premium_min",
  "pdp_premium_max",
  "ma_premium_min",
  "ma_premium_max",
  "medigap_premium_min",
  "medigap_premium_max",
];

export const MedigapPlanPremiumRangeKeys: (keyof T.MedigapPlanPremiumRange)[] =
  ["plan_type", "medigap_plan_type", "monthly_rate_min", "monthly_rate_max"];

export const OecStatusKeys: (keyof T.OecStatus)[] = [
  "confirmation_number",
  "contract_id",
  "download_date",
  "downloaded",
  "plan_id",
  "plan_name",
  "plan_name_esp",
  "plan_type",
  "segment_id",
  "year",
];

export const PharmacyKeys: (keyof T.Pharmacy)[] = [
  "city",
  "name",
  "npi",
  "phone",
  "state",
  "street",
  "unit",
  "zipcode",
];

export const PharmacyInfoKeys: (keyof T.PharmacyInfo)[] = [
  "npi",
  "name",
  "in_network",
  "mail_order",
  "preferred",
];

export const PrescriptionDrugKeys: (keyof T.PrescriptionDrug)[] = [
  "form",
  "frequency",
  "insulin",
  "is_generic",
  "name",
  "ndc",
  "package_quantity",
  "package_size",
  "package_type",
  "package_units",
  "quantity",
  "rxcui",
  "strength_units",
  "strength",
  "use_package_quantity",
];

export const PharmaciesRequestKeys: (keyof T.PharmaciesRequest)[] = [
  "count",
  "page",
  "zipcode",
];

export const PharmaciesResponseKeys: (keyof T.PharmaciesResponse)[] = [
  "pharmacies",
  "geocode_error",
  "request_id",
  "source_address",
  "source_location_type",
];

export const LdFlagsResponseKeys: (keyof T.LdFlagsResponse)[] = [
  "flags",
  "secure-mode-hash",
];

export const InNetworkPharmaciesResponseKeys: (keyof T.InNetworkPharmaciesResponse)[] =
  [...PharmaciesResponseKeys, "mail_order_network", "mail_order_preferred"];

export const MailOrderNetworkStatusKeys: (keyof T.MailOrderNetworkStatus)[] = [
  "inNetwork",
  "preferred",
];

export const PlanDataVersionKeys: (keyof T.PlanDataVersion)[] = [
  "file",
  "file_date",
  "metadata",
  "sha1_hash",
];

export const PlanKeys: (keyof T.Plan)[] = [
  ...BasePlanKeys,
  "additional_supplemental_benefits",
  "address_1",
  "address_2",
  "city",
  "member_phone",
  "optional_benefits",
  "package_benefits",
  "prospect_phone",
  "star_ratings",
  "state",
  "zipcode",
  "ma_benefits",
];

export const PlanResultsKeys: (keyof T.PlanResults)[] = [
  "plans",
  "request_id",
  "search_summary",
  "total_results",
];

export const SearchResultPlanKeys: (keyof T.SearchResultPlan)[] = [
  ...BasePlanKeys,
  "annual_drugs_total",
  "emergency_care_cost",
  "emergency_response_device",
  "home_safety_devices",
  "in_home_support",
  "maximum_oopc",
  "otc_drugs",
  "pharmacies",
  "primary_doctor_visit_cost",
  "remaining_premium_and_drugs",
  "specialist_doctor_visit_cost",
  "telehealth",
  "transportation",
  "worldwide_emergency",
];

export const SearchResultsFiltersKeys: (keyof T.SearchResultsFilters)[] = [
  "insuranceCarrierFilter",
  "insuranceCarriers",
  "planCategoriesFilter",
  "planCoveragesFilter",
  "starRatingFilter",
];

export const StarRatingKeys: (keyof T.StarRating)[] = [
  "category",
  "error",
  "rating",
];

export const PacePlanKeys: (keyof T.PacePlan)[] = [
  "contract_id",
  "contract_year",
  "plan_id",
  "plan_name_esp",
  "plan_name",
  "segment_id",
];

export const PapPlanKeys: (keyof T.PapPlan)[] = [
  "program_name",
  "org_name",
  "eligibility",
];

export const SpapPlanKeys: (keyof T.SpapPlan)[] = [
  "program_name",
  "state",
  "phone1",
  "url",
];

export const PlanPreferredPharmacyInfoKeys: (keyof T.PlanPreferredPharmacyInfo)[] =
  ["plan", "retail_preferred", "mail_preferred"];

export const NewToMedicareAlertInfoKeys: (keyof T.NewToMedicareAlertInfo)[] = [
  "birthdate",
  "iepStatus",
  "newToMedicareResult",
  "hasMedigap",
  "hasPDPPlan",
  "hasMAPDPlan",
  "hasPartAandB",
];

export const BeneInfoOtherInsuranceKeys: (keyof T.BeneInfoOtherInsurance)[] = [
  "contractor_number",
  "insurer_name",
  "insurer_address_line_1",
  "insurer_address_line_2",
  "insurer_city",
  "insurer_state",
  "insurer_zip",
  "policy_number",
  "effective_date",
  "termination_date",
  "coba_number",
  "coba_type",
  "plan_id",
  "insurer_code",
];
