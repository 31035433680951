import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  AriaAttributes,
} from "react";
import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import CCXPBaseDrop from "./CCXPBaseDrop";

interface Props extends AriaAttributes {
  hideClear?: boolean;
  keepDefaultLabel?: boolean;
  label: string;
  name?: string;
  onUpdate: (value: string) => void;
  options: { label: string; value: string }[];
  size?: "wide" | "widest";
  type?: "checkbox" | "radio";
  value: string;
}

const CCXPDropdown: FunctionComponent<Props> = ({
  "aria-labelledby": ariaLabelledBy,
  hideClear = false,
  keepDefaultLabel,
  label,
  name,
  onUpdate,
  options,
  size,
  type = "radio",
  value,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [choices, setChoices] = useState<
    {
      label: string;
      value: string;
      checked?: boolean;
    }[]
  >([]);

  useEffect(() => {
    setCurrentValue(value);
    setChoices(applyCheckedOption(value));
  }, [value]);

  const applyCheckedOption = (checkedValue: string) =>
    options.map(o => ({
      ...o,
      checked: checkedValue == o.value,
    }));

  const displayLabel = keepDefaultLabel
    ? label
    : options.find(o => o.value === value)?.label || label;

  return (
    <CCXPBaseDrop
      aria-labelledby={ariaLabelledBy}
      name={name}
      label={displayLabel}
      onApply={() => onUpdate(currentValue)}
      onClear={() => {
        setCurrentValue("");
        setChoices(applyCheckedOption(""));
      }}
      onMenuOpen={() => {
        setCurrentValue(value);
        setChoices(applyCheckedOption(value));
      }}
      onMenuClose={() => {
        setCurrentValue("");
        setChoices(applyCheckedOption(""));
      }}
      hideClear={hideClear}
      hasValue={!!value}
      size={size}
    >
      <ChoiceList
        className="ds-u-margin--0"
        name={label}
        type={type}
        label=""
        choices={choices}
        onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>): void => {
          setCurrentValue(currentTarget.value);
          setChoices(applyCheckedOption(currentTarget.value));
        }}
      />
    </CCXPBaseDrop>
  );
};

export default CCXPDropdown;
