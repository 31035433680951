import { AddIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from "react";

const defaultProps = {
  title: "mapbox location",
};

const MapboxLocationIconComponent: ForwardRefRenderFunction<
  SVGSVGElement,
  IconCommonProps
> = (
  props: IconCommonProps,
  iconRef: React.ForwardedRef<SVGSVGElement>
): React.ReactElement => {
  const iconCssClasses = `mct-c-icon--location-arrow ${props.className || ""}`;
  const innerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(
    iconRef,
    () => innerRef.current?.firstElementChild as SVGSVGElement
  );

  return (
    <div ref={innerRef}>
      <AddIcon {...defaultProps} {...props} className={iconCssClasses} />
    </div>
  );
};

export const MapboxLocationIcon = forwardRef(MapboxLocationIconComponent);
