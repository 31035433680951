import { TranslationKey } from "../../helpers/intlHooks";
import { EnrollButtonReplacmentHierarchy } from "./types";

export const enrollmentButtonReplacementContentKeys: Record<
  EnrollButtonReplacmentHierarchy,
  TranslationKey
> = {
  [EnrollButtonReplacmentHierarchy.CANT_ENROLL_CURRENT_YEAR]:
    "plan_card.your_current_plan",
  [EnrollButtonReplacmentHierarchy.CANT_ENROLL_CURRENT_YEAR_NEXT_YEAR]:
    "plan_card.your_next_plan",
  [EnrollButtonReplacmentHierarchy.ENROLLMENT_DISABLED]:
    "plan_enroll.cannot_process_enrollments",
  [EnrollButtonReplacmentHierarchy.DSNP_SUPPRESSION]:
    "plan_enroll.not_accepting.d_snp",
  [EnrollButtonReplacmentHierarchy.NO_OPT_IN_STATUS]:
    "plan_card.contact_plan_to_enroll",
  [EnrollButtonReplacmentHierarchy.PLAN_FROZEN]:
    "plan_card.contact_plan_to_enroll",
  [EnrollButtonReplacmentHierarchy.NO_ENROLLMENT_AT_THIS_TIME]:
    "plan_enroll.not_accepting",
};
