import React from "react";
import { FormattedMessage } from "react-intl";
import { useTranslate } from "../../../helpers/intlHooks";
import { linkToSHIPHelp } from "../../../helpers/CONSTANTS";
import { MedicareGovAnchor } from "../../MedicareGovAnchor";

/** Help drawer with "When should I buy Medigap?" information? */
export const MedigapHelpDrawerContents = () => {
  const t = useTranslate();

  return (
    <>
      <p>
        <strong>{t("ntm.find_plans_now.help_drawer.best_time")}</strong>{" "}
        <FormattedMessage
          id={"ntm.find_plans_now.help_drawer.six_month"}
          values={{
            and: <span>{t("and")}</span>,
          }}
        />
      </p>
      <ul>
        <li>
          <strong>
            {t("ntm.find_plans_now.help_drawer.bullet.65_up_intro")}
          </strong>{" "}
          {t("ntm.find_plans_now.help_drawer.bullet.65_up_cont")}
        </li>
        <li>
          <strong>
            {t("ntm.find_plans_now.help_drawer.bullet.64_down_intro")}{" "}
          </strong>
          <FormattedMessage
            id={"ntm.find_plans_now.help_drawer.bullet.64_down_cont"}
            values={{
              ship: (
                <a href={linkToSHIPHelp} target="_blank" rel="noreferrer">
                  {t("ntm.find_plans_now.help_drawer.ship")}
                </a>
              ),
            }}
          />
        </li>
      </ul>
      <MedicareGovAnchor urlKey="medigap">
        {t("ntm.find_plans_now.help_drawer.learn_more_medigap")}
      </MedicareGovAnchor>
    </>
  );
};
