import React, { useEffect, useState } from "react";
import { Plan, StarRating as StarRatingType } from "../@types";
import { getOriginalMedicareStarRatings } from "../api";
import { useTranslate } from "../helpers/intlHooks";
import { getStarRating } from "../helpers/starRatingHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import HelpDrawerWrapper from "./HelpDrawerWrapper";
import ScrollTable from "./ScrollTable";
import StarRating from "./StarRating";

interface OriginalMedicareStarRatingsProps {
  plan: Plan;
}

const OriginalMedicareStarRatings: React.FunctionComponent<
  OriginalMedicareStarRatingsProps
> = ({ plan }) => {
  const t = useTranslate();
  const {
    state: { fips, year },
  } = useAppContext();
  const [ratings, setRatings] = useState<StarRatingType[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    // fetch star ratings
    (async (): Promise<void> => {
      if (fips && year) {
        try {
          const omRatings = await getOriginalMedicareStarRatings(fips, year);
          if (omRatings.length) {
            setRatings(omRatings);
          } else {
            setError(true);
          }
        } catch (e) {
          setError(true);
        }
      }
    })();
  }, [fips, year]);

  return (
    <HelpDrawerWrapper
      toggleText={t("om_stars.view_how_plan_compares")}
      drawerTitle={t("om_stars.view_how_plan_compares")}
      enableDefaultAnalytics={true}
      large
      toggleClassName="OriginalMedicareStarRatingsToggle"
      dialogClassName="OriginalMedicareStarRatingsHelpDrawerDialog"
    >
      {error ? (
        <p>{t("om_stars.error_message")}</p>
      ) : (
        <ScrollTable className="OriginalMedicareStarRatingsTable">
          <thead>
            <tr>
              <th>{t("om_stars.category_column_heading")}</th>
              <th>{t("om_stars.star_column_heading")}</th>
              <th>{t("om_stars.plan_star_column_heading")}</th>
            </tr>
          </thead>
          <tbody>
            {ratings.map(rating => {
              return (
                <tr key={rating.category}>
                  <td>{t(`om_stars.${rating.category.toLowerCase()}`)}</td>
                  <td>
                    <StarRating
                      stars={rating}
                      includeLabel={false}
                      planYear={year || ""}
                    />
                  </td>
                  <td>
                    <StarRating
                      stars={getStarRating(plan, rating.category)}
                      includeLabel={false}
                      planYear={plan.contract_year}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ScrollTable>
      )}
    </HelpDrawerWrapper>
  );
};

export default OriginalMedicareStarRatings;
