import React, { useMemo } from "react";
import { SearchResultPlan, DrugCostInfo, PharmacyType } from "../../@types";
import { TooltipWithClose } from "../TooltipWithClose";
import { useCurrentPlanYear, useYearPart } from "../../helpers/yearFlagHelpers";
import { SuppressionHandler, YearlyCostContextMessage } from "../../components";
import { sendPlanTooltipEvent } from "../../helpers/analyticsHelpers";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { isMapdPlanType, isPdPlanType } from "../../helpers/planTypes";
import {
  shouldSuppressDrugPricing,
  shouldSuppressPlanBenefits,
} from "../../helpers/planHelpers";
import { ifNumber } from "../../helpers/currencyUtilities";

interface YearlyCostProps {
  drugCostInfo: DrugCostInfo;
  plan: SearchResultPlan;
  /**
   * if `hasCostsFromPlan` is true, expect to render cost values from `plan`, otherwise
   * from `drugCostInfo`
   **/
  hasCostsFromPlan?: boolean;
}

const YearlyCost: React.FunctionComponent<YearlyCostProps> = ({
  drugCostInfo,
  plan,
  hasCostsFromPlan = false,
}) => {
  const t = useTypedTranslate();
  const {
    state: { pharmacyType, prescriptions },
  } = useAppContext();
  const mctCurrentPlanYear = useCurrentPlanYear();

  const { isOutsideOpenEnrollment, isOpenEnrollmentNextYearOnly } =
    useYearPart();

  const isPDP = isPdPlanType(plan.plan_type);

  const totalMail = hasCostsFromPlan
    ? plan.remaining_premium_and_drugs_mail_order
    : typeof drugCostInfo.lowest_mail_total === "string"
    ? drugCostInfo.lowest_mail_total
    : drugCostInfo.lowest_mail_total + plan.total_remaining_premium;

  const totalRetail = hasCostsFromPlan
    ? plan.remaining_premium_and_drugs_retail
    : typeof drugCostInfo.lowest_retail_total === "string"
    ? drugCostInfo.lowest_retail_total
    : drugCostInfo.lowest_retail_total + plan.total_remaining_premium;

  const mapdOnlyText = isMapdPlanType(plan.plan_type) ? (
    <p>{t("plan_card.does_not_include_health_costs")}</p>
  ) : null;

  const shouldSuppressExtraContent =
    shouldSuppressDrugPricing(plan.redactions) ||
    shouldSuppressPlanBenefits(plan.redactions);

  const mailOrderNotCovered = useMemo(() => {
    let notCovered = plan.does_not_support_mail_order;
    if (!hasCostsFromPlan) {
      notCovered =
        notCovered ||
        (!plan.remaining_premium_and_drugs &&
          !drugCostInfo.costs.find(c => c.mail_order));
    }
    return notCovered;
  }, [
    drugCostInfo.costs,
    plan.does_not_support_mail_order,
    plan.remaining_premium_and_drugs,
    hasCostsFromPlan,
  ]);

  const mailSection = (
    <div className="PlanCard__info_group">
      {mailOrderNotCovered ? (
        <span data-testid="mail-order-not-covered">
          {t("plan_terms.does_not_cover_mail_order")}
        </span>
      ) : (
        <>
          <SuppressionHandler
            redactions={plan.redactions}
            checkShouldSuppressDrugPricing
            suppressionWrapperProps={{
              className: "PlanCard__data e2e-total-mail-cost",
            }}
          >
            {ifNumber(totalMail)}
          </SuppressionHandler>
          {!shouldSuppressExtraContent && (
            <div className="PlanCard__line_items">
              <TooltipWithClose
                title={
                  isOutsideOpenEnrollment
                    ? t("plan_card.tooltip.mail_total_cost.OOE")
                    : t("plan_card.tooltip.mail_total_cost.OE")
                }
              >
                {t("plan_card.mail_order_pharmacy")}
                {":"}
              </TooltipWithClose>
              <span>{t("plan_terms.estimated_yearly_total_cost")}</span>
              {mapdOnlyText}
            </div>
          )}
        </>
      )}
    </div>
  );

  const retailSection = (
    <div className="PlanCard__info_group">
      <SuppressionHandler
        redactions={plan.redactions}
        checkShouldSuppressDrugPricing
        suppressionWrapperProps={{
          className: "PlanCard__data e2e-total-retail-cost",
        }}
      >
        {ifNumber(totalRetail)}
      </SuppressionHandler>
      {!shouldSuppressExtraContent && (
        <div className="PlanCard__line_items">
          <TooltipWithClose
            title={
              isOutsideOpenEnrollment
                ? t("plan_card.tooltip.retail_total_cost.OOE")
                : t("plan_card.tooltip.retail_total_cost.OE")
            }
            onOpen={() =>
              sendPlanTooltipEvent(plan, t("plan_search_prefs.retail_pharmacy"))
            }
          >
            {t("plan_search_prefs.retail_pharmacy")}
            {":"}
          </TooltipWithClose>
          <span>{t("plan_terms.estimated_yearly_total_cost")}</span>
          {mapdOnlyText}
        </div>
      )}
    </div>
  );

  let noDrugsSection;
  if (!prescriptions.length) {
    noDrugsSection = (
      <div className="PlanCard__info_group">
        <SuppressionHandler
          redactions={plan.redactions}
          checkShouldSuppressDrugPricing
          suppressionWrapperProps={{
            className: "PlanCard__data e2e-total-retail-cost",
          }}
        >
          {ifNumber(totalRetail)}
        </SuppressionHandler>
        {!shouldSuppressExtraContent && (
          <span>
            {!isOpenEnrollmentNextYearOnly &&
            mctCurrentPlanYear &&
            plan.contract_year !== mctCurrentPlanYear.toString()
              ? t("plan_card.total_cost_no_drugs.whole_year")
              : t("plan_card.total_cost_no_drugs.remainder_year")}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="PlanCard__section" data-testid="yearlyCost">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.yearly_cost")}
        <span className="PlanCard__year-context">
          {" "}
          <YearlyCostContextMessage />
        </span>
      </h3>

      {noDrugsSection ? (
        noDrugsSection
      ) : isPDP ? (
        <>
          {pharmacyType !== PharmacyType.MAIL && retailSection}
          {pharmacyType !== PharmacyType.RETAIL && mailSection}
        </>
      ) : pharmacyType === PharmacyType.MAIL ? (
        mailSection
      ) : (
        retailSection
      )}
    </div>
  );
};

export default YearlyCost;
