import React, { FC } from "react";
import { CloseIconThin, HelpDrawer } from "@cmsgov/ds-medicare-gov";
import { FormattedMessage } from "react-intl";
import { useTranslate } from "../helpers/intlHooks";
import { linkToSpecialEnrollmentPeriodInfo } from "../helpers/urlHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

export const SEPHelpDrawer: FC<{
  onCloseClick: () => void;
}> = ({ onCloseClick }) => {
  const t = useTranslate();
  const {
    state: { language },
  } = useAppContext();
  return (
    <div className="mct-c-help-drawer">
      <HelpDrawer
        heading={t("dob.sep.help_me")}
        onCloseClick={onCloseClick}
        closeButtonText={<CloseIconThin />}
      >
        <FormattedMessage id="dob.sep.help_rules" />
        <p>{t("dob.sep.help_rules.header.1")}</p>
        <p>{t("dob.sep.help_rules.header.2")}</p>
        <ul>
          <li>{t("dob.sep.help_rules.list.1")}</li>
          <li>{t("dob.sep.help_rules.list.2")}</li>
          <li>{t("dob.sep.help_rules.list.3")}</li>
        </ul>
        <p>{t("dob.sep.help_rules.footer")}</p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={linkToSpecialEnrollmentPeriodInfo(language)}
          >
            {t("dob.sep.learn_more")}
          </a>
        </p>
      </HelpDrawer>
    </div>
  );
};
