import { useLocation } from "react-router-dom";
import routes from "../app/routes";
import { useFormatMessage } from "./intlHooks";
import { getYearPartInfoFromYearPart, useYearPart } from "./yearFlagHelpers";
import { MctYearPartFlag } from "../@types";

export const YearSwitchBannerRoutes: string[] = [
  routes.lisQuestions,
  routes.questionRouting,
  routes.sanctionedPlans,
  routes.searchResults,
  routes.summary.lisQuestions,
  routes.summary.searchResults,
];

/**
 * useYearSwitchBanner returns a boolean to indicate whether or not to display the YearSwitchBanner
 * @returns true if to display the YearSwitchBanner, otherwise false
 */
export const useYearSwitchBanner = (): boolean => {
  const { isPublicPreview, isOpenEnrollment } = useYearPart();
  const location = useLocation();
  const showBanner = YearSwitchBannerRoutes.includes(location.pathname);

  return !!(isPublicPreview || isOpenEnrollment) && showBanner;
};

/**
 * addYearInTitle adds the year in the title if YearSwitchBanner is shown.
 * @param param object containing parameters
 * @param param.title the page title without the year
 * @param param.yearSwitchBannerShown indicates if YearSwitchBanner is shown or not
 * @param param.yearInfo the text to append to the title if YearSwitchBanner is shown.
 * @returns the title or the title with the year appended.
 */
export const addYearInTitle = ({
  title,
  yearSwitchBannerShown,
  yearInfo,
}: {
  title: string;
  yearSwitchBannerShown: boolean;
  yearInfo: string;
}): string => {
  return yearSwitchBannerShown && !!yearInfo ? `${title} ${yearInfo}` : title;
};

/**
 * useShouldAddYearToTitle returns information about whether to show the YearSwitchBanner or not and the text to append to the title if YearSwitchBanner is shown.
 * @param currentYear the year selected by the user (i.e. stored in state)
 * @returns the showYearSwitchBanner flag and the text to append to the title if YearSwitchBanner is shown
 */
export const useShouldAddYearToTitle = (
  currentYear?: string
): { showYearSwitchBanner: boolean; yearInfo: string } => {
  const showYearSwitchBanner = useYearSwitchBanner();

  const fm = useFormatMessage();
  const yearInfo = currentYear
    ? fm({ id: "page_title.year" }, { year: currentYear })
    : "";

  return { showYearSwitchBanner, yearInfo };
};

/**
 * useYearInTitle adds the current year to the title if YearSwitchBanner is shown.
 * @param title the page title without the year
 * @param currentYear the year selected by the user (i.e. stored in state)
 * @returns the title or the title with the year appended.
 * TODO: We should not need to pass in the currentYear, since we can get that from state directly.
 */
export const useYearInTitle = (title: string, currentYear?: string): string => {
  const { showYearSwitchBanner, yearInfo } =
    useShouldAddYearToTitle(currentYear);

  return addYearInTitle({
    title,
    yearSwitchBannerShown: showYearSwitchBanner,
    yearInfo,
  });
};

/**
 * Verifies that the year used for `AppState.year` and the `year` query param
 * in the URL is valid for the current year part, given the current plan year
 * If this returns `true`, the year does not need to be updated
 * If it returns `false`, the year will need to be updated to be valid for the set year-part
 */
export const isValidYearForYearPart = ({
  year,
  mctCurrentPlanYear,
  yearPart,
}: {
  year: string | undefined;
  mctCurrentPlanYear: number;
  yearPart: MctYearPartFlag;
}): boolean => {
  if (!year) {
    return false;
  }
  const {
    isOpenEnrollment,
    isOpenEnrollmentNextYearOnly,
    isOutsideOpenEnrollment,
    isPublicPreview,
  } = getYearPartInfoFromYearPart(yearPart);
  if (isOpenEnrollmentNextYearOnly) {
    return year === (mctCurrentPlanYear + 1).toString();
  }
  if (isPublicPreview || isOpenEnrollment) {
    return [
      mctCurrentPlanYear.toString(),
      (mctCurrentPlanYear + 1).toString(),
    ].includes(year);
  }
  if (isOutsideOpenEnrollment) {
    return year === mctCurrentPlanYear.toString();
  }
  return false;
};

/**
 * Determines whether the year should be updated in the URL and/or AppState, based
 * on whether the currently set year is valid for the currently set year-part.
 * Returns a year to set, if one needs to be set, and a boolean dictating whether
 * `AppState.year` should be updated.
 */
export const getShouldUpdateSearchYear = ({
  mctCurrentPlanYear,
  yearInAppState,
  yearInUrl,
  yearPart,
}: {
  mctCurrentPlanYear: number;
  yearInAppState: string | undefined;
  yearInUrl: string | undefined;
  yearPart: MctYearPartFlag;
}): {
  shouldUpdateAppState: boolean;
  shouldUpdateUrl: boolean;
  yearToSet: string | null | undefined;
} => {
  const { isOutsideOpenEnrollment } = getYearPartInfoFromYearPart(yearPart);
  const isValidYearInUrl = isValidYearForYearPart({
    year: yearInUrl,
    yearPart,
    mctCurrentPlanYear,
  });
  const isValidYearInAppState = isValidYearForYearPart({
    year: yearInAppState,
    yearPart,
    mctCurrentPlanYear,
  });
  const defaultYear = isOutsideOpenEnrollment
    ? mctCurrentPlanYear.toString()
    : (mctCurrentPlanYear + 1).toString();
  let yearToSet = null;
  if (!isValidYearInUrl && yearInAppState && isValidYearInAppState) {
    yearToSet = yearInAppState;
  } else if (yearInAppState !== yearInUrl && isValidYearInUrl) {
    yearToSet = yearInUrl;
  } else if (!yearInAppState || !isValidYearInUrl) {
    yearToSet = defaultYear;
  }
  return {
    shouldUpdateAppState: !!yearToSet && yearToSet !== yearInAppState,
    shouldUpdateUrl: !!yearToSet && yearToSet !== yearInUrl,
    yearToSet,
  };
};
